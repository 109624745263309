
<div class="row" *ngIf="data && data.agendado">
  <span class="text-center col-md-12">
    <b>  {{translate.instant("agendamentos.dadosDe")}} {{ data.agendado.nome_completo }}</b><br><hr>
  </span>
</div>
<div mat-dialog-content  *ngIf="data && data.agendado">
  <div class="row  col-data">
    <div class="col-md-12">
      <strong>ID: </strong>{{data.agendado.id}}
    </div>
    <div class="col-md-6">
      <strong>{{translate.instant("agendamentos.nomeCompleto")}}: </strong>{{data.agendado.nome_completo}}
    </div>
    <div class="col-md-6" *ngIf="data.agendado.cpf">
      <strong>{{translate.instant("agendamentos.cpfOrCNPJ")}}: </strong>{{data.agendado.cpf}}
    </div>
    <div class="col-md-6" *ngIf="data.agendado.telefone">
      <strong>{{translate.instant("agendamentos.telefone")}}: </strong>{{data.agendado.telefone}}
    </div>
    <div class="col-md-6" *ngIf="data.agendado.email">
      <strong>Email: </strong>{{data.agendado.email}}
    </div>
    <div class="col-md-6" *ngIf="data.agendado.created_at">
      <strong>{{translate.instant("agendamentos.solicitado")}}: </strong>{{data.agendado.created_at  | dateConverter:'dd/MM/yyyy - HH:mm'}}
    </div>
    <div class="col-md-6" *ngIf="data.agendado.atendido_as">
      <strong>{{translate.instant("agendamentos.atendido")}} </strong>{{data.agendado.atendido_as  | dateConverter:'dd/MM/yyyy - HH:mm'}}
    </div>
    <div class="col-md-6" *ngIf="data.agendado.chamado_as">
      <strong>{{translate.instant("agendamentos.chamadoAs")}} </strong>{{data.agendado.chamado_as  | dateConverter:'dd/MM/yyyy - HH:mm'}}
    </div>
    <div class="col-md-12">
      <hr>
    </div>
    <div class="col-md-12">
      <mat-form-field class="w-100">
        <textarea (change)="alterouDados(mensagemEnvio.value)" [(value)]="data.agendado.descricao_atendimento" matInput #mensagemEnvio autofocus rows="3" maxlength="1000" placeholder="Observações do atendimento"></textarea>
        <mat-hint align="end">{{mensagemEnvio.value.length}} / 1000</mat-hint>
      </mat-form-field> 
    </div>
    <div class="col-md-12">
      <hr>
    </div>
    <div class="col-md-2 text-center">
      <button type="button" class="btn-success" (click)="action('chamar')">{{translate.instant("agendamentos.chamar")}}</button>
    </div>
    <div class="col-md-4 text-center">
      <button type="button" class="btn-success"  (click)="action('iniciar')">{{translate.instant("agendamentos.iniciarAtendimento")}}</button>
    </div>
    <div class="col-md-3 text-center">
      <button type="button" class="btn-danger" (click)="action('encerrar')">{{translate.instant("agendamentos.naoComp")}}</button>
    </div>
    <div class="col-md-3 text-center">
      <button type="button" class="btn-danger" (click)="action('remover')">{{translate.instant("agendamentos.liberarHorario")}}</button>
    </div>
  </div>
</div>