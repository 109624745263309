import { MatDialogRef, MAT_DIALOG_DATA,  } from '@angular/material/dialog';
import { Component, OnInit, OnChanges, SimpleChanges,  Inject } from '@angular/core';
import { TranslateService } from "@ngx-translate/core"

@Component({
  // selector: 'app-atendimento-detail-modal',
  templateUrl: './protocolo-atendente-categoria-detail-modal.component.html',
  styleUrls: ['./protocolo-atendente-categoria-detail-modal.component.css']
})

export class ProcoloAtendenteCategoriaDetailModalComponent implements OnInit, OnChanges{


  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ProcoloAtendenteCategoriaDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public category: any) {
      console.log(this.category)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log("Chamou onchanges");
  }
  private mapdate(date){
    return date;
    let d = new Date (date)
    return `${d.getHours()}:${d.getMinutes()} - ${d.getDay()<10?'0':''}${d.getDay()}/${d.getMonth()+1<10?'0':''}${d.getMonth()+1}/${d.getFullYear()}`
  }

}
