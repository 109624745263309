import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { APIService } from '../../../api.service';
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from 'angular2-flash-messages';
var consultaPorCPFModalComponent = /** @class */ (function () {
    function consultaPorCPFModalComponent(apiService, dialog, translate, flashMessagesService, dialogRef) {
        this.apiService = apiService;
        this.dialog = dialog;
        this.translate = translate;
        this.flashMessagesService = flashMessagesService;
        this.dialogRef = dialogRef;
        this.cpf = "";
        this.agendamentos = [];
        this.buscou = false;
    }
    consultaPorCPFModalComponent.prototype.buscar = function (cpfinput) {
        var _this = this;
        if (!cpfinput)
            return;
        cpfinput = this.removeCaracteres(cpfinput);
        this.loading = true;
        this.buscou = true;
        this.apiService.getAgendamentosPorCPF(cpfinput).toPromise().then(function (data) {
            _this.loading = false;
            _this.formataAgendamento(data);
        })
            .catch(function (err) {
            _this.loading = false;
            console.log(err);
        });
    };
    consultaPorCPFModalComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    consultaPorCPFModalComponent.prototype.formataAgendamento = function (dados) {
        var _this = this;
        this.agendamentos = [];
        dados.forEach(function (cadastroAgendamento) {
            if (cadastroAgendamento && cadastroAgendamento.agendamento && cadastroAgendamento.agendamento.json_vagas_hora) {
                var jsonHoras = JSON.parse(cadastroAgendamento.agendamento.json_vagas_hora);
                var horarioSelecionado_1 = null;
                jsonHoras.forEach(function (horario) {
                    if (horario.id_agendados)
                        Object.values(horario.id_agendados).forEach(function (id_agendado) {
                            if (cadastroAgendamento.id == id_agendado && !horarioSelecionado_1) {
                                horarioSelecionado_1 = horario.datetime.date ? horario.datetime.date : horario.datetime;
                            }
                        });
                });
                cadastroAgendamento["dataAgendamentoFormatada"] = horarioSelecionado_1;
                _this.agendamentos.push(cadastroAgendamento);
            }
        });
        console.log(dados);
    };
    consultaPorCPFModalComponent.prototype.numberOnly = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        console.log(charCode);
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode != 45 && charCode != 46) {
            return false;
        }
        return true;
    };
    consultaPorCPFModalComponent.prototype.deletarAgendamento = function (agendamento) {
        var _this = this;
        if (confirm("Você deseja deletar esse cadastro ? Ação irreversivel!")) {
            this.apiService.deleteAgendarCadastro(agendamento.agendamento_id, agendamento.id).subscribe(function () {
                _this.loading = false;
                _this.lancarMensagem("Agendamento removido com sucesso!", "alert-success");
                _this.dialogRef.close();
            });
        }
    };
    consultaPorCPFModalComponent.prototype.removeCaracteres = function (valor) {
        return valor.replace(/\D/g, '');
    };
    return consultaPorCPFModalComponent;
}());
export { consultaPorCPFModalComponent };
