import { StatusProtocolo } from './StatusProtocolo';
var AgrupamentoProtocolo = /** @class */ (function () {
    function AgrupamentoProtocolo(id, created_at, descricao, responsavel, statusId, statusTitulo, categoriaServico, previsaoEncerramento, protocolos) {
        this.id = id;
        this.created_at = created_at;
        this.descricao = descricao;
        this.responsavel = responsavel;
        this.status = new StatusProtocolo(statusId, statusTitulo);
        this.previsaoEncerramento = previsaoEncerramento;
        this.categoriaServico = categoriaServico;
        this.protocolos = protocolos;
    }
    return AgrupamentoProtocolo;
}());
export { AgrupamentoProtocolo };
