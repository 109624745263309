import { Component, Input, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { PerfilLogado, PerfilBody } from '../../model';
import { AuthenticationService } from '../../auth/authentication.service';
import { MenuService } from '../../services/menu-service';
import { Constantes } from '../../helpers';
import { DomainService } from '../../services/domain-service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../event-service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  public navItems;
  public selectedFile;
  public iconePrefeituraBrasao;
  public iconePrefeituraLogo;
  public isCollapsed;
  public element: HTMLElement = document.body;
  public dadosPerfil: PerfilLogado;
  public foto = '/assets/img/avatars/7.jpg';
  public href: string = "";
  public removeCartPages = [
    '/entrada',
    '/dashboard',
    '/atendimento/mapa-atendimentos',
    '/atendimento/tempo-real',
    '/dashboard-corona'
  ];
  public exibirCard = true;
  public opened: boolean = true;
  public checkUserInScope;
  constructor(
    private authService: AuthenticationService,
    public domainSvc: DomainService,
    private menuService: MenuService,
    private eventService: EventService,
    public translate: TranslateService,
    private router: Router,
    private location: Location,
    public media: MediaMatcher
  ) {
    
    this.iconePrefeituraBrasao = Constantes.urlBrasaoPrefeitura(
      domainSvc.getPrimarySubDomain()
    );
    this.iconePrefeituraLogo = Constantes.urlIconPrefeitura(
      domainSvc.getPrimarySubDomain()
    );

    this.router.events.subscribe(() => {
      if(this.location.path() != ''){
        this.href = this.location.path();
      }
      this.exibirCard =  this.removeCartPages.indexOf(this.href) == -1;
    });
  }
  onFileChanged(event){
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      const formdata: FormData = new FormData();
      formdata.append("imagem", this.selectedFile, "arquivo.jpg");
      formdata.append("_method", "PUT");
      this.authService.updateMyProfile(formdata).toPromise().then(
      data => {
        this.authService.carregarPerfil().subscribe();
        this.verificarUsuario();
      },
      (erro) => {
      });
    }
  };

  useLanguage(language: string) {
    this.translate.use(language).subscribe(
      () => {
        this.eventService.changeTranslateEvent(language);
      }
    );
  }

  ngAfterViewInit(): void {}

  async ngOnInit() {
    this.verificarUsuario();
  }
  private verificarUsuario(){
    this.authService.consultarPerfil().subscribe((perfilUsuario: any) => {
      let perfilUsuarioFormatado = <PerfilBody> perfilUsuario;
      this.dadosPerfil = this.convertObjectSession(perfilUsuarioFormatado);
      if (this.dadosPerfil != null) {
        localStorage.setItem('currentPerfil', JSON.stringify(perfilUsuario));
        if(this.dadosPerfil.imagem) this.foto = this.dadosPerfil.imagem;
        this.navItems = this.menuService.build();
        this.eventService.emitirChangeOnTranslator.subscribe(data => {
          this.navItems = this.menuService.build();
        });
      }
    });
  }

  private convertObjectSession(perfil: PerfilBody): PerfilLogado {
    return new PerfilLogado(
      perfil.name,
      perfil.email,
      perfil.fone,
      perfil.imagem
    );
  }
}
