import { APIService } from '../../../../../api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
var MultiplasFotosComponent = /** @class */ (function () {
    function MultiplasFotosComponent(apiService, flashMessagesService, dialogRef, translate, data) {
        this.apiService = apiService;
        this.flashMessagesService = flashMessagesService;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.data = data;
        this.loading = true;
        this.carregarMolduras();
    }
    MultiplasFotosComponent.prototype.carregarMolduras = function () {
        var _this = this;
        this.apiService.getinformacao(this.data.id).toPromise().then(function (data) {
            _this.listMolduras = data['imagens'];
            _this.loading = false;
        }, function (error) {
            _this.loading = false;
        });
    };
    MultiplasFotosComponent.prototype.createMoldura = function () {
        var _this = this;
        if (this.selectedFile) {
            this.loading = true;
            var formdata = new FormData();
            formdata.append('informacao', this.data.id);
            formdata.append('imagem', this.selectedFile, 'arquivo.jpg');
            this.apiService.createFotoInformacao(formdata).toPromise().then(function () {
                _this.lancarMensagem(_this.translate.instant("categorias.imagemCriada"), 'alert-success');
                _this.carregarMolduras();
            }, function (erro) {
                _this.lancarMensagemDeErro(erro['error']['error'], 'alert-danger');
                _this.loading = false;
            });
        }
    };
    MultiplasFotosComponent.prototype.lancarMensagemDeErro = function (mensagem, tipo) {
        var campos = Object.keys(mensagem);
        var mensagensDeErro = [];
        for (var _i = 0, campos_1 = campos; _i < campos_1.length; _i++) {
            var campo = campos_1[_i];
            mensagensDeErro.push(mensagem[campo]);
        }
        this.flashMessagesService.show(mensagensDeErro.join('<br>'), { cssClass: tipo, timeout: 9900 });
    };
    MultiplasFotosComponent.prototype.deletarMoldura = function (id) {
        var _this = this;
        this.apiService.deleteFotoInformacao(id).toPromise().then(function (data) {
            _this.getMoldura(id);
            _this.lancarMensagem(_this.translate.instant("categorias.imagemRemovida"), 'alert-success');
        }, function () { return _this.lancarErro(); });
    };
    MultiplasFotosComponent.prototype.onFileChanged = function (event) {
        this.selectedFile = event.target.files[0];
    };
    MultiplasFotosComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    MultiplasFotosComponent.prototype.lancarErro = function () {
        this.lancarMensagem(this.translate.instant("categorias.errorTentarOperacao"), 'alert-danger');
    };
    MultiplasFotosComponent.prototype.getMoldura = function (id) {
        var _this = this;
        this.listMolduras.map(function (element, index) {
            if (element.id === id) {
                _this.listMolduras.splice(index, 1);
            }
        });
    };
    return MultiplasFotosComponent;
}());
export { MultiplasFotosComponent };
