<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<main>
  <header>
    <strong>{{ title }}</strong>
    <span
      >Gerencie as imagens abaixo, caso deseje adicionar ou remover clique nos
      botões correspondentes.</span
    >
  </header>

  <!-- <div class="row">
    <div class="col-md-12 mx-auto">
      <br /><flash-messages class="text-center"></flash-messages>
    </div>
  </div> -->

  <div class="data">
    <div class="images">
      <div class="card-image" *ngFor="let image of images">
        <img *ngIf="image != null" [src]="image.imagem" />
        <button class="icon" (click)="deleteImage(image)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="input">
        <label class="buttonNewImage" for="file"
          ><mat-icon>add</mat-icon></label
        >
        <input
          id="file"
          type="file"
          class="form-control-file"
          [(ngModel)]="imageSelected"
          (change)="onFileChanged($event)"
          #fileInput
        />
      </div>
    </div>
  </div>

  <footer>
    <button (click)="closeDialog()">Fechar</button>
  </footer>
</main>
