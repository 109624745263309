import { StatusProtocolo, CategoriaProtocolo, SubCategoriaProtocolo, Autor } from "./index";
export class Protocolo {

    id: number ;
    autor: Autor;
    nome: string;
    imagem: string;
    latitude: number;
    longitude: number;
    mensagem: string;
    categoriaServico: CategoriaProtocolo;
    subCategoria: SubCategoriaProtocolo;
    status: StatusProtocolo;
    created_at: string;
    emAtendimento: boolean;
    placaVeiculo?: string;
    endereco?: string;
    usarPlaca?: boolean;

    constructor(id: number, autor: Autor, nome: string, imagem: string,
        latitude: number, longitude: number, mensagem: string, created_at: string, usarPlaca: boolean = false) {
            this.id=id;
            this.autor=autor;
            this.nome= nome;
            this.imagem=imagem;
            this.latitude=latitude;
            this.longitude=longitude;
            this.mensagem=mensagem;
            this.created_at=created_at;
            this.usarPlaca=usarPlaca;
    }

    get nomeFormatado(){
        return this.autor ?  this.autor.name : this.nome;
    }
 
    
}

/*

id": 1,
"autor": 1,
"nome": null,
"imagem": "https://igx.4sqi.net/img/general/600x600/25835854_IG80tKxyY1twcP0MhZQ_WYI-zxpIqSmksNTqPGCTCAU.jpg",
"latitude": "-5.8124020141",
"longitude": "-35.2047349513",
"mensagem": "Os postes da avenida Pedro Vasconcelos estão quebrados na localização selecionada.",
"categoriaServico": 1,
"subCategoriaServico": 1,
"status": {
"id": 2,
"titulo": "Encaminhado"
},
"created_at": "30/10/2018",
"categoria_servico": {
"id": 1,
"titulo": "Ouvidoria",
"descricao": "Contato com a prefeitura",
"imagem": "https://svgsilh.com/svg_v2/310399.svg",
"template": 1,
"created_at": "30/10/2018 - 13:49"
},
"sub_categoria_servico": {
"id": 1,
"titulo": "Poste quebrado",
"categoriaServico": 2,
"created_at": "30/10/2018 13:49"
}



*/