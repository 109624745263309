var HttpsRequestInterceptor = /** @class */ (function () {
    function HttpsRequestInterceptor() {
    }
    HttpsRequestInterceptor.prototype.intercept = function (request, next) {
        var adicionarToken = true;
        try {
            if (!(new URL(request.url).hostname).includes("grt8.com.br")
                && !(new URL(request.url).hostname).includes("mobby.inf.br")
                && !(new URL(request.url).hostname).includes("localhost")
                && !(new URL(request.url).hostname).includes("natal.br"))
                adicionarToken = false;
        }
        catch (error) { }
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.access_token && adicionarToken) {
            request = request.clone({
                setHeaders: {
                    'Authorization': "Bearer " + currentUser.access_token,
                    'Accept': 'application/json',
                }
            });
        }
        return next.handle(request);
    };
    return HttpsRequestInterceptor;
}());
export { HttpsRequestInterceptor };
