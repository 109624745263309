import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export type FeedbackData = {
  content: string,
  model?: string;
};

@Component({
  selector: 'feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeedbackData
  ) {}


  close(): void {
    this.dialogRef.close();
  }
}