import * as i0 from "@angular/core";
var State = /** @class */ (function () {
    function State() {
        this.data = {
            email: "",
            token: "",
            changed: false
        };
    }
    State.prototype.password_was_changed = function () {
        this.data.changed = true;
    };
    State.prototype.set_email = function (email) {
        this.data.email = email;
    };
    State.prototype.set_token = function (token) {
        this.data.token = token;
    };
    State.prototype.get_data = function () {
        return this.data;
    };
    State.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function State_Factory() { return new State(); }, token: State, providedIn: "root" });
    return State;
}());
export { State };
