<mat-sidenav-container class="container-main" [hasBackdrop]="false" autosize>
  <mat-sidenav
    #sidenav
    class="sidenav-menu no-printable "
    mode="side"
    
    [(opened)]="opened"
  >
    <div class="menuNz">
      <div *ngIf="!isCollapsed" class="users-details">
        <input
          type="file"
          #fileInput
          (change)="onFileChanged($event)"
          style="display: none"
          accept="image/png, image/jpeg"
        />
        <img
          class="user-avatar"
          *ngIf="dadosPerfil && dadosPerfil.imagem"
          [src]="dadosPerfil.imagem"
          (click)="fileInput.click()"
        />
        <img
          class="user-avatar"
          *ngIf="!dadosPerfil || !dadosPerfil.imagem"
          src="/assets/img/personicon.png"
          (click)="fileInput.click()"
        />
        <div class="user-infos">
          <div class="name">{{ dadosPerfil?.name }}</div>
          <div ngbDropdown class="d-inline-block">
            <button mat-button [matMenuTriggerFor]="menu">
              {{ translate.instant("defaultlayout.acoes") }}
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="fileInput.click()">
                {{ translate.instant("defaultlayout.trocarfoto") }}
              </button>
              <button mat-menu-item routerLink="/altera-senha">
                {{ translate.instant("defaultlayout.alterarsenha") }}
              </button>
              <button mat-menu-item routerLink="/entrar">
                {{ translate.instant("defaultlayout.sair") }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <ul
        nz-menu
        nzTheme="light"
        nzMode="inline"
        [nzInlineCollapsed]="isCollapsed"
        *ngIf="navItems && navItems.noCategorias"
        class="itensNormaisMenu"
      >
        <li
          *ngFor="let item of navItems.noCategorias"
          nz-menu-item
          nz-tooltip
          [nzSelected]
          [routerLink]="[item.url]"
          [nzTitle]="isCollapsed ? item?.name : ''"
          nzSelected
          style="display: flex; align-items: center"
        >
          <i nz-icon [nzType]="item.icon"></i>
          <span>{{ item?.name }}</span>
        </li>
        <ng-container *ngFor="let item of navItems.categorias">
          <li
            *ngIf="navItems[item].length > 1"
            nz-submenu
            [nzTitle]="item"
            [nzIcon]="navItems[item][0].icon"
          >
            <ul>
              <li
                *ngFor="let menu of navItems[item]"
                [nzMatchRouterExact]
                [routerLink]="[menu.url]"
                nz-menu-item
              >
                {{ menu?.name }}
              </li>
            </ul>
          </li>

          <li
            *ngIf="navItems[item].length == 1"
            nz-menu-item
            nz-tooltip
            [nzSelected]
            [routerLink]="[navItems[item][0].url]"
            [nzTitle]="navItems[item][0].name"
            nzSelected
            style="display: flex; align-items: center"
          >
            <i nz-icon [nzType]="navItems[item][0].icon"></i>
            <span>{{ navItems[item][0].name }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <nz-layout>
      <nz-header class="no-printable bg-sethas">
        <i
          class="trigger"
          nz-icon
          [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          (click)="isCollapsed = !isCollapsed"
        ></i>
        <img
          src="assets/img/brand/api.sethas.logo.png"
          class="logo-prefeitura"
          alt="Logo Prefeitura"
        />

        <span class="adjust-position">
          <a (click)="useLanguage('es')"
            ><img
              src="assets/img/bandeiraes.png"
              height="36"
              alt="Bandeira Espanha" /></a
          >&nbsp;
          <a (click)="useLanguage('pt-BR')"
            ><img
              src="assets/img/bandeirabr.png"
              height="32"
              alt="Bandeira Brasil"
          /></a>
        </span>
      </nz-header>
      <nz-content class="content-main">
        <div class="card reduced-border" *ngIf="exibirCard; else notShowCart">
          <div class="card-body">
            <flash-messages></flash-messages>
            <router-outlet class="only-printable"></router-outlet>
          </div>
        </div>
        <ng-template #notShowCart>
          <flash-messages></flash-messages>
          <router-outlet class="only-printable"></router-outlet>
        </ng-template>
      </nz-content>
    </nz-layout>
  </mat-sidenav-content>
</mat-sidenav-container>
