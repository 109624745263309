import { Component, Output, EventEmitter, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "not-data",
  templateUrl: './not-data.component.html',
  styleUrls: ['./not-data.component.scss']
})
export class NoteDataComponent {
  @Input() visible: boolean;
  @Input() message: any;
  constructor(public translate: TranslateService) {
  }
}