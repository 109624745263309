import { Component, OnInit } from '@angular/core';
import { FieldConfig } from "../../field.interface";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-froala-wysiwyg',
  template: `
    <div [formGroup]="group">
      <label class="radio-label-padding">{{field.label}}:</label>
      <quill-editor [formControlName]="field.name">{{ field.value }}</quill-editor>
      <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </div>
  `,
  styles: []
})
export class FroalaWysiwygComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }

}
