
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
<div class="row">
  <small class="text-center col-md-12">
    <b>  {{ translate.instant("protocolos.atribuirVideoChamada") }} - {{protocolo.id}}</b><br><hr>
  </small>
</div>
<div mat-dialog-content>
  <mat-form-field *ngIf="usuarios"  class="col-sm-12">
    <mat-select placeholder="Responsável" [(value)]="usuarioSelecionado" (selectionChange)="selecionarUsuario($event)" required>
      <mat-option *ngFor="let usuario of usuarios" [value]="usuario">
        {{usuario.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions text-center>
  <hr>
  <button class="col-md-12" mat-raised-button color="primary" (click)="criarVideoChamada()"> {{ translate.instant('protocolos.adicionar') }} </button>
</div>