var ResultPage = /** @class */ (function () {
    function ResultPage(data, code) {
        this.data = data;
        this.code = code;
    }
    ResultPage.prototype.isOk = function () {
        return this.code && this.code == 200;
    };
    return ResultPage;
}());
export { ResultPage };
