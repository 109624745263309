import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var DateConverterPipe = /** @class */ (function (_super) {
    tslib_1.__extends(DateConverterPipe, _super);
    function DateConverterPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateConverterPipe.prototype.transform = function (value, format, timezone, locale) {
        if (format === void 0) { format = 'mediumDate'; }
        if (value == null || value === '' || value !== value)
            return null;
        var toConvert = value;
        if (DateConverterPipe.isTextoFormatado(value)) {
            toConvert = DateConverterPipe.textoParaData(value);
        }
        return _super.prototype.transform.call(this, toConvert, format, locale || locale, timezone);
    };
    DateConverterPipe.textoParaData = function (texto) {
        //21/11/2018 - 11:34
        if (/^\d{2}\/\d{2}\/\d{4}\s-\s\d{2}:\d{2}$/.test(texto)) {
            var _dateTime = texto.split('-');
            var _data = _dateTime[0].trim().split('/');
            var _tempo = _dateTime[1].trim().split(':');
            return new Date(_data[2], _data[1] - 1, _data[0], _tempo[0], _tempo[1]);
        }
    };
    DateConverterPipe.isTextoFormatado = function (texto) {
        //21/11/2018 - 11:34
        return (/^\d{2}\/\d{2}\/\d{4}\s-\s\d{2}:\d{2}$/.test(texto));
    };
    DateConverterPipe.toStringFormat = function (date) {
        //dd/mm/yyyy - hh:MM
        return date != null ? DateConverterPipe.leftPad(date.getDate()) + "/" + DateConverterPipe.leftPad(date.getMonth() + 1) + "/" + date.getFullYear() + " - " + DateConverterPipe.leftPad(date.getHours()) + ":" + DateConverterPipe.leftPad(date.getMinutes()) : '';
    };
    DateConverterPipe.stringFormat = function (date) {
        //dd/mm/yyyy hh:MM
        return DateConverterPipe.toStringFormat(new Date(date));
        //return date!=null ?  `${date.split(' ')[0].split('-').reverse().join('/')} ${date.split(' ')[1].split('-').reverse().join('/')}` :'';
    };
    DateConverterPipe.stringConverte = function (date) {
        //dd/mm/yyyy hh:MM
        return date != null ? date.split(' - ')[0].split('/').reverse().join('-') + "T" + date.split(' - ')[1] : '';
    };
    DateConverterPipe.stringConverteSimple = function (date) {
        console.log(date);
        //dd/mm/yyyy
        return date != null ? "" + date.split('-').reverse().join('/') : '';
    };
    DateConverterPipe.leftPad = function (numero) {
        return numero > 9 ? "" + numero : "0" + numero;
    };
    return DateConverterPipe;
}(DatePipe));
export { DateConverterPipe };
