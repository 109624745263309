import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { PerfilLogado } from './model';
import { DomainService } from './services/domain-service';
import { Charts, ChartsResponseData } from "./views/dashboard/geral/dashboard-geral.component";

export type PaginatedResponse = {
  "current_page": number,
  "data": Array<object>,
  "first_page_url": string,
  "from": number,
  "last_page": number,
  "last_page_url": string,
  "next_page_url": string | null,
  "path": string,
  "per_page": number,
  "prev_page_url": string | null,
  "to": number,
  "total": number;
};

@Injectable({
  providedIn: 'root'
})
export class APIService {

  public endpoint;
  public client_id = '2';
  public client_secret = 'l7xF7EsRAH3buuvuoiERhjAD84XWeKbPRPQea6JN';
  public dadosPerfil: PerfilLogado;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private httpOptionsArquivos = {

  };
  constructor(private httpClient: HttpClient, private domainSvc: DomainService) {
    this.endpoint = 'https://sethas.api.grt8.com.br:4026';
    console.log('Consumindo api na url: ', this.endpoint);
    this.getChaves().toPromise().then(
      data => {
        this.client_secret = data['pessoal']['token'];
        this.client_id = data['pessoal']['id'];
      }
    );

    const retorno = this.getConfigs().subscribe((data: Array<object>) => {
      data.map(cfg => {
        const prop = JSON.parse(JSON.stringify(cfg));
        if (prop.campo == "nomePrefeitura") {
          localStorage.setItem('nomePrefeitura', prop.valor);
        } else if (prop.campo == "apiName") {
          localStorage.setItem('apiName', prop.valor);
        } else if (prop.campo == "categoriaSuporte") {
          localStorage.setItem('categoriaSuporte', prop.valor);
        }
      });
    });

  }

  private extractData(res: Response) {
    return res || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }


  criarPedidoSuporte(dados) {
    return this.httpClient.post(`${environment.servidor_suporte}/protocolos/anonimo`, dados);
  }
  subCategoriaSuporte() {
    return this.httpClient.get(`${environment.servidor_suporte}/categoriaServicos`);
  }
  getLocationPosition(position) {
    return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${JSON.stringify(position)}&key=${environment.googlekey}`);
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM AUTENTICAÇÃO        ==============================
  // =================================================================================
  alteraSenhaAuth(dados) {
    return this.httpClient.post(`${this.endpoint}/usuarios/perfil/alterarSenha`, dados);
  }
  getChaves() {
    return this.httpClient.get(`${this.endpoint}/tokens`).pipe(
      map(this.extractData));
  }
  getProtocoloLog(id): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/logs/protocolos/${id}/baixarPdf`, this.httpOptionsArquivos)
      .map(res => res);
  }


  usuarioPossuiChaveValida(): Promise<boolean> {
    return this.httpClient.get(`${this.endpoint}/usuarios/perfil`).toPromise().then(
      data => {
        return true;
      },
      () => {
        return false;
      }
    );
  }

  getPerfilUsuario() {
    return this.httpClient.get(`${this.endpoint}/usuarios/perfil`)
      .pipe(map(this.extractData));
  }

  getDadosCorona(apenasSuspeitos: Number = 0) {
    return this.httpClient.get(`${this.endpoint}/corona/resultados?apenasSuspeitos=${apenasSuspeitos}`)
      .pipe(map(this.extractData));
  }
  getDadosCoronaPorData(inicial, final, pagina: Number = 0, apenasSuspeitos: Number = 0) {
    return this.httpClient.get(`${this.endpoint}/corona/relatorio?created_at%2Fend=${final}&created_at%2Fstart=${inicial}&page=${pagina}&apenasSuspeitos=${apenasSuspeitos}&porPagina=100`);
  }
  getCadastrosCoronaPorData(pagina: Number = 0, agenda) {
    return this.httpClient.get(`${this.endpoint}/cadastro_agendamento?agendamento_id=${agenda}&page=1&porPagina=100`);
  }
  getMinhasInstituicoes() {
    return this.httpClient.get(`${this.endpoint}/instituicoes_por_permissao`).pipe(map(this.extractData));
  }
  getCadastrosAgendamentoData(idservico, pagina: Number = 1, filtros, inicial, final) {
    return this.httpClient.get(`${this.endpoint}/cadastros/${idservico}?paginate=1&page=${pagina}&porPagina=15&porData=${inicial}`, { params: filtros });
  }
  getCadastrosAgendamento(idservico, pagina: Number = 1, filtros?,) {
    return this.httpClient.get(`${this.endpoint}/cadastros/${idservico}?paginate=1&page=${pagina}&porPagina=15`, { params: filtros });
  }

  agendamentosRelatorio(queryString?: string) {
    return this.httpClient
      .get(`${this.endpoint}/v2/agendamentos/relatorio${queryString}`)
      .pipe(map(this.extractData))
      .toPromise();
  }

  getDadosAgendamentos(inicial, final) {
    let dataFiltroInicio = new Date(inicial);
    let inicio_atendimento = `${("0" + dataFiltroInicio.getDate()).slice(-2)}/${("0" + (dataFiltroInicio.getMonth() + 1)).slice(-2)}/${dataFiltroInicio.getFullYear()}`;

    return this.httpClient.get(`${this.endpoint}/servicos/dashboard?inicio_atendimento=${encodeURI(inicio_atendimento)}&inicio=${this.datetoYMD(inicial)}&fim=${this.datetoYMD(final)}`).pipe(map(this.extractData));
  }

  datetoYMD(date) {
    if (date.constructor.name === "Moment") {
      date = date.toDate();
    }

    try {
      date.getFullYear();
    }
    catch (e) {
      date = new Date(date.toString());
    }

    //return `${date.toString().split(" ")[3]}-${date.getMonth()+1<10?'0':''}${date.getMonth()+1}-${date.getDate()<10?'0':''}${date.getDate()}`

    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  }

  getAgendamentosPorCPF(cpf) {
    return this.httpClient.get(`${this.endpoint}/agendamentos/buscar?cpf=${cpf}`);
  }

  getCadastrosCoronaData(pagina: Number = 1, filtros, inicial, final) {
    return this.httpClient.get(`${this.endpoint}/cadastro_agendamento?page=${pagina}&porPagina=15&created_at%2Fstart=${inicial}&created_at%2Fend=${final}`, { params: filtros });
  }
  getCadastrosCorona(pagina: Number = 1, filtros?,) {
    return this.httpClient.get(`${this.endpoint}/cadastro_agendamento?page=${pagina}&porPagina=15`, { params: filtros });
  }
  getAgendasCorona(pagina: Number = 1, semPaginacao?) {
    if (semPaginacao) {
      return this.httpClient.get(`${this.endpoint}/agendamento?page=${pagina}&porPagina=15&semPaginacao=1`);
    }
    return this.httpClient.get(`${this.endpoint}/agendamento?page=${pagina}&porPagina=15`);
  }
  getAgendasServico(idservico, pagina: Number = 1, semPaginacao?) {
    if (semPaginacao) {
      return this.httpClient.get(`${this.endpoint}/servicos/${idservico}/agendas?semPaginacao=1&servico_agendamento_id=${idservico}`);
    }
    return this.httpClient.get(`${this.endpoint}/servicos/${idservico}/agendas?page=${pagina}&porPagina=15&servico_agendamento_id=${idservico}`);
  }
  getInstituicoes(pagina: Number = 1, semPaginacao?) {
    if (semPaginacao) {
      return this.httpClient.get(`${this.endpoint}/instituicoes?page=${pagina}&porPagina=30&semPaginacao=1`);
    }
    return this.httpClient.get(`${this.endpoint}/instituicoes?page=${pagina}&porPagina=30`);
  }

  alterarVisibilidadeSetor(id, status) {
    return this.httpClient.post(`${this.endpoint}/v2/setores/visibilidade/${id}`, { status });
  }

  getSetores(id, pagina: Number = 1, semPaginacao?) {
    if (semPaginacao) {
      return this.httpClient.get(`${this.endpoint}/instituicoes/${id}/setores?semPaginacao=1`);
    }
    return this.httpClient.get(`${this.endpoint}/instituicoes/${id}/setores?page=${pagina}&porPagina=30`);
  }
  getServicosAgendamento(id, pagina: Number = 1, semPaginacao?) {
    if (semPaginacao) {
      return this.httpClient.get(`${this.endpoint}/setores/${id}/servicos?page=${pagina}&porPagina=30&semPaginacao=1`);
    }
    return this.httpClient.get(`${this.endpoint}/setores/${id}/servicos?page=${pagina}&porPagina=30`);
  }
  criarInstituicoes(update = false, id = 0, dados) {
    if (update) {
      return this.httpClient.post(`${this.endpoint}/v2/instituicoes/${id}`, dados);
    } else {
      return this.httpClient.post(`${this.endpoint}/v2/instituicoes`, dados);
    }
  }
  criarSetor(idinstituicao, update = false, id = 0, dados) {
    if (update) {
      return this.httpClient.post(`${this.endpoint}/instituicoes/${idinstituicao}/setores/${id}`, dados);
    } else {
      return this.httpClient.post(`${this.endpoint}/instituicoes/${idinstituicao}/setores`, dados);
    }
  }
  criarServico(idsetor, update = false, id = 0, dados) {
    if (update) {
      return this.httpClient.post(`${this.endpoint}/setores/${idsetor}/servicos/${id}`, dados);
    } else {
      return this.httpClient.post(`${this.endpoint}/setores/${idsetor}/servicos`, dados);
    }
  }
  deletarInstituicoes(id) {
    return this.httpClient.delete(`${this.endpoint}/instituicoes/${id}`);
  }
  deletarSetor(idinstituicao, id) {
    return this.httpClient.delete(`${this.endpoint}/instituicoes/${idinstituicao}/setores/${id}`);
  }
  deletarServico(idsetor, id) {
    return this.httpClient.delete(`${this.endpoint}/setores/${idsetor}/servicos/${id}`);
  }
  agendarCadastro(idCadastro, idAgenda) {
    return this.httpClient.post(`${this.endpoint}/cadastro_agendamento/${idCadastro}/agenda/${idAgenda}`, {});
  }
  deleteAgendarCadastro(idAgenda, idCadastro) {
    return this.httpClient.delete(`${this.endpoint}/agendamentos/${idAgenda}/cadastros/${idCadastro}`);
  }
  deleteAgenda(idServico, idAgenda) {
    return this.httpClient.delete(`${this.endpoint}/servicos/${idServico}/agendas/${idAgenda}`);
  }
  updateAgendarCadastro(idAgenda, idCadastro, dados) {
    return this.httpClient.put(`${this.endpoint}/agendamentos/${idAgenda}/cadastros/${idCadastro}`, {
      // _method: 'PUT',
      ...dados
    });
  }
  criarAgendamento(update = false, id = 0, dados) {
    if (update) {
      return this.httpClient.post(`${this.endpoint}/servicos/${id}/agendas`, dados);
    } else {
      return this.httpClient.post(`${this.endpoint}/servicos/${id}/agendas`, dados);
    }
  }

  criarAgendamentoV2(update = false, id = 0, dados) {
    if (update) {
      return this.httpClient.post(`${this.endpoint}/v2/servicos/${id}/agendas_periodo`, dados);
    } else {
      return this.httpClient.post(`${this.endpoint}/v2/servicos/${id}/agendas_periodo`, dados);
    }
  }

  reordenarCamposExtrasServico(ordem, idServico) {
    return this.httpClient.post(`${this.endpoint}/v2/campos_extras/${idServico}/servicos/set_order`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }

  getCamposExtrasServico(idServico, pagina: Number = 1, semPaginacao = false) {
    if (!semPaginacao) {
      return this.httpClient.get(`${this.endpoint}/v2/campos_extras/${idServico}/servicos`);
    }
    return this.httpClient.get(`${this.endpoint}/v2/campos_extras/${idServico}/servicos?paginate=1&page=${pagina}`);
  }

  getCamposExtrasServicoExtraPorId(id, idServico) {
    return this.httpClient.get(`${this.endpoint}/v2/campos_extras/${idServico}/servicos/${id}`).pipe(
      map(this.extractData));
  }
  criarCamposExtrasServicoExtra(campoExtra, idServico) {
    return this.httpClient.post(`${this.endpoint}/v2/campos_extras/${idServico}/servicos`, campoExtra).pipe(
      tap(_ => console.log(`created campo extra id=${campoExtra}`)),
    );
  }
  editarCamposExtrasServicoExtra(campoExtra, id, idServico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/campos_extras/${idServico}/servicos/${id}`, campoExtra).pipe(
      tap(_ => console.log(`updated campo extra id=${id}`)),
    );
  }
  deleteCamposExtrasServicoExtra(id, idServico): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/v2/campos_extras/${idServico}/servicos/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted campo extra id=${id}`)),
    );
  }


  // =================================================================================
  // ==========       OPERAÇÕES PARA AGRUPAMENTO DE PROTOCOLO       ==================
  // =================================================================================

  getProtocolosDisponiveisSemChat(pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/agrupamento/protocolosDisponiveisSemChat?page=${pagina}&porPagina=100`).pipe(
      map(this.extractData));
  }
  getProtocolosDisponiveisSemChatComFiltro(categoriaId) {
    if (categoriaId) {
      return this.httpClient.get(`${this.endpoint}/agrupamento/protocolosDisponiveisSemChat?porPagina=100&categoriaServico=${categoriaId}&status%2Fnot=${environment.id_status_encerrado}`).pipe(
        map(this.extractData));
    } else {
      return this.httpClient.get(`${this.endpoint}/agrupamento/protocolosDisponiveisSemChat?porPagina=100&status%2Fnot=${environment.id_status_encerrado}`).pipe(
        map(this.extractData));
    }
  }

  getAgrupamentos(pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/agrupamento/protocolo?porPagina=5&page=${pagina}`).pipe(
      map(this.extractData));
  }

  getMeusAgrupamentos(pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/agrupamento/meusAgrupamentos?porPagina=5&page=${pagina}`).pipe(
      map(this.extractData));
  }

  getAgrupamento(id) {
    return this.httpClient.get(`${this.endpoint}/agrupamento/protocolo/${id}`).pipe(
      map(this.extractData));
  }

  createAgrupamento(agrupamento) {
    return this.httpClient.post(`${this.endpoint}/agrupamento/protocolo`, agrupamento).pipe(
      tap(_ => console.log(`created agrupamento id=${agrupamento}`)),
    );
  }

  deleteAgrupamento(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/agrupamento/protocolo/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted agrupamento id=${id}`)),
    );
  }

  updateAgrupamento(id, agrupamento): Observable<any> {
    return this.httpClient.put(`${this.endpoint}/agrupamento/protocolo/${id}`, agrupamento).pipe(
      tap(_ => console.log(`updated agrupamento id=${id}`)),
    );
  }

  updateResponsavelAgrupamento(idAgrupamento, idUsuario): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/agrupamento/${idAgrupamento}/usuario/${idUsuario}/alterarAtribuicao`, {}).pipe(
      tap(_ => console.log(`updated usuário agrupamento id=${idAgrupamento}`)),
    );
  }

  updateAgrupamentoAdicionarProtocolos(idAgrupamento, agrupamento): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/agrupamento/${idAgrupamento}/adicionar`, agrupamento).pipe(
      tap(_ => console.log(`updated usuário agrupamento id=${idAgrupamento}`)),
    );
  }

  updateAgrupamentoRemoverProtocolos(idAgrupamento, agrupamento): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/agrupamento/${idAgrupamento}/excluir`, agrupamento).pipe(
      tap(_ => console.log(`updated usuário agrupamento id=${idAgrupamento}`)),
    );
  }

  enviarMensagemAgrupamentoProtocolos(idAgrupamento, mensagem): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/agrupamento/${idAgrupamento}/broadcastMensagem`, mensagem).pipe(
      tap(_ => console.log(`enviando mensagem para os protocolos do agrupamento id=${idAgrupamento}`)),
    );
  }

  alterarStatusAgrupamentoProtocolos(idAgrupamento, idStatus): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/agrupamento/${idAgrupamento}/status/${idStatus}/alterarStatus`).pipe(
      tap(_ => console.log(`altera o status do agrupamento de protocolos id=${idAgrupamento}`)),
    );
  }

  finalizarAgrupamentoProtocolos(idAgrupamento): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/agrupamento/${idAgrupamento}/finalizar`).pipe(
      tap(_ => console.log(`finalizando o agrupamento de protocolos id=${idAgrupamento}`)),
    );
  }


  // agrupamento/{idAgrupamento}/usuario/{idUsuario}/alterarAtribuicao





  // =================================================================================
  // ==========           OPERAÇÕES COM NOTICIAS        ==============================
  // =================================================================================
  getNoticias(pagina: Number = 1, categoria?) {
    if (categoria) {
      return this.httpClient.get(`${this.endpoint}/noticias?page=${pagina}&categoria=${categoria}`).pipe(
        map(this.extractData));
    } else {
      return this.httpClient.get(`${this.endpoint}/noticias?page=${pagina}`).pipe(
        map(this.extractData));
    }
  }
  getNoticia(id) {
    return this.httpClient.get(`${this.endpoint}/noticias/${id}`).pipe(
      map(this.extractData));
  }

  createNoticia(noticia) {
    return this.httpClient.post(`${this.endpoint}/noticias`, noticia).pipe(
      tap(_ => console.log(`created noticia id=${noticia}`)),
    );
  }
  updateNoticia(id, noticia): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/noticias/${id}`, noticia).pipe(
      tap(_ => console.log(`updated noticia id=${id}`)),
    );
  }

  deleteNoticia(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/noticias/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted noticia id=${id}`)),
    );
  }

  getCategoriasNoticia() {
    return this.httpClient.get(`${this.endpoint}/categoriaNoticias`).pipe(
      map(this.extractData));
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM CATEGORIA DE NOTICIAS        =================
  // =================================================================================
  getCategoriaNoticias() {
    return this.httpClient.get(`${this.endpoint}/categoriaNoticias`).pipe(
      map(this.extractData));
  }
  getCategoriaNoticia(id) {
    return this.httpClient.get(`${this.endpoint}/categoriaNoticias/${id}`).pipe(
      map(this.extractData));
  }

  createCategoriaNoticia(CategoriaNoticia) {
    return this.httpClient.post(`${this.endpoint}/categoriaNoticias`, CategoriaNoticia).pipe(
      tap(_ => console.log(`created CategoriaNoticia id=${CategoriaNoticia}`)),
    );
  }
  updateCategoriaNoticia(id, CategoriaNoticia): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/categoriaNoticias/${id}`, CategoriaNoticia).pipe(
      tap(_ => console.log(`updated CategoriaNoticia id=${id}`)),
    );
  }

  deleteCategoriaNoticiaForcado(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/categoriaNoticias/${id}/forced`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted CategoriaNoticia id=${id}`)),
    );
  }
  deleteCategoriaNoticia(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/categoriaNoticias/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted CategoriaNoticia id=${id}`)),
    );
  }

  saveNoticiaImagem(id, image) {
    return this.httpClient
      .post(`${this.endpoint}/v2/noticias/${id}/salvarImagem`, image)
      .pipe<any>(item => (item));
  }

  removeNoticiaImagem(id, image) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/noticias/${id}/deleteImagem?url=` + image)
      .pipe<any>(item => (item));
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM INFORMAÇÕES      =================
  // =================================================================================
  deleteFotoInformacao(id) {
    return this.httpClient.delete(`${this.endpoint}/informacoes/imagem/${id}`).pipe();
  }
  createFotoInformacao(campos) {
    return this.httpClient.post(`${this.endpoint}/informacoes/imagem`, campos).pipe(
      tap(_ => console.log(`created moldura conteudo=${campos}`)),
    );
  }

  getinformacoes(pagina: Number = 1, categoria?: Number) {
    if (categoria) {
      return this.httpClient.get(`${this.endpoint}/informacoes?page=${pagina}&categoria=${categoria}`).pipe(
        map(this.extractData));
    } else {
      return this.httpClient.get(`${this.endpoint}/informacoes?page=${pagina}`).pipe(
        map(this.extractData));
    }
  }
  getinformacao(id) {
    return this.httpClient.get(`${this.endpoint}/informacoes/${id}`).pipe(
      map(this.extractData));
  }

  createinformacoes(informacoes) {
    return this.httpClient.post(`${this.endpoint}/informacoes`, informacoes).pipe(
      tap(_ => console.log(`created informacoes id=${informacoes}`)),
    );
  }
  updateInformacao(id, informacoes): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/informacoes/${id}`, informacoes).pipe(
      tap(_ => console.log(`updated informacoes id=${id}`)),
    );
  }

  deleteinformacoes(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/informacoes/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted informacoes id=${id}`)),
    );
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM CATEGORIA DE INFORMAÇÕES      =================
  // =================================================================================
  getCategoriasInformacoes() {
    return this.httpClient.get(`${this.endpoint}/categoriaInformacoes`).pipe(
      map(this.extractData));
  }
  getCategoriaInformacao(id) {
    return this.httpClient.get(`${this.endpoint}/categoriaInformacoes/${id}`).pipe(
      map(this.extractData));
  }

  getCategoriaTempoMedio() {
    return this.httpClient.get(`${this.endpoint}/atendimento/categorias/tempoMedio`).pipe(
      map(this.extractData));
  }

  createCategoriaInformacoes(informacoes) {
    return this.httpClient.post(`${this.endpoint}/categoriaInformacoes`, informacoes).pipe(
      tap(_ => console.log(`created categoriaInformacoes id=${informacoes}`)),
    );
  }
  enviarArquivoProtocolo(protocolo, dados) {
    return this.httpClient.post(`${this.endpoint}/protocolos/${protocolo}/mensagens/arquivo`, dados).pipe(
      tap(_ => console.log(`created arquivo em protocolo id=${dados}`)),
    );
  }
  updateCategoriainformacoes(id, informacoes): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/categoriaInformacoes/${id}`, informacoes).pipe(
      tap(_ => console.log(`updated categoriaInformacoes id=${id}`)),
    );
  }

  deleteCategoriaInformacoes(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/categoriaInformacoes/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted categoriaInformacoes id=${id}`)),
    );
  }
  deleteCategoriaInformacoesForcado(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/categoriaInformacoes/${id}/forced`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted categoriaInformacoes id=${id}`)),
    );
  }

  reordenarCategoriaInformacoes(ordem) {
    return this.httpClient.post(`${this.endpoint}/categoriaInformacoes/ordenar`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }

  updateGeocodingInfo(idProtocolo, lat, lgn) {
    return this.httpClient.put(`${this.endpoint}/informacoes/${idProtocolo}/localizacao`, {
      latitude: lat,
      longitude: lgn
    }).pipe();
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM STATUS        ==============================
  // =================================================================================
  getStatus() {
    return this.httpClient.get(`${this.endpoint}/status`).pipe(
      map(this.extractData));
  }


  // =================================================================================
  // ==========           OPERAÇÕES COM VIDEOCHAMADA        ==========================
  // =================================================================================
  getVideoChamadas(pagina: Number = 1, porPagina?) {
    let stringCon = `${this.endpoint}/video_chamadas/atribuidas?page=${pagina}`;
    if (porPagina) { stringCon = stringCon + `&porPagina=${porPagina}`; }
    return this.httpClient.get(stringCon).pipe(
      map(this.extractData));
  }

  getVideoToken() {
    return this.httpClient.get(`${this.endpoint}/video_chamadas/token`).pipe(
      map(this.extractData));
  }

  createVideoChamada(idprotocolo, idresponsavel) {
    return this.httpClient.post(`${this.endpoint}/protocolos/${idprotocolo}/video_chamadas`, {
      protocolo: idprotocolo,
      protocolo_id: idprotocolo,
      responsible_user_id: idresponsavel
    }).pipe(
      map(this.extractData));
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM SERVIÇOS        ==============================
  // =================================================================================

  getServicosPaginado(pagina: Number = 1, area?, categoria?, subCategoria?, status?, bairro?, macroregiao?, dataInicial?, dataFinal?) {
    let stringCon = `${this.endpoint}/protocolos?page=${pagina}`;
    if (area) { stringCon = stringCon + `&area_categoria=${area}`; }
    if (categoria) { stringCon = stringCon + `&categoriaServico=${categoria}`; }
    if (subCategoria) { stringCon = stringCon + `&subCategoriaServico=${subCategoria}`; }
    if (status) { stringCon = stringCon + `&status=${status}`; }
    // if (porPagina) { stringCon = stringCon + `&porPagina=${porPagina}`; }
    if (bairro) { stringCon = stringCon + `&bairro_id=${bairro}`; }
    if (macroregiao) { stringCon = stringCon + `&macroRegiao=${macroregiao}`; }
    if (dataInicial) { stringCon = stringCon + `&data_inicio=${dataInicial}`; }
    if (dataFinal) { stringCon = stringCon + `&data_fim=${dataFinal}`; }


    return this.httpClient.get(stringCon).pipe(
      map(this.extractData));
  }
  getServicosSemPagina(pagina: Number = 1, categoria?, subCategoria?, status?, bairro?) {
    let stringCon = `${this.endpoint}/protocolos?page=${pagina}`;
    if (categoria) { stringCon = stringCon + `&categoriaServico=${categoria}`; }
    if (subCategoria) { stringCon = stringCon + `&subCategoriaServico=${subCategoria}`; }
    if (status) { stringCon = stringCon + `&status=${status}`; }
    if (bairro) { stringCon = stringCon + `&bairro_id=${bairro}`; }
    stringCon = stringCon + `&porPagina=100`;
    return this.httpClient.get(stringCon).pipe(
      map(this.extractData));
  }
  getProtocolosResumidos(area?, categoria?, subCategoria?, status?, bairro?, macro?, datainicial?, datafinal?) {
    let stringCon = `${this.endpoint}/v2/protocolos/resumido`;
    if (categoria || subCategoria || status || bairro) { stringCon = stringCon + `?conditions=`; }
    if (categoria) { stringCon = stringCon + `categoriaServico:=:${categoria}${subCategoria || status || bairro ? ";" : ""}`; }
    if (subCategoria) { stringCon = stringCon + `subCategoriaServico:=:${subCategoria}${status || bairro ? ";" : ""}`; }
    if (status) { stringCon = stringCon + `status:=:${status}${bairro ? ";" : ""}`; }
    if (bairro) { stringCon = stringCon + `bairro_id:=:${bairro}`; }

    if (!(categoria || subCategoria || status || bairro) && (datainicial || datafinal)) { stringCon = stringCon + `?`; }
    if ((categoria || subCategoria || status || bairro) && (datainicial || datafinal)) { stringCon = stringCon + `&`; }
    if (datainicial) { stringCon = stringCon + `data_inicio=${datainicial}`; }
    if (datainicial) { stringCon = stringCon + `&`; }
    if (datafinal) { stringCon = stringCon + `data_fim=${datafinal}`; }

    if (!(categoria || subCategoria || status || bairro) && !(datainicial || datafinal) && (macro || area)) { stringCon = stringCon + `?`; }
    if ((categoria || subCategoria || status || bairro) || (datainicial || datafinal) && (macro || area)) { stringCon = stringCon + `&`; }
    if (macro) {
      stringCon = stringCon + `macro_regiao=${macro}`;
    } else if (area) {
      stringCon = stringCon + `area_categoria=${area}`;
    }

    return this.httpClient.get(stringCon).pipe(
      map(this.extractData));
  }
  getServicos() {
    return this.httpClient.get(`${this.endpoint}/protocolos`).pipe(
      map(this.extractData));
  }
  getAgendamentosPorData(idServico, idAgenda) {
    return this.httpClient.get(`${this.endpoint}/servicos/${idServico}/agendas/${idAgenda}`).pipe(
      map(this.extractData));
  }
  getCadastroAgendamentosPorData(idServico, dataAgenda) {
    return this.httpClient.get(`${this.endpoint}/cadastros/${idServico}?data=${dataAgenda}`).pipe(
      map(this.extractData));
  }

  getDiasDisponiveisAgendamento(servicoId?) {
    return this.httpClient.get(`${this.endpoint}/servicos/${servicoId}/dias`).pipe(
      map(this.extractData));
  }

  getServicosPorData(inicial, final, pagina: Number = 0, status?, categoria?, subcategoria?, bairros?, macros?) {
    const statusStr = status && status !== 0 ? '&status=' + status : '';
    const categoriaStr = categoria && categoria !== 0 ? '&categoriaServico=' + categoria : '';
    const subStr = subcategoria && subcategoria !== 0 ? '&subCategoriaServico=' + subcategoria : '';
    const bairrosStr = bairros && bairros !== 0 ? '&bairro_id=' + bairros : '';
    const macrosStr = macros && macros !== 0 ? '&macroRegiao=' + macros : '';
    return this.httpClient.get(`${this.endpoint}/protocolos?created_at%2Fend=${final}&created_at%2Fstart=${inicial}&page=${pagina}${statusStr}${categoriaStr}${subStr}${bairrosStr}${macrosStr}&porPagina=100`).pipe(
      map(this.extractData));
  }
  getAtendimentoProtocolosAbertosPorCategoria(categoriaId: Number = 0) {
    if (categoriaId === 0) {
      return this.httpClient.get(`${this.endpoint}/atendimento/meusProtocolos?status=${environment.id_status_recebido}`).pipe(
        map(this.extractData));
    } else {
      return this.httpClient.get(`${this.endpoint}/atendimento/meusProtocolos?status=${environment.id_status_recebido}&categoriaServico=${categoriaId}`).pipe(
        map(this.extractData));
    }
  }
  getAtendimentosAbertosPorUser() {
    return this.httpClient.get(`${this.endpoint}/atendimento/meusAtendimentosAbertos`).pipe(
      map(this.extractData));
  }
  getProtocolo(id) {
    return this.httpClient.get(`${this.endpoint}/protocolos/${id}`).pipe(
      map(this.extractData));
  }
  getProtocolov2(id) {
    return this.httpClient.get(`${this.endpoint}/v2/protocolos?conditions=id:=:${id}`).pipe(
      map(this.extractData));
  }
  createProtocolo(Servico) {
    return this.httpClient.post(`${this.endpoint}/protocolos`, Servico).pipe(
      tap(_ => console.log(`created protocolo id=${Servico}`)),
    );;
  }
  createProtocoloSemUser(Servico) {
    return this.httpClient
      .post(`${this.endpoint}/protocolos/anonimo`, Servico)
      .pipe(tap((_) => console.log(`created protocolo id=${Servico}`)));
  }
  updateProtocolo(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/protocolos/${id}`, Servico).pipe(
      tap(_ => console.log(`updated Protocolo id=${id}`)),
    );
  }
  updateProtocoloAtendente(id, novoAtendenteId): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/protocolos/${id}/alterarAtendente`, {
      atendente: novoAtendenteId
    }).pipe(
      tap(_ => console.log(`updated atendente Protocolo id=${id}`)),
    );
  }
  updateProtocoloCategoria(id, novaCategoria, novaSubCategoria): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/protocolos/${id}/alterarCategoria`, {
      categoriaServico: novaCategoria,
      subCategoriaServico: novaSubCategoria,
    }).pipe(
      tap(_ => console.log(`updated atendente Protocolo id=${id}`)),
    );
  }
  deleteProtocolo(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/protocolos/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted Protocolo id=${id}`)),
    );
  }

  getProtocolosEntrada() {
    return this.httpClient.get(`${this.endpoint}/v2/entrada`).pipe(
      map(this.extractData));
  }

  // =================================================================================
  // ==========        OPERAÇÕES COM CATEGORIA SERVIÇOS ==============================
  // =================================================================================
  reordenarCategorias(ordem) {
    return this.httpClient.post(`${this.endpoint}/categoriaServicos/ordenar`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }
  getSubCategoriaServicos() {
    return this.httpClient.get(`${this.endpoint}/categoriaServicos`).pipe(
      map(this.extractData));
  }

  getCategoriaServicos() {
    return this.httpClient.get(`${this.endpoint}/categoriaServicos`).pipe(
      map(this.extractData));
  }

  getCategoriaServicosv2() {
    return this.httpClient.get(`${this.endpoint}/v2/categoriaServicos`).pipe(
      map(this.extractData));
  }
  getStatusTotais() {
    return this.httpClient.get(`${this.endpoint}/status`).pipe(
      map(this.extractData));
  }
  getMinhasCategoriaServicos(id?: number) {
    let url = `${this.endpoint}/atendimento/minhasCategorias${id ? `?area_categoria_servico=${id}` : ''}`;

    return this.httpClient.get(url).pipe(
      map(this.extractData));
  }
  getMinhasCategoriaServicosAgrupamento() {
    return this.httpClient.get(`${this.endpoint}/agrupamento/minhasCategorias`).pipe(
      map(this.extractData));
  }
  getCategoriaServico(id) {
    return this.httpClient.get(`${this.endpoint}/categoriaServicos/${id}`).pipe(
      map(this.extractData));
  }
  createCategoriaServico(Servico) {
    return this.httpClient.post(`${this.endpoint}/categoriaServicos`, Servico).pipe(
      tap(_ => console.log(`created categoriaServicos id=${Servico}`)),
    );
  }
  deleteCategoriaServico(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/categoriaServicos/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted categoriaServicos Servico id=${id}`)),
    );
  }
  deleteCategoriaServicoForcado(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/categoriaServicos/${id}/forced`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted categoriaServicos Servico id=${id}`)),
    );
  }
  updateCategoriaServico(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/categoriaServicos/${id}`, Servico).pipe(
      tap(_ => console.log(`updated categoriaServicos Servico id=${id}`)),
    );
  }
  alterarVisibilidadeCategoria(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/categoriaServicos/${id}/visibilidade`, { visivel: estado }).pipe(
      tap(_ => console.log(`alter categoriaServicos visibility id=${id} to estate=${estado}`)),
    );
  }
  // =================================================================================
  // ==========        OPERAÇÕES COM AREA CATEGORIA SERVIÇOS ==============================
  // =================================================================================
  reordenarAreaCategorias(ordem) {
    return this.httpClient.post(`${this.endpoint}/areaCategoriaServicos/ordenar`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }

  getAreaCategoriaServicos() {
    return this.httpClient.get(`${this.endpoint}/areaCategoriaServicos?portal=true`).pipe(
      map(this.extractData));
  }
  getAreaCategoriaServico(id) {
    return this.httpClient.get(`${this.endpoint}/areaCategoriaServicos/${id}`).pipe(
      map(this.extractData));
  }
  createAreaCategoriaServico(Servico) {
    return this.httpClient.post(`${this.endpoint}/areaCategoriaServicos`, Servico).pipe(
      tap(_ => console.log(`created categoriaServicos id=${Servico}`)),
    );
  }
  deleteAreaCategoriaServico(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/areaCategoriaServicos/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted categoriaServicos Servico id=${id}`)),
    );
  }
  deleteAreaCategoriaServicoForcado(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/areaCategoriaServicos/${id}/forced`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted categoriaServicos Servico id=${id}`)),
    );
  }
  updateAreaCategoriaServico(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/areaCategoriaServicos/${id}`, Servico).pipe(
      tap(_ => console.log(`updated categoriaServicos Servico id=${id}`)),
    );
  }
  alterarAreaVisibilidadeCategoria(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/areaCategoriaServicos/${id}/visibilidade`, { visivel: estado }).pipe(
      tap(_ => console.log(`alter categoriaServicos visibility id=${id} to estate=${estado}`)),
    );
  }
  // =================================================================================
  // ==========        OPERAÇÕES COM SUB CATEGORIA SERVIÇOS ==========================
  // =================================================================================

  getSubCategoriaServico(id) {
    return this.httpClient.get(`${this.endpoint}/subCategoriaServicos/${id}`).pipe(
      map(this.extractData));
  }
  createSubCategoriaServico(Servico) {
    return this.httpClient.post(`${this.endpoint}/subCategoriaServicos`, Servico).pipe(
      tap(_ => console.log(`created SubCategoriaServicos id=${Servico}`)),
    );
  }
  deleteSubCategoriaServico(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/subCategoriaServicos/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted SubCategoriaServicos Servico id=${id}`)),
    );
  }
  updateSubCategoriaServico(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/subCategoriaServicos/${id}`, Servico).pipe(
      tap(_ => console.log(`updated SubCategoriaServicos Servico id=${id}`)),
    );
  }

  reordenarCategoriaServico(ordem) {
    return this.httpClient.post(`${this.endpoint}/subCategoriaServicos/set_order`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM PESQUISAS       ==============================
  // =================================================================================

  getOpcoes(idpergunta, pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/v2/pesquisas/perguntas/${idpergunta}/opcoes?paginate=1&page=${pagina}`).pipe(
      map(this.extractData));
  }
  getOpcao(idopcao) {
    return this.httpClient.get(`${this.endpoint}/pesquisas/perguntas/opcoes/${idopcao}`).pipe(
      map(this.extractData));
  }
  updateOpcoes(idopcao, dados) {
    return this.httpClient.put(`${this.endpoint}/v2/pesquisas/perguntas/opcoes/${idopcao}`, dados).pipe(
      tap(_ => console.log(`created pesquisa id=${dados}`)),
    );
  }
  createOpcoes(idpergunta, dados) {
    return this.httpClient.post(`${this.endpoint}/v2/pesquisas/perguntas/${idpergunta}/opcoes`, dados).pipe(
      tap(_ => console.log(`created enquete id=${dados}`)),
    );
  }
  deleteOpcoes(idopcao) {
    return this.httpClient.delete(`${this.endpoint}/v2/pesquisas/perguntas/opcoes/${idopcao}`).pipe(
      map(this.extractData));
  }

  getPesquisas(pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/pesquisas?page=${pagina}`).pipe(
      map(this.extractData));
  }
  getPesquisasByTitle(titulo, pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/pesquisas?page=${pagina}&titulo=${titulo}`).pipe(
      map(this.extractData));
  }
  getPerguntasPesquisa(id, pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/v2/pesquisas/${id}/perguntas?paginate=1&page=${pagina}`).pipe(
      map(this.extractData));
  }
  getPerguntaPesquisa(idpergunta, idpesquisa) {
    return this.httpClient.get(`${this.endpoint}/pesquisas/perguntas/${idpergunta}`).pipe(
      map(this.extractData));
  }
  updatePerguntaPesquisa(idpergunta, idpesquisa, conteudo) {
    return this.httpClient.put(`${this.endpoint}/v2/pesquisas/perguntas/${idpergunta}`, conteudo).pipe(
      map(this.extractData));
  }
  deletePerguntaPesquisa(idpergunta) {
    return this.httpClient.delete(`${this.endpoint}/v2/pesquisas/perguntas/${idpergunta}`).pipe(
      map(this.extractData));
  }

  alterarVisibilidadePesquisa(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/pesquisas/${id}/visibilidade`, { visibilidade: estado }).pipe(
      tap(_ => console.log(`alter pesquisa visibility id=${id} to estate=${estado}`)),
    );
  }
  getResultadoPesquisa(id: number) {
    return this.httpClient.get(`${this.endpoint}/pesquisas/${id}/respostas`).pipe(
      map(this.extractData));
  }
  getResultadoPesquisaPaginada(id: number, pagina: Number = 1, inicial, final, selecionado, idPesquisa, nameUser) {
    let selecionadoFilter = "";
    let idFilter = "";
    let nomeUsuario = "";
    if (selecionado != -1) {
      selecionadoFilter += `&selecionado=${selecionado == true ? 1 : (selecionado == false ? 0 : "")}`;
    }
    if (idPesquisa != null && idPesquisa != "") {
      idFilter += `&id=${idPesquisa}`;
    }
    if (nameUser != null && nameUser != "") {
      nomeUsuario += `&name_user=${nameUser}`;
    }
    return this.httpClient.get(`${this.endpoint}/pesquisas/${id}/respostas/paginadas?page=${pagina}&created_at%2Fstart=${inicial}&created_at%2Fend=${final}${selecionadoFilter}${idFilter}${nomeUsuario}`).pipe(
      map(this.extractData));
  }
  deletarResultadoPesquisa(id: number) {
    return this.httpClient.delete(`${this.endpoint}/pesquisas/resposta/${id}`).pipe(
      map(this.extractData));
  }
  deletarPesquisa(id: number) {
    return this.httpClient.delete(`${this.endpoint}/v2/pesquisas/${id}`).pipe(
      map(this.extractData));
  }
  reordenarPesquisa(ordem) {
    return this.httpClient.post(`${this.endpoint}/v2/pesquisas/ordenar`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }
  salvadaAnotacao(id: number, texto, selecionado) {
    return this.httpClient.post(`${this.endpoint}/pesquisas/resposta/${id}`, {
      'resposta_texto': texto ? texto : "",
      'selecionado': selecionado == true ? 1 : (selecionado == false ? 0 : ""),
      '_method': 'put'
    }).pipe(map(this.extractData));
  }
  getDashboardPesquisa(id: number) {
    return this.httpClient.get(`${this.endpoint}/pesquisas/relatorio/${id}`).pipe(
      map(this.extractData));
  }
  getDashboardBairrosPesquisa(id: number) {
    return this.httpClient.get(`${this.endpoint}/pesquisas/${id}/respostas/bairros`).pipe(
      map(this.extractData));
  }
  getPesquisa(id: number) {
    return this.httpClient.get(`${this.endpoint}/pesquisas/${id}`).pipe(
      map(this.extractData));
  }
  updatePesquisa(enquete, id) {
    return this.httpClient.put(`${this.endpoint}/v2/pesquisas/${id}`, enquete).pipe(
      tap(_ => console.log(`created pesquisa id=${enquete}`)),
    );
  }
  createPesquisa(enquete) {
    return this.httpClient.post(`${this.endpoint}/v2/pesquisas`, enquete).pipe(
      tap(_ => console.log(`created enquete id=${enquete}`)),
    );
  }
  createPesquisaPergunta(idpesquisa, conteudo) {
    return this.httpClient.post(`${this.endpoint}/v2/pesquisas/${idpesquisa}/perguntas`, conteudo).pipe(
      tap(_ => console.log(`created enquete id=${conteudo}`)),
    );
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM ENQUETES       ==============================
  // =================================================================================

  getEnquete(id: number) {
    return this.httpClient.get(`${this.endpoint}/enquetes/${id}`).pipe(
      map(this.extractData));
  }
  getResultadoBairros(id: number) {
    return this.httpClient.get(`${this.endpoint}/enquetes/${id}/bairros`).pipe(
      map(this.extractData));
  }
  getEnquetes(pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/enquetes?page=${pagina}`).pipe(
      map(this.extractData));
  }
  createEnquete(enquete) {
    return this.httpClient.post(`${this.endpoint}/enquetes`, enquete).pipe(
      tap(_ => console.log(`created enquete id=${enquete}`)),
    );
  }
  updateEnquete(enquete, id) {
    return this.httpClient.put(`${this.endpoint}/enquetes/${id}`, enquete).pipe(
      tap(_ => console.log(`created enquete id=${enquete}`)),
    );
  }
  deleteEnquete(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/enquetes/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted enquete id=${id}`)),
    );
  }
  alterarVisibilidade(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/enquetes/${id}/visibilidade`, { visivel: estado }).pipe(
      tap(_ => console.log(`alter enquete visibility id=${id} to estate=${estado}`)),
    );
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM USUÁRIOS       ================
  // =================================================================================
  getUsuarios(pagina: Number = 1) {
    return this.httpClient.get(`${this.endpoint}/usuarios?page=${pagina}`).pipe(
      map(this.extractData));
  }
  getUsuariosFiltrado(pagina: Number = 1, data) {
    return this.httpClient.get(`${this.endpoint}/usuarios/filtrar?page=${pagina}`, { params: data }).pipe(
      map(this.extractData));
  }
  getUsuariosSistema() {
    return this.httpClient.get(`${this.endpoint}/usuario/buscarTodosAdmins`).pipe(
      map(this.extractData));
  }
  getUsuario(id) {
    return this.httpClient.get(`${this.endpoint}/usuarios/${id}`).pipe(
      map(this.extractData));
  }
  createUsuario(usuario) {
    return this.httpClient.post(`${this.endpoint}/usuarios`, usuario).pipe(
      tap(_ => console.log(`created usuario id=${usuario}`)),
    );
  }
  updateUsuario(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/usuarios/${id}`, Servico).pipe(
      tap(_ => console.log(`updated catgoria Servico id=${id}`)),
    );
  }
  deleteUsuario(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/usuarios/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted enquete id=${id}`)),
    );
  }

  createUsuarioSistema(usuario) {
    return this.httpClient.post(`${this.endpoint}/usuario/admin/cadastrar`, usuario).pipe(
      tap(_ => console.log(`created usuario sistema id=${usuario}`)),
    );
  }

  getUsuarioSistema(id) {
    return this.httpClient.get(`${this.endpoint}/usuario/admin/${id}`).pipe(
      map(this.extractData));
  }

  getUsuariosPorCategoria(categoriaId) {
    return this.httpClient.get(`${this.endpoint}/agrupamento/usuarios/DisponiveisParaAgrupar/${categoriaId}`).pipe(
      map(this.extractData));
  }
  getUsuariosPorCategoriaAtendimento(categoriaId) {
    return this.httpClient.get(`${this.endpoint}/atendimento/usuarios/disponiveis/${categoriaId}`).pipe(
      map(this.extractData));
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM TEMPLATES       ================
  // =================================================================================
  getTemplates() {
    return this.httpClient.get(`${this.endpoint}/templates`).pipe(
      map(this.extractData));
  }
  getTemplate(id) {
    return this.httpClient.get(`${this.endpoint}/templates/${id}`).pipe(
      map(this.extractData));
  }
  createTemplate(Servico) {
    return this.httpClient.post(`${this.endpoint}/templates`, Servico).pipe(
      tap(_ => console.log(`created enquete id=${Servico}`)),
    );
  }
  updateTemplate(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/templates/${id}`, Servico).pipe(
      tap(_ => console.log(`updated catgoria Servico id=${id}`)),
    );
  }
  deleteTemplate(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/templates/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted enquete id=${id}`)),
    );
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM BAIRROS       ================
  // =================================================================================
  getBairros() {
    return this.httpClient.get(`${this.endpoint}/bairros`).pipe(
      map(this.extractData));
  }
  updateGeocodingBairro(idProtocolo, titulo, lat, lgn) {
    return this.httpClient.post(`${this.endpoint}/bairros/${idProtocolo}`, {
      latitude: lat,
      longitude: lgn,
      '_method': 'put'
    }).pipe();
  }
  getBairro(id) {
    return this.httpClient.get(`${this.endpoint}/bairros/${id}`).pipe(
      map(this.extractData));
  }
  createBairro(Servico) {
    return this.httpClient.post(`${this.endpoint}/bairros`, Servico).pipe(
      tap(_ => console.log(`created enquete id=${Servico}`)),
    );
  }
  updateBairro(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/bairros/${id}`, Servico).pipe(
      tap(_ => console.log(`updated catgoria Servico id=${id}`)),
    );
  }
  deleteBairro(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/bairros/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted enquete id=${id}`)),
    );
  }
  // =================================================================================
  // ==========           OPERAÇÕES COM MACRO REGIOES       ================
  // =================================================================================
  getMacroRegioes() {
    return this.httpClient.get(`${this.endpoint}/macroRegioes`).pipe(
      map(this.extractData));
  }
  getMacroRegiao(id) {
    return this.httpClient.get(`${this.endpoint}/macroRegioes/${id}`).pipe(
      map(this.extractData));
  }
  createMacroRegioes(Servico) {
    return this.httpClient.post(`${this.endpoint}/macroRegioes`, Servico).pipe(
      tap(_ => console.log(`created macroregiao id=${Servico}`)),
    );
  }
  updateMacroRegioes(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/macroRegioes/${id}`, Servico).pipe(
      tap(_ => console.log(`updated macroregiao Servico id=${id}`)),
    );
  }
  deleteMacroRegioes(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/macroRegioes/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted macroregiao id=${id}`)),
    );
  }
  relatorioUsuariosRegiao(params?) {
    return this.httpClient
      .get(`${this.endpoint}/usuarios/relatorio_por_regiao`, { params })
      .toPromise();
  }

  // =================================================================================
  // ===============      OPERAÇÕES COM GRUPO DE INSCRICAO       =====================
  // =================================================================================
  reordenarGrupoInscricao(ordem) {
    return this.httpClient.post(`${this.endpoint}/v2/courses_group/set_order`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }
  getGrupoInscricao(title, enabled) {
    return this.httpClient.get(`${this.endpoint}/v2/courses_group?conditions=${title}${enabled}`).pipe(
      map(this.extractData));
  }
  getGrupoInscricaoPorId(id) {
    return this.httpClient.get(`${this.endpoint}/v2/courses_group/${id}`).pipe(
      map(this.extractData));
  }
  createGrupoInscricao(grupo) {
    return this.httpClient.post(`${this.endpoint}/v2/courses_group`, grupo).pipe(
      tap(_ => console.log(`created grupo inscrição id=${grupo}`)),
    );
  }
  updateGrupoInscricao(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/courses_group/${id}`, Servico).pipe(
      tap(_ => console.log(`updated grupo inscrição id=${id}`)),
    );
  }
  deleteGrupoInscricao(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/v2/courses_group/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted grupo inscrição id=${id}`)),
    );
  }

  alterarVisibilidadeGrupoInscricao(id: Number, enabled: Boolean) {
    return this.httpClient.post(`${this.endpoint}/v2/courses_group/${id}/visibilidade`, { enabled: enabled }).pipe(
      tap(_ => console.log(`alter grupo inscrição id=${id} to enabled=${enabled}`)),
    );
  }

  moverInscricao(idInscricao: number, idCurso: number) {
    return this.httpClient.post(`${this.endpoint}/v2/course_enrollment/${idInscricao}`, { course_id: idCurso, _method: 'PUT' }).pipe(
    );
  }

  // =================================================================================
  // ====================      OPERAÇÕES COM CURSOS       ============================
  // =================================================================================
  reordenarCursos(ordem) {
    return this.httpClient.post(`${this.endpoint}/v2/course/set_order`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }
  getCursos(id, title, enabled) {
    return this.httpClient.get(`${this.endpoint}/v2/course?conditions=course_group_id:=:${id};${title}${enabled}`).pipe(
      map(this.extractData));
  }
  getCursoPorId(id) {
    return this.httpClient.get(`${this.endpoint}/v2/course/${id}`).pipe(
      map(this.extractData));
  }
  createCurso(curso) {
    return this.httpClient.post(`${this.endpoint}/v2/course`, curso).pipe(
      tap(_ => console.log(`created curso id=${curso}`)),
    );
  }
  updateCurso(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/course/${id}`, Servico).pipe(
      tap(_ => console.log(`updated curso id=${id}`)),
    );
  }
  deleteCurso(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/v2/course/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted curso id=${id}`)),
    );
  }

  alterarVisibilidadeCurso(id: Number, enabled: Boolean) {
    return this.httpClient.post(`${this.endpoint}/v2/course/${id}/visibilidade`, { enabled: enabled }).pipe(
      tap(_ => console.log(`alter curso id=${id} to enabled=${enabled}`)),
    );
  }

  // =================================================================================
  // ====================      OPERAÇÕES COM CAMPOS EXTRAS       =====================
  // =================================================================================
  reordenarCursosCamposExtras(ordem) {
    return this.httpClient.post(`${this.endpoint}/v2/course/extra_field/set_order`, ordem).pipe(
      tap(_ => console.log(`reordenando obj=${ordem}`)),
    );
  }
  getCamposExtras(campoExtra) {
    return this.httpClient.get(`${this.endpoint}/v2/course/extra_field_by_course/${campoExtra}`).pipe(
      map(this.extractData));
  }
  getCampoExtraPorId(id) {
    return this.httpClient.get(`${this.endpoint}/v2/course/extra_field/${id}`).pipe(
      map(this.extractData));
  }
  createCampoExtra(campoExtra) {
    return this.httpClient.post(`${this.endpoint}/v2/course/extra_field`, campoExtra).pipe(
      tap(_ => console.log(`created campo extra id=${campoExtra}`)),
    );
  }
  updateCampoExtra(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/course/extra_field/${id}`, Servico).pipe(
      tap(_ => console.log(`updated campo extra id=${id}`)),
    );
  }
  deleteCampoExtra(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/v2/course/extra_field/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted campo extra id=${id}`)),
    );
  }
  // =================================================================================
  // ====================      OPERAÇÕES COM CAMPOS EXTRAS       =====================
  // =================================================================================
  getInscricao(cousedID, page?: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/course_enrollment?page=${page}&conditions=course_id:=:${cousedID}`)
      .pipe(map(this.extractData));
  }
  updateInscricaoFinal(cousedID, formData) {
    return this.httpClient.post(`${this.endpoint}/v2/course_enrollment/${cousedID}`, formData).pipe(
      map(this.extractData));
  }
  getInscricaoPorId(id) {
    return this.httpClient.get(`${this.endpoint}/v2/course_enrollment/${id}`).pipe(
      map(this.extractData));
  }
  deleteInscricaoPorId(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/v2/course_enrollment/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted inscricao id=${id}`)),
    );
  }

  updateInscricao(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/course_enrollment/${id}`, Servico).pipe(
      tap(_ => console.log(`updated campo extra id=${id}`)),
    );
  }
  getCourseEnrollment(cousedEnrollmentId) {
    return this.httpClient.get(`${this.endpoint}/v2/course_enrollment/fields/${cousedEnrollmentId}`).pipe(
      map(this.extractData));
  }
  updateCourseEnrollment(id, valor): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/course_enrollment/fields/${id}`, valor).pipe(
      tap(_ => console.log(`updated course enrollment id=${id}`)),
    );
  }
  updateCourseEnrollmentFiles(valor): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/course_enrollment/files`, valor).pipe(
      tap(_ => console.log(`updated course enrollment file id=${valor}`)),
    );
  }

  getFiltered(cousedID: number, page?: number, filters?: { name: string, cpf: string; }) {
    const query = Object
      .entries(filters)
      .filter(filter => filter[1])
      .map((queries: [string, string]) => `${queries[0]}=${queries[1]}`);

    const pagination = page ? `page=${page}&` : '';

    return this.httpClient
      .get(`${this.endpoint}/v2/${cousedID}/course_enrollments?${pagination}${query}`);
  }

  // =================================================================================
  // ==========           PARAMETROS DE CONFIGURACAO        ==========================
  // =================================================================================
  getConfigs() {
    return this.httpClient.get(`${this.endpoint}/configs`).pipe(
      map(this.extractData));
  }

  getConfigPorNome(chave) {
    return this.httpClient.get(`${this.endpoint}/configs/${chave}`).pipe(
      map(this.extractData));
  }

  createConfig(config) {
    return this.httpClient.post(`${this.endpoint}/configs`, config).pipe(
      tap(_ => console.log(`created config nome=${config}`)),
    );
  }

  updateConfig(chave, valor): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/configs/${chave}`, valor).pipe(
      tap(_ => console.log(`updated config chave=${chave}`)),
    );
  }
  deleteConfig(chave): Observable<any> {
    return this.httpClient.delete(`${this.endpoint}/configs/${chave}`, {}).pipe(
      tap(_ => console.log(`deleted config chave=${chave}`)),
    );
  }


  // =================================================================================
  // ============           PARAMETROS DE PERMISSÔES        ==========================
  // =================================================================================
  getPermissoesPerm() {
    return this.httpClient.get(`${this.endpoint}/administrativo/permissoes`).pipe(
      map(this.extractData));
  }

  getConfigPorId(chave) {
    return this.httpClient.get(`${this.endpoint}/administrativo/permissoes/${chave}`).pipe(
      map(this.extractData));
  }

  createPermissoes(config) {
    return this.httpClient.post(`${this.endpoint}/administrativo/permissoes`, config).pipe(
      tap(_ => console.log(`created config nome=${config}`)),
    );
  }

  updatePermissoes(chave, valor): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/administrativo/permissoes/${chave}`, valor).pipe(
      tap(_ => console.log(`updated config chave=${chave}`)),
    );
  }


  deletePermissoes(chave): Observable<any> {
    return this.httpClient.delete(`${this.endpoint}/administrativo/permissoes/${chave}`, {}).pipe(
      tap(_ => console.log(`deleted config chave=${chave}`)),
    );
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM A PT. ADMINISTRATIVA        ==================
  // =================================================================================
  getRoles() {
    return this.httpClient.get(`${this.endpoint}/administrativo/funcoes`).pipe(
      map(this.extractData));
  }
  getRole(id) {
    return this.httpClient.get(`${this.endpoint}/administrativo/funcoes/${id}`).pipe(
      map(this.extractData));
  }
  createRole(Servico) {
    return this.httpClient.post(`${this.endpoint}/administrativo/funcoes`, Servico).pipe(
      tap(_ => console.log(`created enquete id=${Servico}`)),
    );
  }
  updateRole(id, Servico): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/administrativo/funcoes/${id}`, Servico).pipe(
      tap(_ => console.log(`updated catgoria Servico id=${id}`)),
    );
  }
  deleteRole(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/administrativo/funcoes/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted enquete id=${id}`)),
    );
  }

  // =================================================================================
  // ===========================         FRONTENDS        ============================
  // =================================================================================
  getFrontEnds() {
    return this.httpClient.get(`${this.endpoint}/v2/frontend`).pipe(
      map(this.extractData));
  }

  getFrontEnd(id) {
    return this.httpClient.get(`${this.endpoint}/v2/frontend/${id}`).pipe(
      map(this.extractData));
  }

  createFrontEnd(frontend) {
    console.log('Create', frontend);
    return this.httpClient.post(`${this.endpoint}/v2/frontend`, frontend).pipe(
      tap(_ => console.log(`created frontend id=${frontend}`)),
    );
  }
  updateFrontEnd(id, frontend): Observable<any> {
    return this.httpClient.post(`${this.endpoint}/v2/frontend/${id}`, frontend).pipe(
      tap(_ => console.log(`updated frontend id=${id}`)),
    );
  }
  deleteFrontEnd(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.endpoint}/v2/frontend/${id}`, this.httpOptions).pipe(
      tap(_ => console.log(`deleted frontend id=${id}`)),
    );
  }


  // =================================================================================
  // ==========           OPERAÇÕES DASHBOASRD          ==============================
  // =================================================================================

  getDados(tipo: Charts) {
    return (this.httpClient
      .get(`${this.endpoint}/estatisticas/tipo/${tipo}`) as Observable<ChartsResponseData>)
      .toPromise();
  }

  getDadosCategorias(inicial, final) {
    return this.httpClient.get(`${this.endpoint}/estatisticas/protocolosPorCategoria?&inicio=${this.datetoYMD(inicial)}&fim=${this.datetoYMD(final)}`);
  }

  getStatusPorCategorias(inicial, final) {
    return this.httpClient.get(`${this.endpoint}/estatisticas/protocolosPorCategoriaEStatus?&inicio=${this.datetoYMD(inicial)}&fim=${this.datetoYMD(final)}`);
  }

  getProtocolosEmAtrasoDashBoard() {
    return this.httpClient.get(`${this.endpoint}/v2/protocolosEmAtrasoDashBoard`);
  }

  getCursosDashboard(inicial, final) {
    return this.httpClient.get(`${this.endpoint}/estatisticas/cursos?&inicio=${this.datetoYMD(inicial)}&fim=${this.datetoYMD(final)}`);
  }

  atribuiRoleAUsuario(idUsuario, idFuncao) {
    return this.httpClient.get(`${this.endpoint}/administrativo/usuario/${idUsuario}/funcao/${idFuncao}/atribuir`).pipe(
      tap(_ => console.log(`atribuiu role id=${idFuncao} ao usuario ${idUsuario}`)),
    );
  }

  desvinculaRoleNoUsuario(idUsuario, idFuncao) {
    return this.httpClient.get(`${this.endpoint}/administrativo/usuario/${idUsuario}/funcao/${idFuncao}/desvincular`).pipe(
      tap(_ => console.log(`desvinculou role id=${idFuncao} ao usuario ${idUsuario}`)),
    );
  }

  atribuiInstituicaoAUsuario(idUsuario, idFuncao) {
    return this.httpClient.get(`${this.endpoint}/administrativo/usuario/${idUsuario}/instituicao/${idFuncao}/atribuir`).pipe(
      tap(_ => console.log(`atribuiu instituicao id=${idFuncao} ao usuario ${idUsuario}`)),
    );
  }

  desvinculaInstituicaoNoUsuario(idUsuario, idFuncao) {
    return this.httpClient.get(`${this.endpoint}/administrativo/usuario/${idUsuario}/instituicao/${idFuncao}/desvincular`).pipe(
      tap(_ => console.log(`desvinculou instituicao id=${idFuncao} ao usuario ${idUsuario}`)),
    );
  }

  getPermissoes() {
    return this.httpClient.get(`${this.endpoint}/administrativo/permissoes`).pipe(
      map(this.extractData));
  }

  atribuiPermissaoNaFuncao(idFuncao, idPermissao) {
    return this.httpClient.get(`${this.endpoint}/administrativo/funcao/${idFuncao}/permissao/${idPermissao}/atribuir`).pipe(
      tap(_ => console.log(`atribuiu permissao id=${idPermissao} a funcao ${idFuncao}`)),
    );
  }

  desvinculaPermissaoNaFuncao(idFuncao, idPermissao) {
    return this.httpClient.get(`${this.endpoint}/administrativo/funcao/${idFuncao}/permissao/${idPermissao}/desvincular`).pipe(
      tap(_ => console.log(`desvinculou permissao id=${idPermissao} a funcao ${idFuncao}`)),
    );
  }


  // =================================================================================
  // ==========           OPERAÇÕES COM ATENDIMENTO        ==============================
  // =================================================================================

  async getProtocoloAtendentePorCategoria() {

    var users = await this.httpClient.get(`${this.endpoint}/v2/atendentesCategoria`).pipe(map(this.extractData)).toPromise().then((data: any) => {
      return data;
    });

    var categories = await this.getCategoriaServicos().toPromise().then((data: Array<any>) => {
      return data;
    });

    categories.forEach((category: any) => {

      category["users"] = [];
      category["lastUser"] = null;
      users.forEach((user: any) => {
        if (user.categorias_atendimentos.find(i => i.id === category.id) && user.email != "gestor@admin.com") {

          if (category.users.length) {
            category.users.push(user);
          }
          else {
            category["users"] = [user];
          }

          // Adiciona o último usuário logado à categoria
          if (category.lastUser && category.lastUser.log_max && category.lastUser.log_max.length && user && user.log_max && user.log_max.length) {
            if (this.formatDate(category.lastUser.log_max[0].created_at) < this.formatDate(user.log_max[0].created_at)) {
              category["lastUser"] = user;
            }
          }
          else {
            category["lastUser"] = user;
          }
        }
      });
      // console.log(category)
      category.users.sort((a, b) => {
        if (a.log_max && a.log_max.length && b.log_max && b.log_max.length)
          return this.formatDate(b.log_max[0].created_at).getTime() - this.formatDate(a.log_max[0].created_at).getTime();
        else
          return -1;
      });
    });

    return categories;
  }
  formatDate(date) {
    console.log(date);
    if (!date) return null;
    let stringSplited = date.split(" - ");
    if (stringSplited) {
      let hourSplited = stringSplited[1].split(":");
      let dateSplited = stringSplited[0].split("/");
      console.log(date, stringSplited);
      if (hourSplited && dateSplited) return new Date(dateSplited[2], parseInt(dateSplited[1]) - 1, dateSplited[0], hourSplited[0], hourSplited[1]);
      else return null;
    }
    return null;
  }
  iniciarAtendimento(protocolo) {
    return this.httpClient.post(`${this.endpoint}/atendimento/iniciarAtendimento`, protocolo).pipe(
      tap(_ => console.log(`iniciou atendimento protocolo =${protocolo}`)),
    );
  }
  encerrarAtendimento(protocolo) {
    return this.httpClient.post(`${this.endpoint}/atendimento/encerrarAtendimento`, protocolo).pipe(
      tap(_ => console.log(`encerrou atendimento protocolo =${protocolo}`)),
    );
  }
  enviaProtocoloResposta(resposta) {
    return this.httpClient.post(`${this.endpoint}/protocoloRespostas`, resposta).pipe(
      tap(_ => console.log(`enviou a resposta =${resposta}`)),
    );
  }
  enviaRespostaParaProtocolo(protocoloId, resposta) {
    return this.httpClient.post(`${this.endpoint}/protocolos/${protocoloId}/mensagens`, resposta).pipe(
      tap(_ => console.log(`enviou a resposta =${resposta}`)),
    );
  }

  getProtocoloRespostas(protocoloId) {
    return this.httpClient.get(`${this.endpoint}/protocolos/${protocoloId}/mensagens`).pipe(
      map(this.extractData));
  }

  getMinhasCategoriasAtendimento() {
    return this.httpClient.get(`${this.endpoint}/atendimento/minhasCategorias`).pipe(
      map(this.extractData));
  }

  getMinhasAreas() {
    return this.httpClient.get(`${this.endpoint}/v2/atendimento/minhasAreas`).pipe(
      map(this.extractData));
  }

  getMeusProtocolosEmAtendimento() {
    return this.httpClient.get(`${this.endpoint}/atendimento/meusAtendimentosAbertos`).pipe(
      map(this.extractData));
  }

  getMeusProtocolosEmAberto() {
    return this.httpClient.get(`${this.endpoint}/atendimento/meusProtocolos`).pipe(
      map(this.extractData));
  }

  getUsuarioCategoriasServico() {
    return this.httpClient.get(`${this.endpoint}/atendimento/categoriasServico`).pipe(
      map(this.extractData));
  }

  createUsuarioCategoriaServico(usuarioCategoriaServico) {
    return this.httpClient.post(`${this.endpoint}/atendimento/categoriasServico`, usuarioCategoriaServico).pipe(
      tap(_ => console.log(`created usuarioCategoriaServico id=${usuarioCategoriaServico}`)),
    );
  }
  deleteUsuarioCategoriaServico(usuarioCategoriaServico) {
    return this.httpClient.post(`${this.endpoint}/atendimento/categoriasServico/conjunto`, usuarioCategoriaServico).pipe(
      tap(_ => console.log(`created usuarioCategoriaServico id=${usuarioCategoriaServico}`)),
    );
  }

  updateConfirmarMensagens(idProtocolo) {
    return this.httpClient.get(`${this.endpoint}/protocolo/${idProtocolo}/confirmarMensagens`).pipe();
  }

  updateStatusAtendimento(idProtocolo, status) {
    return this.httpClient.put(`${this.endpoint}/protocolos/${idProtocolo}/statusAtendimento`, { statusatendimento: status }).pipe();
  }

  // =================================================================================
  // ==========           OPERAÇÕES COM SELFIES        ==============================
  // =================================================================================

  // Recupera a lista de modulos de selfies cadastrado
  criarModuloSelfies(data) {
    return this.httpClient.post(`${this.endpoint}/modulos/fotos`, data).pipe();
  }

  // Recupera o modulo de selfies por id passado
  editaModuloSelfies(data, id) {
    return this.httpClient.post(`${this.endpoint}/modulos/fotos/${id}`, data).pipe();
  }

  // Deleta o modulos de selfies por id passado
  deletarModuloSelfies(id) {
    return this.httpClient.delete(`${this.endpoint}/modulos/fotos/${id}`).pipe();
  }

  // Recupera a lista de modulos de selfies cadastrado
  getListModulosSelfies(page: Number) {
    return this.httpClient.get(`${this.endpoint}/modulos/fotos?page=${page}&portal=1`).pipe();
  }

  // Recupera a lista de modulos de selfies cadastrado
  getModuloSelfies(id) {
    return this.httpClient.get(`${this.endpoint}/modulos/fotos/${id}?portal=1`).pipe();
  }

  // Recupera todas selfies para moderação de um determinado modulo
  getListSelfiesGeral(id, page) {
    return this.httpClient.get(`${this.endpoint}/modulos/fotos/${id}/fotos?page=${page}&portal=1`).pipe();
  }

  // Recupera todas selfies que precisam de aprovação de um determinado modulo
  getListSelfiesAprovar(id) {
    return this.httpClient.get(`${this.endpoint}/modulos/fotos/${id}/paraAprovar`).pipe();
  }

  // Recupera todas selfies que precisam de aprovação de um determinado modulo
  getListMoldurasPorModulo(id) {
    return this.httpClient.get(`${this.endpoint}/modulos/fotos/${id}/molduras`).pipe();
  }
  // Cria moldura para um modulo
  createMoldura(campos) {
    return this.httpClient.post(`${this.endpoint}/modulos/fotos/molduras`, campos).pipe(
      tap(_ => console.log(`created moldura conteudo=${campos}`)),
    );
  }
  // Confirma aprovação da selfie para ser visualizada.
  aprovarSelfie(id) {
    return this.httpClient.post(`${this.endpoint}/modulos/fotos/fotos/${id}`, { _method: 'PUT', visivel: true }).pipe();
  }

  // Confirma aprovação da selfie para ser visualizada.
  deletarSelfie(id) {
    return this.httpClient.delete(`${this.endpoint}/modulos/fotos/fotos/${id}`).pipe();
  }
  // Confirma aprovação da selfie para ser visualizada.
  deletarMoldura(id) {
    return this.httpClient.delete(`${this.endpoint}/modulos/fotos/molduras/${id}`).pipe();
  }

  // =================================================================================
  // ==================== OPERAÇÕES COM MODULO FEIRINHA ==============================
  // =================================================================================

  // Recupera a lista de modulos cadastrado
  criarModuloFeirinha(data) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/modulo`, data).pipe();
  }

  // Recupera o modulo por id passado
  editaModuloFeirinha(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/modulo/${id}`, data).pipe();
  }

  // Deleta o modulos por id passado
  deletarModuloFeirinha(id) {
    return this.httpClient.delete(`${this.endpoint}/v2/feirinha/modulo/${id}`).pipe();
  }

  // Recupera a lista de modulos cadastrados
  getListModulosFeirinha(page: Number) {
    return this.httpClient.get(`${this.endpoint}/v2/feirinha/modulo?paginate=1&page=${page}`).pipe();
  }

  // Recupera a modulo específico
  getModuloFeirinha(id) {
    return this.httpClient.get(`${this.endpoint}/v2/feirinha/modulo/${id}`).pipe();
  }

  // Altera visibilidade
  alterarAreaVisibilidadeModuloFeirinha(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/modulo/${id}`, { status: estado }).pipe(
      tap(_ => console.log(`alter moduloFeirinha visibility id=${id} to estate=${estado}`)),
    );
  }

  // Recupera a lista de produtos de modulo específico
  getModuloFeirinhaProdutos(id, page: Number, situacao, titulo, dataInicio, dataFim) {
    return this.httpClient.get(`${this.endpoint}/v2/feirinha/modulo_produto/${id}?conditions=${situacao}${titulo}&data_inicio=${dataInicio}&data_fim=${dataFim}&paginate=1&page=${page}`).pipe();
  }

  // Recupera a lista com filtros de produtos de modulo específico
  getFiltroModuloFeirinhaProdutos(id, page: Number, situacao, titulo, dataInicio, dataFim) {
    return this.httpClient.get(`${this.endpoint}/v2/feirinha/modulo_produto/${id}?conditions=${situacao}${titulo}&data_inicio=${dataInicio}&data_fim=${dataFim}&&paginate=1&page=${page}`).pipe();
  }

  // Altera a situação do produto
  setSituacaoProduto(data, id: Number) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/produto/${id}`, data).pipe();
  }

  // Deleta produto
  deletarProdutoModulo(id) {
    return this.httpClient.delete(`${this.endpoint}/v2/feirinha/produto/${id}`).pipe();
  }



  // Recupera a lista de categorias de modulo específico
  getModuloFeirinhaCategorias(id, page: Number) {
    return this.httpClient.get(`${this.endpoint}/v2/feirinha/categoria?conditions=modulo_id:=:${id}&paginate=1&page=${page}`).pipe();
  }

  // Recupera a modulo específico
  getModuloFeirinhaCategoria(id) {
    return this.httpClient.get(`${this.endpoint}/v2/feirinha/categoria/${id}`).pipe();
  }

  // Recupera a lista de modulos cadastrado
  criarModuloFeirinhaCategoria(data) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/categoria`, data).pipe();
  }

  // Recupera o modulo por id passado
  editaModuloFeirinhaCategoria(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/categoria/${id}`, data).pipe();
  }

  // Altera a situação do categoria
  setSituacaoCategoria(data, id: Number) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/categoria/${id}`, data).pipe();
  }

  // Deleta categoria
  deletarModuloFeirinhaCategoria(id) {
    return this.httpClient.delete(`${this.endpoint}/v2/feirinha/categoria/${id}`).pipe();
  }

  // Altera visibilidade categoria
  alterarAreaVisibilidadeModuloFeirinhaCategoria(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/v2/feirinha/categoria/${id}`, { status: estado }).pipe(
      tap(_ => console.log(`alter CategoriaFeirinha visibility id=${id} to estate=${estado}`)),
    );
  }

  // =================================================================================
  // ============================= VITRINE ===========================================
  // =================================================================================

  createVitrine(data) {
    return this.httpClient
      .post(`${this.endpoint}/v2/cadsol/vitrine`, data)
      .pipe(map((res: Record<string, any>) => res.data ? res.data : res))
      .toPromise();
  }

  getVitrines(page = 1) {
    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine?paginate=1&page=${page}`)
      .pipe(map((res: Record<string, any>) => res.data))
      .toPromise();
  }

  getVitrine(vitrineId: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}`)
      .pipe(map((res: Record<string, any>) => res.data ? res.data : res))
      .toPromise();
  }

  updateVitrine(vitrineId: number, payload: any) {
    return this.httpClient
      .post(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}`, payload)
      .pipe(map((res: Record<string, any>) => res.data ? res.data : res))
      .toPromise();
  }

  deleteVitrine(vitrineId: number,) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}`)
      .pipe(map((res: Record<string, any>) => res.data ? res.data : res))
      .toPromise();
  }

  createCategoriaVitrine(vitrineId: number, payload: any) {
    return this.httpClient
      .post(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/categoria`, payload)
      .pipe(map((res: Record<string, any>) => res.data))
      .toPromise();
  }

  getCategoriasVitrine(vitrineId: number, page: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/categoria?paginate=1&page=${page}`)
      .pipe(map((res: Record<string, any>) => res.data))
      .toPromise();
  }

  getCategoriasVitrineUnpagined(vitrineId: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/categoria`)
      .pipe(map((res: Record<string, any>[]) => res))
      .toPromise();
  }

  getCategoriaVitrine(vitrineId: number, id: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/categoria/${id}`)
      .pipe(map((res: Record<string, any>) => res))
      .toPromise();
  }

  updateCategoriaVitrine(vitrineId: number, categoriaId: number, payload: any) {
    return this.httpClient
      .post(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/categoria/${categoriaId}`, payload)
      .pipe(map((res: Record<string, any>) => res.data))
      .toPromise();
  }

  deleteCategoriaVitrine(vitrineId: number, categoriaId: number) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/categoria/${categoriaId}`)
      .pipe(map((res: Record<string, any>) => res.data ? res.data : res))
      .toPromise();
  }

  createProdutoVitrine(vitrineId: number, payload: FormData) {
    return this.httpClient
      .post(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/produto`, payload)
      .pipe(map((res: Record<string, any>) => res.data))
      .toPromise();
  }

  getProdutosVitrine(vitrineId: number, page: number, conditions?: string) {
    const conditionsStr = conditions ? `&conditions=${conditions}` : "";

    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/produto?paginate=1&page=${page}${conditionsStr}`)
      .toPromise();
  }

  getProdutoVitrine(vitrineId: number, produtoId: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/produto/${produtoId}`)
      .pipe(map((res: Record<string, any>) => res))
      .toPromise();
  }

  updateProdutoVitrine(vitrineId: number, produtoId: number, payload: FormData) {
    return this.httpClient
      .post(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/produto/${produtoId}`, payload)
      .pipe(map((res: Record<string, any>) => res))
      .toPromise();
  }

  deleteProdutoVitrine(vitrineId: number, produtoId: number) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/produto/${produtoId}`)
      .pipe(map((res: Record<string, any>) => res.data))
      .toPromise();
  }

  getEmpreendimentosVitrine(vitrineId, filters: [string, string][] = []) {
    const filterStr = filters
      .map(filter => `${filter[0]}=${filter[1]}`)
      .join('&');

    return this.httpClient
      .get(`${this.endpoint}/v2/cadsol/vitrine/${vitrineId}/empreendimento?${filterStr}`)
      .pipe(map((res: Record<string, any>[]) => res))
      .toPromise();
  }
  // =================================================================================
  // ========================== TIPO CARTEIRA ========================================
  // =================================================================================

  // Recupera Tipo carteira
  getTiposCarteira(page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/carteira/tipo?paginate=1&page=${page}${filter}`).pipe();
  }

  // Cria Tipo carteira
  criarTipoCarteira(data) {
    return this.httpClient.post(`${this.endpoint}/v2/carteira/tipo`, data).toPromise();;
  }

  // Recupera Tipo carteira específico
  getTipoCarteira(id) {
    return this.httpClient.get(`${this.endpoint}/v2/carteira/tipo/${id}`).pipe();
  }

  // Recupera o modulo por id passado
  editarTipoCarteira(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/carteira/tipo/${id}`, data).toPromise();
  }

  // Deleta Tipo carteira
  deletarTipoCarteira(id) {
    return this.httpClient.delete(`${this.endpoint}/v2/carteira/tipo/${id}`).toPromise();
  }

  // =================================================================================
  // ========================== CAMPOS CARTEIRA ======================================
  // =================================================================================

  // Recupera campos carteira
  getCamposCarteira(idCarteira, page: Number) {
    return this.httpClient.get(`${this.endpoint}/v2/carteira/${idCarteira}/tipo/campo_carteira?paginate=1&page=${page}`).pipe();
  }

  // Cria campo carteira
  criarCampoCarteira(data, idCarteira) {
    return this.httpClient.post(`${this.endpoint}/v2/carteira/${idCarteira}/tipo/campo_carteira`, data).toPromise();
  }

  // Recupera campo carteira específico
  getCampoCarteira(idCarteira, id) {
    return this.httpClient.get(`${this.endpoint}/v2/carteira/${idCarteira}/tipo/campo_carteira/${id}`).pipe();
  }

  // Recupera o modulo por id passado
  editarCampoCarteira(data, idCarteira, id) {
    return this.httpClient.post(`${this.endpoint}/v2/carteira/${idCarteira}/tipo/campo_carteira/${id}`, data).toPromise();
  }

  // Deleta campo carteira
  deletarCampoCarteira(idCarteira, id) {
    return this.httpClient.delete(`${this.endpoint}/v2/carteira/${idCarteira}/tipo/campo_carteira/${id}`).toPromise();
  }

  // =================================================================================
  // ============================ GRUPO CARTEIRA =====================================
  // =================================================================================

  getGrupos() {
    return this.httpClient.get(`${this.endpoint}/v2/grupo`).pipe();
  }

  // Recupera Grupo carteira
  getGruposCarteira(page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/grupo?paginate=1&page=${page}${filter}`).pipe();
  }

  // Cria Grupo carteira
  criarGrupoCarteira(data) {
    return this.httpClient.post(`${this.endpoint}/v2/grupo`, data).toPromise();
  }


  // Recupera Grupo carteira específico
  getGrupoCarteira(id) {
    return this.httpClient.get(`${this.endpoint}/v2/grupo/${id}`).pipe();
  }

  // Recupera o modulo por id passado
  editarGrupoCarteira(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/grupo/${id}`, data).toPromise();
  }

  // Deleta Grupo carteira
  deletarGrupoCarteira(id) {
    return this.httpClient.delete(`${this.endpoint}/v2/grupo/${id}`).toPromise();
  }

  // Atribui titulares ao Grupo carteira
  atribuirTitular(data, idGrupo) {
    return this.httpClient.post(`${this.endpoint}/v2/grupo/${idGrupo}/atribuir`, data).pipe();
  }

  // Desatribuir titulares ao Grupo carteira
  desatribuirTitular(data, idGrupo) {
    return this.httpClient.post(`${this.endpoint}/v2/grupo/${idGrupo}/desatribuir`, data).pipe();
  }

  // Recupera Grupo carteira específico
  getGrupoCarteiraTitulares(id, page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/grupo/${id}/titulares?paginate=1&page=${page}${filter}`).pipe();
  }

  // Recupera Grupo carteira específico
  getGrupoCarteiraTitularesNaoAtribuidos(id, page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/grupo/${id}/titulares_nao_atribuidos?paginate=1&page=${page}${filter}`).pipe();
  }


  // =================================================================================
  // ================================== TITULAR ======================================
  // =================================================================================

  // Recupera titulares
  getTitulares(page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/titular?paginate=1&page=${page}${filter}`).pipe();
  }

  // Cria titular
  criarTitular(data) {
    return this.httpClient.post(`${this.endpoint}/v2/titular`, data).pipe();
  }

  // Recupera titular específico
  getTitular(id) {
    return this.httpClient.get(`${this.endpoint}/v2/titular/${id}`).pipe();
  }

  // Edita o titular
  editarTitular(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/titular/${id}`, data).pipe();
  }

  // Deleta titular
  deletarTitular(id) {
    return this.httpClient.delete(`${this.endpoint}/v2/titular/${id}`).pipe();
  }

  // Bloquar/desbloquear titular
  bloqueioTitular(id) {
    return this.httpClient.post(`${this.endpoint}/v2/titular/bloqueio/${id}`, id).toPromise();
  }

  // =================================================================================
  // ============================= CARTEIRA TITULAR ==================================
  // =================================================================================

  // Atribui titulares a carteira
  atribuirTitularCarteira(data) {
    return this.httpClient.post(`${this.endpoint}/v2/nova-carteira/atribuir`, data).pipe();
  }

  // Atribui titulares a carteira
  atribuirTitularCarteiraSemCampos(data) {
    return this.httpClient.post(`${this.endpoint}/v2/nova-carteira/atribuir_titulares`, data).pipe();
  }

  // Editacampos dos titulares
  editarCamposTitularCarteira(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/titular/carteira/${id}/editar_campos`, data).pipe();
  }

  // status titular carteira
  statusTitularCarteira(data, id) {
    return this.httpClient.post(`${this.endpoint}/v2/titular/carteira/${id}/ativar_desativar`, data).toPromise();
  }

  // Recupera titulares carteira
  getCarteiraTitularesAtribuidos(id, page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/titular/carteira/${id}?paginate=1&page=${page}${filter}`).pipe();
  }

  // Recupera titulares carteira não atribuidos
  getCarteiraTitularesNaoAtribuidos(id, page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/titular/carteira/${id}/nao_atribuidos?paginate=1&page=${page}${filter}`).pipe();
  }

  // Recupera titulares campos
  getCarteiraTitularesCampos(id, page: Number, filter: any) {
    return this.httpClient.get(`${this.endpoint}/v2/titular/carteira/${id}/campos_carteiras?paginate=1&page=${page}${filter}`).pipe();
  }

  // Remover vínculo de titular com carteira
  desatribuirTitularCarteira(id_carteira: number, id_titular: number) {
    return this.httpClient.post(`${this.endpoint}/v2/carteiras/${id_carteira}/desatribuir/${id_titular}`, {}).toPromise();
  }

  // =================================================================================
  // ======================== TITULARES +++++++++++++=================================
  // =================================================================================

  titularesIndex(page = 1) {
    return this.httpClient
      .get(`${this.endpoint}/v2/titular?paginate=1&page=${page}`)
      .toPromise();
  }

  titularCreate(data: any) {
    return this.httpClient
      .post(`${this.endpoint}/v2/titular`, data)
      .toPromise();
  }
  titularEdit(data: any, id: number) {
    return this.httpClient.post(`${this.endpoint}/v2/titular/${id}`, data)
      .pipe();
  }

  titularDelete(id) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/titular/${id}`)
      .toPromise();
  }


  // =================================================================================
  // ==========      OPERAÇÕES DE RECUPERAR SENHA       ==============================
  // =================================================================================

  enviaEmail(email) {
    return this.httpClient.get(`${this.endpoint}/recuperar_senha/disparar_email/${email}`).pipe();
  }

  validarToken(data) {
    return this.httpClient.post(`${this.endpoint}/recuperar_senha/validar_token`, data).pipe();
  }

  alterarSenha(data) {
    return this.httpClient.post(`${this.endpoint}/recuperar_senha/alterar_senha`, data).pipe();
  }

  // =================================================================================
  // ==========          OPERAÇÕES DE EVENTOS           ==============================
  // =================================================================================

  // get all
  getEventos(page: Number) {
    return this.httpClient.get(`${this.endpoint}/eventos?page=${page}&apenas_pais=1`).pipe();
  }

  // show
  getEvento(id) {
    return this.httpClient.get(`${this.endpoint}/eventos/${id}`).pipe();
  }

  // store
  addEvento(data) {
    return this.httpClient.post(`${this.endpoint}/eventos`, data).pipe();
  }

  // delete
  removeEvento(id) {
    return this.httpClient.delete(`${this.endpoint}/eventos/${id}`).pipe();
  }

  // update
  updateEvento(id, data) {
    return this.httpClient.post(`${this.endpoint}/eventos/${id}`, data).pipe();
  }

  alterarVisibilidadeEvento(id: Number, estado: Boolean) {
    return this.httpClient.post(`${this.endpoint}/eventos/${id}/visibilidade`, { visivel: estado }).pipe(
      tap(_ => console.log(`alter eventos visibility id=${id} to estate=${estado}`)),
    );
  }

  // participantes
  getParticipantes(id, pagina) {
    return this.httpClient.get(`${this.endpoint}/eventos/${id}/participantes?page=${pagina}}`).pipe();
  }

  downloadParticipantes(eventoId) {
    return this.httpClient.get(`${this.endpoint}/eventos/${eventoId}/relatorio_inscricoes`, { responseType: "blob" });
  }

  // =================================================================================
  // =========================     POLOS     =========================================
  // =================================================================================

  getPolos(eventId) {
    return this.httpClient.get(`${this.endpoint}/eventos/${eventId}/polos`).pipe();
  }

  addPolo(eventId, data) {
    return this.httpClient.post(`${this.endpoint}/eventos/${eventId}/polos`, data).pipe();
  }

  showPolo(eventId, poloId) {
    return this.httpClient.get(`${this.endpoint}/eventos/${eventId}/polos/${poloId}`).pipe();
  }

  updatePolo(eventId, data) {
    return this.httpClient.post(`${this.endpoint}/eventos/${eventId}/polos`, data).pipe();
  }

  removePolo(eventId, poloId) {
    return this.httpClient.delete(`${this.endpoint}/eventos/${eventId}/polos/${poloId}`).pipe();
  }

  // =================================================================================
  // ========================     UPLOAD IMAGENS     =================================
  // =================================================================================

  uploadImagens(imagens) {
    return this.httpClient.post(`${this.endpoint}/v2/imagemUpload/salvar`, imagens).pipe();
  }

  uploadFileCreate(imagem: FormData) {
    return this.httpClient
      .post(`${this.endpoint}/salvar_imagem`, imagem)
      .pipe<any>((item) => item)
      .toPromise();;
  }

  removeFileCreate(url: string) {

    return this.httpClient
      .delete(`${this.endpoint}/deletar_imagem?url=${url}`)
      .toPromise();
  }

  uploadFileUpdate(productId: number, imagem: FormData) {

    return this.httpClient
      .post(`${this.endpoint}/produto/${productId}/salvar_imagem`, imagem)
      .pipe<any>((item) => item)
      .toPromise();
  }

  removeFileUpdate(productId: number, imageId: number) {
    return this.httpClient
      .delete(`${this.endpoint}/produto/${productId}/imagem/${imageId}/delete`)
      .toPromise();
  }

  // =================================================================================
  // ============================ TAGS ===============================================
  // =================================================================================

  tagCreate(data: Record<string, string | number>) {
    return this.httpClient
      .post(`${this.endpoint}/v2/tag`, data)
      .toPromise();
  }

  tagsList(categoriaId: number, page = 0) {
    return this.httpClient
      .get(`${this.endpoint}/v2/tag?conditions=categoria_id:ilike:${categoriaId}&paginate=${Number(!!page)}&page=${page}`)
      .pipe(map((res: Record<string, unknown>) => (res.data ? res.data : res) as object[]));
  }

  tagShow(tagId: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/tag/${tagId}`)
      .pipe(map(res => res as Record<string, unknown>));
  }

  tagUpdate(data: Record<string, string | number>, tagId: number) {
    return this.httpClient
      .put(`${this.endpoint}/v2/tag/${tagId}`, data)
      .toPromise();
  }

  tagDelete(tagId: number) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/tag/${tagId}`)
      .toPromise();
  }

  getInfotag(infoId: number) {
    return this.httpClient
      .get(`${this.endpoint}/v2/tag/info_tags/${infoId}`)
      .pipe(map((res: Record<string, unknown>) => (res.data ? res.data : res) as object[]));
  }

  aplicarTag(data: { informacao_id: number, tag_id: number; }) {
    return this.httpClient
      .post(`${this.endpoint}/v2/tag/aplicar`, data)
      .toPromise();
  }

  removerTag(tagId: number) {
    return this.httpClient
      .delete(`${this.endpoint}/v2/tag/remover/${tagId}`)
      .toPromise();
  }

  getSolicitations<T>() {
    return this.httpClient
      .get<T>(`${this.endpoint}/v2/solicitacao_emprestimo`)
      .toPromise();
  }

  updateSolicitation({ id, status, justify }: { id: number, status: string, justify?: string }) {
    return this.httpClient
      .put(`${this.endpoint}/v2/solicitacao_emprestimo/${id}`, 
        { 
          status, 
          ...(justify ? { justificativa_reprovacao: justify } : {})
        })
      .toPromise();
  }
}

