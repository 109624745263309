import { EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
var PaginatorComponent = /** @class */ (function () {
    function PaginatorComponent(translate) {
        this.translate = translate;
        this.pageEvent = new EventEmitter();
    }
    PaginatorComponent.prototype.setPage = function (pagenumber) {
        if (pagenumber === void 0) { pagenumber = 1; }
        console.log("Pagina solicitada: ", pagenumber);
        this.pageEvent.emit(pagenumber);
    };
    return PaginatorComponent;
}());
export { PaginatorComponent };
