var SubCategoriaProtocolo = /** @class */ (function () {
    //created_at deveria ser Date()
    function SubCategoriaProtocolo(id, titulo, categoriaServico, created_at) {
        this.id = id;
        this.titulo = titulo;
        this.categoriaServico = categoriaServico;
        this.created_at = created_at;
    }
    return SubCategoriaProtocolo;
}());
export { SubCategoriaProtocolo };
