import { AuthenticationService } from './auth/authentication.service';
import { APIService } from './api.service';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from './event-service';
import { DOCUMENT } from '@angular/common';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private api: APIService,
    private authService: AuthenticationService,
    public translate: TranslateService,
    private eventService: EventService,
    @Inject(DOCUMENT) private document, private elementRef: ElementRef
    ) {
      const langAccept = ['pt-BR', 'es'];
      if (langAccept.includes(navigator.language)) {
        translate.setDefaultLang(navigator.language);
        this.eventService.changeTranslateEvent(navigator.language);
      } else {
        translate.setDefaultLang('pt-BR');
        this.eventService.changeTranslateEvent('pt-BR');
      }
    }
  ngOnInit() {
    this.api.usuarioPossuiChaveValida().then(
      data => {
        if (!data) {
          // Token armazenado na aplicação é invalido relogar o usuario
          this.authService.logout();
          this.router.navigate(['/entrar']);
        }
        this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
            return;
          }
          window.scrollTo(0, 0);
        });
      }
    );
  }
}
