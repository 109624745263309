var DomainService = /** @class */ (function () {
    function DomainService(window) {
        this.window = window;
    }
    DomainService.prototype.getHostname = function () {
        return this.window.location.hostname;
    };
    DomainService.prototype.getPrimarySubDomain = function () {
        if (!this.dominio) {
            this.dominio = this.window.location.hostname.split(".")[0];
        }
        return this.dominio;
    };
    return DomainService;
}());
export { DomainService };
