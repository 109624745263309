
  <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
<label class="radio-label-padding">{{field.label}}:</label>
<input style="color:white" matInput
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      [cpOKButton]="true"
      [(colorPicker)]="field.value"
      [formControlName]="field.name"
      (colorPickerChange)="atualizouCor($event)"/>
<mat-hint></mat-hint>
<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</mat-form-field>
