import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
var novoAtendimentoModalComponent = /** @class */ (function () {
    function novoAtendimentoModalComponent(sanitizer, dialogRef, url) {
        this.sanitizer = sanitizer;
        this.dialogRef = dialogRef;
        this.url = url;
    }
    return novoAtendimentoModalComponent;
}());
export { novoAtendimentoModalComponent };
