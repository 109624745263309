export class Opcao {

    public id:number;
    public titulo: string;
    public descricao: string;
  
    constructor(descricao: string, titulo: string) { 
        this.descricao=descricao;
        this.titulo=titulo;
    }
  
  }