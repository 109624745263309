export class Usuario{
    
    id: number;
    name: string;
    email: string;
    fone: string;
    imagem: string;
    password: string;
    admin: boolean;
    bairro: string;

}