import { Responsavel } from './Responsavel';
import { StatusProtocolo } from './StatusProtocolo';
import { Protocolo } from './Protocolo';

export class AgrupamentoProtocolo {

  id: number;
  responsavel: Responsavel;
  descricao: string;
  created_at: string;
  status:StatusProtocolo;
  categoriaServico: number;
  previsaoEncerramento: string;
  protocolos: Array<Protocolo>;

    
  constructor(id: number, created_at: string, descricao: string, responsavel: Responsavel, statusId: number, statusTitulo: string, categoriaServico: number, previsaoEncerramento: string,  protocolos: Array<Protocolo>){
    this.id=id;
    this.created_at=created_at;
    this.descricao=descricao;
    this.responsavel=responsavel;
    this.status=new StatusProtocolo(statusId, statusTitulo);
    this.previsaoEncerramento = previsaoEncerramento;
    this.categoriaServico=categoriaServico;
    this.protocolos=protocolos;
  }

}