/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./404.component";
import * as i2 from "@ngx-translate/core";
var styles_P404Component = [];
var RenderType_P404Component = i0.ɵcrt({ encapsulation: 2, styles: styles_P404Component, data: {} });
export { RenderType_P404Component as RenderType_P404Component };
export function View_P404Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "app flex-row align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "col-md-6"], ["text-center", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [["class", "float-left display-3 mr-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [["class", "pt-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-dark btn-block"], ["type", "button"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate.instant("error.perdido"); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.translate.instant("error.rota"); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.translate.instant("error.retornar"); _ck(_v, 12, 0, currVal_2); }); }
export function View_P404Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_P404Component_0, RenderType_P404Component)), i0.ɵdid(1, 49152, null, 0, i1.P404Component, [i2.TranslateService], null, null)], null, null); }
var P404ComponentNgFactory = i0.ɵccf("ng-component", i1.P404Component, View_P404Component_Host_0, {}, {}, []);
export { P404ComponentNgFactory as P404ComponentNgFactory };
