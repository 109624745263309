import { ProtocoloAgrupamentoPost } from './ProtocoloAgrupamentoPost';

export class AgrupamentoProtocoloPost {

    id: number ;
    responsavel: number;
    descricao: string;
    categoriaServico: number;
    status: number;
    previsaoEncerramento: string;
    //dataEncerramento: string;
    protocolos: Array<ProtocoloAgrupamentoPost> =[];

    constructor(id: number, responsavel: number, descricao: string,
      categoriaServico: number, status: number, previsaoEncerramento: string, 
      dataEncerramento: string, protocolos: Array<ProtocoloAgrupamentoPost>){
        this.id=id;
        this.responsavel=responsavel;
        this.descricao = descricao;
        this.categoriaServico=categoriaServico;
        this.status=status;
        this.previsaoEncerramento=previsaoEncerramento;
        //this.dataEncerramento=dataEncerramento;
        this.protocolos=protocolos;
    }
    
}