import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-novo-atendimento-modal',
  templateUrl: './novo-atendimento-modal.component.html',
  styleUrls: ['./novo-atendimento-modal.component.css']
})

export class novoAtendimentoModalComponent{

  constructor(
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<novoAtendimentoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public url: any
    ) {

    }
}


