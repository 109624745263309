import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";
var EnqueteResultadoComponent = /** @class */ (function () {
    function EnqueteResultadoComponent(translate, dialogRef, dados) {
        this.translate = translate;
        this.dialogRef = dialogRef;
        this.dados = dados;
        this.datas = [];
        this.labels = [];
        this.graficosProntos = false;
        this.basicOptions = {
            maintainAspectRatio: false,
        };
        this.graficoTipoPie = 'pie';
        this.coresGrafico = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4',
            '#f032e6', '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#fffac8',
            '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9'];
        this.resultadoBairros = dados['resultadoBairros'];
        this.enquetes = dados['data'];
        for (var _i = 0, _a = Object.entries(this.resultadoBairros); _i < _a.length; _i++) {
            var _b = _a[_i], votoOpcao = _b[0], arrayItens = _b[1];
            this.datas[votoOpcao] = Array();
            this.labels[votoOpcao] = Array();
            console.log("Entradas : ", arrayItens);
            for (var _c = 0, _d = Object.entries(this.sort_object(arrayItens)); _c < _d.length; _c++) {
                var _e = _d[_c], bairro = _e[0], votos = _e[1];
                this.datas[votoOpcao].push(votos);
                this.labels[votoOpcao].push(bairro);
            }
        }
        console.log(this.datas);
        console.log("dados: ", this.enquetes, "resultados bairro: ", this.resultadoBairros);
        this.graficosProntos = true;
    }
    EnqueteResultadoComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    EnqueteResultadoComponent.prototype.sort_object = function (obj) {
        var items = Object.keys(obj).map(function (key) {
            return [key, obj[key]];
        });
        items.sort(function (first, second) {
            return second[1] - first[1];
        });
        var sorted_obj = {};
        items.forEach(function (element) {
            sorted_obj[element[0]] = element[1];
        });
        return (sorted_obj);
    };
    return EnqueteResultadoComponent;
}());
export { EnqueteResultadoComponent };
