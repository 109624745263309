import { CanActivateViaAuthGuard } from './auth/can-activate-via-auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { RecuperarSenhaComponent } from './views/recuperar-senha/recuperar-senha.component'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'entrada',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Erro 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Erro 500'
    }
  },
  {
    path: 'entrar',
    component: LoginComponent,
    data: {
      title: 'Entrar'
    }
  },
  {
    path: 'recuperar-senha',
    component: RecuperarSenhaComponent,
    data: {
      title: 'Recuperar senha'
    }
  },
  {
    path: 'registro',
    component: RegisterComponent,
    data: {
      title: 'Registro'
    }
  },
  {
    path: 'relatorios',
    loadChildren: './views/relatorios/relatorios.module#RelatoriosModule',
    data: {
      title: 'Relatorios'
    }
  },
  {
    path: 'relatorios-web',
    component: DefaultLayoutComponent,
    canActivate: [CanActivateViaAuthGuard],
    loadChildren:  './views/relatorios/relatorios.module#RelatoriosModule',
    data: {
      title: 'Relatorios'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'entrada',
        loadChildren: './views/entrada/entrada.module#EntradaModule',
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'dashboard-corona',
        loadChildren: './views/dashboard-corona/dashboard-corona.module#DashboardCoronaModule',
      },
      { 
        path: 'atendimento',
        loadChildren: './views/protocolo/protocolo.module#ProtocoloModule'
      },
      {
        path: '',
        loadChildren: './views/cadastro.module#CadastroModule'
      },
      { 
        path: 'administrativo',
        loadChildren: './views/administrativo/administrativo.module#AdministrativoModule'
      },
      { 
        path: 'modulos',
        loadChildren: './views/modulos/modulos.module#ModulosModule'
      },
    ]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
