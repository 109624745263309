import { ProtocoloPageBody, Protocolo, StatusProtocolo, CategoriaProtocolo, AgrupamentoProtocoloPost, Usuario, ProtocoloAgrupamentoPost, AgrupamentoProtocoloPageBody, AgrupamentoProtocolo, Responsavel, ProtocoloAgrupamento, Autor } from '../../model';

export class AgrupamentoProtocoloConverter {

  static convertPost(id: number, protocolos: Array<Protocolo>, usuario: Usuario, descricao: string, previsaoEncerramento: string, categoria: CategoriaProtocolo): AgrupamentoProtocoloPost {
    let protocolosPost = protocolos.map( protocolo => new ProtocoloAgrupamentoPost(protocolo.id) )
    let objeto = new AgrupamentoProtocoloPost(id, usuario.id, descricao, categoria.id, null, previsaoEncerramento, null, protocolosPost);
    return objeto;
  }

  
  static convertPostProtocolos(id: number, protocolos: Array<number>, usuario: Usuario, descricao: string, categoria: CategoriaProtocolo): AgrupamentoProtocoloPost {
    let protocolosPost = protocolos.map( id => new ProtocoloAgrupamentoPost(id) )
    let objeto = new AgrupamentoProtocoloPost(id, usuario.id, descricao, categoria.id, null, null, null, protocolosPost);
    return objeto;
  }

  static convertObject(agrupamento: any): AgrupamentoProtocolo {

    
    let responsavel = new Responsavel(agrupamento.responsavel.id, agrupamento.responsavel.name, agrupamento.responsavel.email, agrupamento.responsavel.fone, 
      agrupamento.responsavel.imagem, agrupamento.responsavel.verificado, agrupamento.responsavel.admin, agrupamento.responsavel.bairro);
      
      let protocolos = [];
      if(agrupamento.protocolos){
        agrupamento.protocolos.forEach( (prot) =>  protocolos.push(this.convertProtocoloObject(prot.protocolo)) );
      }
      
      let objeto = new AgrupamentoProtocolo(agrupamento.id, agrupamento.created_at, agrupamento.descricao,
         responsavel, agrupamento.status.id, agrupamento.status.titulo, agrupamento.categoriaServico, 
         agrupamento.previsaoEncerramento, protocolos); 
        
      return objeto;
  }
      
      
  static convertProtocoloObject(protocolo: ProtocoloAgrupamento): Protocolo {
    
    let autor = null;
    if(protocolo.autor){
      autor = new Autor(
        protocolo.autor.id, 
        protocolo.autor.name, 
        protocolo.autor.email, 
        protocolo.autor.fone, protocolo.autor.imagem, `protocolo.autor.verificado`, protocolo.autor.admin, protocolo.autor.bairro);
    }
          
    let objeto = new Protocolo(protocolo.id, autor, protocolo.nome, protocolo.imagem,
      protocolo.latitude, protocolo.longitude, protocolo.mensagem, protocolo.created_at); 
            
    let status = new StatusProtocolo(protocolo.status.id, protocolo.status.titulo);
    objeto.status = status;
    
    let categoria = new CategoriaProtocolo(protocolo.categoriaServico.id, 
      protocolo.categoriaServico.titulo, 
      protocolo.categoriaServico.descricao,
      protocolo.categoriaServico.imagem,
      protocolo.categoriaServico.template,
      protocolo.categoriaServico.created_at);
      objeto.categoriaServico = categoria;
      
      return objeto;
  }
    
}