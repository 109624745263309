/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./button.component";
var styles_ButtonComponent = [];
var RenderType_ButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "demo-full-width margin-top"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(3, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(5, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.group; _ck(_v, 1, 0, currVal_7); var currVal_10 = "primary"; _ck(_v, 5, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 3).ngClassValid; var currVal_5 = i0.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i0.ɵnov(_v, 5).disabled || null); var currVal_9 = (i0.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_11 = _co.field.label; _ck(_v, 6, 0, currVal_11); }); }
export function View_ButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i6.ButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonComponentNgFactory = i0.ɵccf("app-button", i6.ButtonComponent, View_ButtonComponent_Host_0, {}, {}, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
