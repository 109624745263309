export * from './AgrupamentoProtocolo';
export * from './Autor';
export * from './CategoriaProtocolo';
export * from './ConfiguracaoMapa';
export * from './Protocolo';
export * from './Responsavel';
export * from './ResultPage';
export * from './StatusProtocolo';
export * from './SubCategoriaProtocolo';
export * from './Usuario';
export * from './PerfilLogado';

export * from './api/post/AgrupamentoProtocoloPost';
export * from './api/post/ProtocoloAgrupamentoPost';
export * from './api/post/EnquetePost';

export * from './api/AgrupamentoProtocoloPageBody';
export * from './api/CategoriaProtocoloBody';
export * from './api/PerfilBody';
export * from './api/ProtocoloPageBody';
export * from './api/SubCategoriaProtocoloBody';
export * from './api/TemplateCategoriaProtocoloBody';
export * from './api/AutorPageBody';
export * from './api/EnquetePageBody';
export * from './UsuarioCategoriaServico';


export * from './form/Opcao';

