/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./informacoes-mapa-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./informacoes-mapa-modal.component";
import * as i4 from "../../../../../api.service";
import * as i5 from "angular2-flash-messages/module/flash-messages.service";
import * as i6 from "@ngx-translate/core";
var styles_InformacoesMapaModalComponent = [i0.styles];
var RenderType_InformacoesMapaModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformacoesMapaModalComponent, data: {} });
export { RenderType_InformacoesMapaModalComponent as RenderType_InformacoesMapaModalComponent };
export function View_InformacoesMapaModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "info-mapa-modal mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "leafletmap"], ["id", "leafletMapId"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-primary float-right mt-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.atualizarLocalizacaoMapa() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate.instant("categorias.localizarInformacao"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.translate.instant("categorias.movaOMapa"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.translate.instant("categorias.atualizar"); _ck(_v, 8, 0, currVal_2); }); }
export function View_InformacoesMapaModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "informacoes-mapa-modal", [], null, null, null, View_InformacoesMapaModalComponent_0, RenderType_InformacoesMapaModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.InformacoesMapaModalComponent, [i4.APIService, i5.FlashMessagesService, i2.MatDialogRef, i6.TranslateService, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformacoesMapaModalComponentNgFactory = i1.ɵccf("informacoes-mapa-modal", i3.InformacoesMapaModalComponent, View_InformacoesMapaModalComponent_Host_0, {}, {}, []);
export { InformacoesMapaModalComponentNgFactory as InformacoesMapaModalComponentNgFactory };
