import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { DateConverterPipe } from './index';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DateConverterPipe,
    ],
    exports:[
        DateConverterPipe,
    ],
    providers:[]
})

export class HelpersMudule { }