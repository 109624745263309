<div mat-dialog-content class="info-mapa-modal">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
    <div *ngIf="!selectedCurso || (selectedCurso &&(!campos ||  !campos.length))">
        <h5>
            {{ translate.instant("CopiarCampos.modalTitulo") }}
        </h5>
        <p>
            {{ translate.instant("CopiarCampos.modalSubTitulo") }}
        </p>
        <hr>
        <span class="text-center">
            <mat-form-field>
                <mat-select [placeholder]="translate.instant('CopiarCampos.grupo')" (selectionChange)="selectGrupo($event.value)">
                    <mat-option *ngFor="let grupo of grupos" [value]="grupo">
                        {{grupo.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>
        <div *ngIf="selectedGrupo && selectedGrupo!=null">
            <span class="text-center" *ngIf="setores.length>0">
                <mat-form-field>
                    <mat-select [placeholder]="translate.instant('CopiarCampos.setor')" (selectionChange)="selectSetor($event.value)">
                        <mat-option *ngFor="let setor of setores" [value]="setor">
                            {{setor.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
            <div *ngIf="setores.length==0">
                {{ translate.instant("CopiarCampos.semSetor") }}
                <hr>
            </div>
            <div *ngIf="selectedSetor">
                <span class="text-center" *ngIf="cursos.length>0">
                    <mat-form-field>
                        <mat-select [placeholder]="translate.instant('CopiarCampos.curso')" (selectionChange)="selectCurso($event.value)">
                            <mat-option *ngFor="let curso of cursos" [value]="curso">
                                {{curso.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>
                <div *ngIf="cursos.length==0">
                    {{ translate.instant("CopiarCampos.semCursos") }}
                    <hr>
                </div>
                <div *ngIf="selectedCurso && campos && campos.length == 0">
                    {{ translate.instant("CopiarCampos.semCapos") }}
                    <hr>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedCurso && campos &&  campos.length != 0">
        <h5>
            {{ selectedCurso.title }}
        </h5>
        <p>
            {{ selectedSetor.nome }}
        </p>
        <hr>
        <div *ngIf="campos && campos.length != 0">

            <div style="margin-left: 30px; margin-right: 70px; display: flex; justify-content:  space-between; flex-wrap: wrap;">
                <h5>
                    {{ translate.instant("CopiarCampos.nomeCampo") }}
                </h5>
                <h5>
                    {{ translate.instant("CopiarCampos.tipo") }}
                </h5>
            </div>
            <div class="table-container">
                <table class="table table-striped" style="width:100%" mdbTable bordered="true">
                    <tbody>
                      <tr mdbTableCol *ngFor="let campo of campos">
                        <td> {{ campo['title'] }} </td>
                        <td> {{ mapType(campo['type']) }} </td>
                      </tr>
                    </tbody>
                  </table>
             </div>

            <hr>
        </div>
        <button type="button" (click)="copiarCampos()" [disabled]="!(campos && campos.length != 0)" class="btn btn-primary float-right mt-3">
            {{ translate.instant("CopiarCampos.cadastrar") }}
        </button>

    </div>


</div>  