
<div mat-dialog-content class="scroll-modal">

  <mat-card-title class="font-weight-bold">{{ category.titulo }}</mat-card-title><br>
  <mat-card-subtitle class="font-weight-bold">{{ translate.instant('atendenteCategoria.atendentes') }}</mat-card-subtitle><hr>
  <table class="table table-striped" style="width:100%" bordered="true">
    <thead >
      <tr>
        <th> {{ translate.instant('atendenteCategoria.nome') }} </th>
        <th> {{ translate.instant('atendenteCategoria.email') }} </th>
        <th> {{ translate.instant('atendenteCategoria.ultimo_acesso') }} </th>
    </thead>
    <tbody>
      <tr mdbTableCol *ngFor="let user of category.users">
        <td> {{ user.name }} </td>
        <td> {{ user.email }} </td>
        <td *ngIf="user.log_max && user.log_max[0]"> {{ mapdate(user.log_max[0].created_at) }} </td>
        <td *ngIf="!user.log_max || !user.log_max[0]"> - </td>
      </tr>
    </tbody>
  </table>
</div>  