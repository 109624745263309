import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core"

@Component({
  templateUrl: '404.component.html'
})
export class P404Component {

  constructor(public translate: TranslateService) { }

}
