<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading> -->
<div class="row">
  <div class="col-sm-12">
    <h4 class="card-title mb-0"> {{translate.instant("agendamentos.tituloConsulta")}} </h4>
    <div class="small text-muted">
      {{translate.instant("agendamentos.descricaoConsulta")}}
    </div>
  </div>
</div><br>
<div class="row">
  <div class="col-sm-4">
    <mat-form-field style="width: 100%;"> 
      <input matInput #cpfinput type="text" [placeholder]="translate.instant('agendamentos.cpfOrCNPJ')" maxlength="14" [(ngModel)]="cpf" (keypress)="numberOnly($event)">
    </mat-form-field>
  </div>
  <div class="col-sm-8">
    <button mat-stroked-button color="primary" (click)="buscar(cpfinput.value)" style="margin-left: 10px;">
      Buscar
    </button>
  </div>
</div>
<div class="row"  *ngIf="buscou && agendamentos.length > 0">
  <div class="col-sm-12">
    <span>
      <b>{{translate.instant("agendamentos.nomeCompleto")}}: </b><span *ngIf="agendamentos.length > 0">{{ agendamentos[0]['nome_completo'] }}</span>
    </span>
  </div>
</div>
<br><br>

<div class="row">
  <div class="col-sm-12">
    <span *ngIf="buscou && !agendamentos.length">{{translate.instant("agendamentos.semAgendamento")}}</span>
    <table *ngIf="buscou && agendamentos.length > 0" class="table table-striped" style="width:100%" mdbTable bordered="true">
      <thead>
        <tr>
          <th>{{translate.instant("agendamentos.data")}}</th>
          <th>{{translate.instant("agendamentos.horario")}}</th>
          <th>{{translate.instant("agendamentos.instituicao")}}</th>
          <th>{{translate.instant("agendamentos.setor")}}</th>
          <th>{{translate.instant("agendamentos.servico")}}</th>
          <th>{{translate.instant("agendamentos.status")}}</th>
          <th>{{translate.instant("agendamentos.acao")}}</th>
        </tr>
      </thead>
      <tbody>
        <tr mdbTableCol *ngFor="let agendamento of agendamentos">
          <td> {{ agendamento['dataAgendamentoFormatada'] | dateConverter:'dd/MM/yyyy' }} </td>
          <td> {{ agendamento['dataAgendamentoFormatada'] | dateConverter:'HH:mm' }} </td>
          <td> {{ agendamento['agendamento']['servicos']['setor']['instituicao']['nome'] }} </td>
          <td> {{ agendamento['agendamento']['servicos']['setor']['nome'] }} </td>
          <td> {{ agendamento['agendamento']['servicos']['nome'] }} </td>
          <td>
            <span class="badge badge-success" *ngIf="agendamento.atendido_as && !agendamento.nao_compareceu">{{translate.instant("agendamentos.atendido")}} {{ agendamento.atendido_as | dateConverter:'dd/MM/yyyy HH:mm'}}</span>
            <span class="badge badge-warning" *ngIf="!agendamento.atendido_as && agendamento.chamado_as && !agendamento.nao_compareceu">{{translate.instant("agendamentos.chamadoAs")}} {{ agendamento.chamado_as | dateConverter:'dd/MM/yyyy HH:mm'}}</span>
            <span class="badge badge-danger" *ngIf="agendamento.chamado_as && agendamento.nao_compareceu">{{translate.instant("agendamentos.naoCompChamado")}} {{ agendamento.chamado_as | dateConverter:'dd/MM/yyyy HH:mm'}}</span>
            <span class="badge badge-danger" *ngIf="!agendamento.atendido_as && !agendamento.chamado_as && agendamento.nao_compareceu">{{translate.instant("agendamentos.naoCompNaoChamado")}}</span>
            <span class="badge badge-info" *ngIf="!agendamento.atendido_as && !agendamento.chamado_as && !agendamento.nao_compareceu">{{translate.instant("agendamentos.naoAtendido")}}</span>
          </td>
          <td>
            <button type="button button-sm" (click)="deletarAgendamento(agendamento)" class="btn btn-sm btn-outline-danger float-right">
              <i class="material-icons">delete</i>
            </button>
          </td>
          <hr>
        </tr>
      </tbody>
    </table>
  </div>
</div>
