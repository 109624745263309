var Autor = /** @class */ (function () {
    function Autor(id, name, email, fone, imagem, verificado, admin, bairro) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.fone = fone;
        this.imagem = imagem;
        this.verificado = verificado;
        this.admin = admin;
        this.bairro = bairro;
    }
    return Autor;
}());
export { Autor };
