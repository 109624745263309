<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
<div mat-dialog-content class="info-mapa-modal">
  <div class="row">
    <div class="col">
      <div class="card bg-white">
        <div class="card-body pb-2 bg-white">
          <div class="row">
            <div class="col-6 text-left">
              <h5 class="mt-4 text-cinza"> {{ translate.instant("categorias.envieUmaFoto") }} </h5>
            </div>
            <div class="col-6 text-left">
              <div class="form-group">
                <input type="file" class="form-control-file" #fileInput (change)="onFileChanged($event)">
              </div>
              <button type="submit" class="btn btn-primary mb-2" (click)="createMoldura()">
                {{ translate.instant("categorias.enviar") }}
              </button>
            </div>
          </div>       
          <div class="row">
            <div class="col-12 text-center">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <div *ngIf="!listMolduras" class="alert alert-warning" role="alert">
                {{ translate.instant("categorias.naoExistemFotos") }}
              </div>
            </div>
            <div *ngFor="let item of listMolduras" class="col-md-3 mb-4 selfie-aprovar">
              <div class="row">
                <div class="col-12 no-padding">
                  <img class="img-selfie" src="{{ item.imagem }}" alt="">
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="mt-4 text-cinza">Criada em</h5>
                      <p class="text-cinza">{{ item.created_at }}</p>
                    </div>
                    <div class="col-6">
                      <h5 class="mt-4 text-cinza"></h5>
                      <button (click)="deletarMoldura(item.id)" type="button" class="btn btn-md btn-block btn-outline-danger">
                        {{ translate.instant("categorias.remover") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>