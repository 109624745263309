import { tileLayer, latLng, marker, icon, divIcon } from 'leaflet';
import { Constantes } from './Constantes';
var MapaHelper = /** @class */ (function () {
    function MapaHelper() {
        throw new Error('Essa classe não deve ser instanciada');
    }
    MapaHelper.createMarkerPopup = function (protocolo) {
        return this.createMarker(protocolo)
            .bindPopup(this.getTemplate(protocolo), { autoPan: true });
    };
    MapaHelper.createMarkerColourPopup = function (protocolo, colour) {
        return this.createColourMarker(protocolo, colour)
            .bindPopup(this.getTemplate(protocolo), { autoPan: true });
    };
    MapaHelper.createMarker = function (protocolo) {
        return marker([protocolo.latitude, protocolo.longitude], { icon: icon(this.iconMarkerLeaflet) });
    };
    MapaHelper.createColourMarker = function (protocolo, colour) {
        var markerHtmlStyles = "\n      background-color: " + colour + ";\n      width: 1.5rem;\n      height: 1.5rem;\n      display: block;\n      left: -0.75rem;\n      top: -0.75rem;\n      position: relative;\n      border-radius: 3rem 3rem 0;\n      transform: rotate(45deg);\n      border: 1px solid #FFFFFF\n    ";
        return marker([protocolo.latitude, protocolo.longitude], { icon: divIcon({
                iconAnchor: [0, 12],
                popupAnchor: [0, -21],
                html: "<span style=\"" + markerHtmlStyles + "\" />"
            })
        });
    };
    MapaHelper.createMapOptions = function (latitude, longitude, zoom, zoomMaximo) {
        if (zoom === void 0) { zoom = 16; }
        if (zoomMaximo === void 0) { zoomMaximo = 16; }
        return {
            layers: [
                tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: zoomMaximo, attribution: '...' })
            ],
            zoom: zoom,
            center: latLng(latitude, longitude),
        };
    };
    MapaHelper.getTemplate = function (protocolo) {
        return "\n    <div class=\"row\">\n    <div class=\"col-sm-12\">\n    <div class=\"row\">\n    <div class=\"col-sm-12\">\n    <h5 class=\"font-weight-bold text-nowrap text-center mt-1\">" + protocolo.nomeFormatado + "</h5>\n    </div>\n    </div>\n    <div class=\"row\">\n    <div class=\"col-sm-12\">\n    <strong><b>ID:</b> " + protocolo.id + "</strong>\n    </div>\n    <div class=\"col-sm-12\">\n    <strong><b>Status:</b> " + protocolo.status.titulo + "</strong>\n    </div>\n    <div class=\"col-sm-12\">\n    <strong><b>Categoria:</b> " + protocolo.categoriaServico.titulo + "</strong>\n    </div>\n    <div class=\"col-sm-12\">\n    " + (protocolo.imagem ? "<img src='" + protocolo.imagem + "' height='150px' width='150px'>" : "") + "\n    </div>\n    </div>\n    </div>\n    </div>\n    </div>\n    ";
    };
    MapaHelper.getTemplateResumido = function (protocolo) {
        return "\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"row\">\n          <div class=\"col-sm-12\">\n            <strong><b>ID:</b> " + protocolo.id + "</strong>\n          </div>\n          <div class=\"col-sm-12\">\n            <strong><b>Status:</b> " + protocolo.status.titulo + "</strong>\n          </div>\n          <div class=\"col-sm-12\">\n            <strong><b>Categoria:</b> " + protocolo.categoriaServico.titulo + "</strong>\n          </div>\n  \n        </div>\n      </div>\n    </div>\n    ";
    };
    MapaHelper.iconMarkerLeaflet = Constantes.iconMarkerLeaflet;
    return MapaHelper;
}());
export { MapaHelper };
