<div mat-dialog-content class="info-mapa-modal">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
    <h5>
        {{ translate.instant("categorias.subcategoria.copiar") }}
    </h5>
    <p>
        {{ translate.instant("categorias.subcategoria.descricao") }}
    </p>
    <hr>
    <span class="text-center">
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('protocolos.categoria')" [(value)]="copiarCategoriasData" (selectionChange)="pegarSubCategorias(copiarCategoriasData)">
                <mat-option *ngFor="let categoria of categorias" [value]="categoria.id">
                    {{categoria.titulo}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </span>
    <hr>
    <div *ngIf="subCategorias && subCategorias.length != 0">
        {{ translate.instant("categorias.subcategoria.categorias") }}
        <ul>
            <li *ngFor="let subCategoria of subCategorias">
                {{subCategoria.titulo}}
            </li>
        </ul>
        <hr>
    </div>
    <div *ngIf="subCategorias && subCategorias.length == 0">
            {{ translate.instant("categorias.subcategoria.semCategorias") }}
        <hr>
    </div>
    <button type="button" (click)="copiarCategorias()" [disabled]="!(subCategorias && subCategorias.length != 0)" class="btn btn-primary float-right mt-3">
        {{ translate.instant("categorias.subcategoria.confirmarCopiar") }}
    </button>
</div>  