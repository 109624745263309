import { APIService } from '../../../api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Protocolo } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from 'angular2-flash-messages';
import { ProtocoloService } from '../protocolo-service';
var SelectResponsavelAtendimentoComponent = /** @class */ (function () {
    function SelectResponsavelAtendimentoComponent(apiService, router, dialogRef, translate, protocoloService, flashMessagesService, protocolo) {
        this.apiService = apiService;
        this.router = router;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.protocoloService = protocoloService;
        this.flashMessagesService = flashMessagesService;
        this.protocolo = protocolo;
        this.mensagemEnvio = '';
        this.loading = true;
        this.usuarioSelecionado = null;
        this.getResponsaveis();
    }
    SelectResponsavelAtendimentoComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    SelectResponsavelAtendimentoComponent.prototype.getResponsaveis = function () {
        var _this = this;
        this._usuarios = [];
        var retorno = this.apiService.getUsuariosPorCategoriaAtendimento(this.protocolo.categoriaServico.id);
        //let retorno = this.apiService.getUsuariosSistema();
        retorno.subscribe(function (result) {
            console.log("Usuarios: ", result);
            result
                .forEach(function (usuario) {
                _this._usuarios.push(usuario);
            });
            _this.loading = false;
        });
        return retorno;
    };
    Object.defineProperty(SelectResponsavelAtendimentoComponent.prototype, "usuarios", {
        get: function () {
            return [].concat(this._usuarios);
        },
        enumerable: true,
        configurable: true
    });
    SelectResponsavelAtendimentoComponent.prototype.selecionarUsuario = function (event) {
        this.usuarioSelecionado = event.value;
    };
    SelectResponsavelAtendimentoComponent.prototype.transferirAtendimento = function () {
        var _this = this;
        this.loading = true;
        if (this.usuarioSelecionado) {
            this.apiService.updateProtocoloAtendente(this.protocolo.id, this.usuarioSelecionado.id).subscribe(function (data) {
                _this.lancarMensagem(_this.translate.instant("protocolos.transferidoSucessoAtendimento"), "alert-success");
                _this.protocoloService.selecionarRemoverListagemProtocolo(_this.protocolo);
                _this.onNoClick();
                _this.loading = false;
            }, function (error) {
                _this.lancarMensagem(_this.translate.instant("protocolos.transferidoErroAtendimento"), "alert-danger");
                _this.onNoClick();
                _this.loading = false;
            });
        }
        else {
            this.lancarMensagem(this.translate.instant("protocolos.selecioneUmUsuario"), "alert-danger");
            this.loading = false;
        }
    };
    SelectResponsavelAtendimentoComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    return SelectResponsavelAtendimentoComponent;
}());
export { SelectResponsavelAtendimentoComponent };
