import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { tileLayer, latLng, marker, icon, Map } from 'leaflet';
import { MapaHelper } from '../../../../helpers';
import { APIService } from '../../../../api.service';

import { TranslateService } from "@ngx-translate/core"
import { ConfiguracaoMapa } from '../../../../model';

@Component({
    selector: 'bairros-modal',
    templateUrl: './bairros-modal.component.html',
    styleUrls: ['./bairros-modal.component.scss']
})

export class BairrosModalComponent implements OnInit {
    
    public map: Map;
    public markerIndividual: any;
    public centerMap;
    public end: any;
    private latitude: any = -5.1841285;
    private longitude: any = -37.3477805;
    private zoom = 14;
    public leafletOptionsIndividual: any;
    public configMapa: ConfiguracaoMapa;

    constructor(
        private apiService: APIService,
        private flashMessagesService: FlashMessagesService,
        public dialogRef: MatDialogRef<BairrosModalComponent>,
        public translate: TranslateService,
        public modal: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.getConfig();
    }
    private getConfig() {
      const retorno = this.apiService.getConfigs();
      this.configMapa = new ConfiguracaoMapa(this.latitude, this.longitude, this.zoom);
      retorno.subscribe((data: Array<object>) => {
        let hasPoints = false;
        data.map(cfg => {
          const prop = JSON.parse(JSON.stringify(cfg));
          if (prop.campo == "latitudeCidade") {
            this.configMapa.latitudeCidade = parseFloat(prop.valor);
            this.latitude = parseFloat(prop.valor);
            hasPoints = true;
          } else if (prop.campo == "longitudeCidade") {
            this.configMapa.longitudeCidade = parseFloat(prop.valor);
            this.longitude = parseFloat(prop.valor);
            hasPoints = true;
          } else if (prop.campo == "zoomMapaCidade") {
            this.configMapa.zoomMapaCidade = parseFloat(prop.valor);
          }
        });
        this.loadMapInd();
      });
    }

    ngOnInit() {
        // this.loadMapInd();
    }

    loadMapInd() {
        this.centerMap = [(this.data.latitude || this.latitude) , (this.data.longitude || this.longitude)];
        this.map = new Map('leafletMapId', {
            center: this.centerMap,
            layers: [
                tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
              ],
            zoom: 16,
        });

        setTimeout(() => {
            this.map.invalidateSize();
        }, 1)

        this.markerIndividual = this.createMarker((this.data.latitude || this.latitude) , (this.data.longitude || this.longitude));
        this.map.addLayer(this.markerIndividual);

        this.map.on("move", data => {
            this.map.removeLayer(this.markerIndividual);           
            this.markerIndividual = this.createMarker(this.map.getCenter().lat, this.map.getCenter().lng)
            this.map.addLayer(this.markerIndividual);
        })
    }

    public atualizarLocalizacaoMapa() {        
        this.apiService.updateGeocodingBairro(this.data.id, this.data.titulo, this.map.getCenter().lat, this.map.getCenter().lng).subscribe(() => {
            this.data.latitude = this.map.getCenter().lat
            this.data.longitute = this.map.getCenter().lng
            this.lancarMensagem(this.translate.instant("categorias.localizaoAtualizar"), "alert-success")
            this.dialogRef.close({data: this.data});
        }, error => {
            this.lancarMensagemDeErro(error['error']['error'], "alert-danger");
        })
    }

    public lancarMensagem(mensagem, tipo) {
        this.flashMessagesService.show(mensagem,{ cssClass: tipo });
    }

    public lancarMensagemDeErro(mensagem, tipo) {
      const campos = Object.keys(mensagem);
      let mensagensDeErro = "";
      if((typeof mensagem) == 'string'){
        // Erros com mensagem direta
        mensagensDeErro = mensagem;
      }else{
        // Erros com multiplas entradas
        for (const campo of campos) {
          mensagensDeErro += ((typeof mensagem[campo]) == 'string' ? mensagem[campo] + "<br>" : mensagem[campo][0] + "<br>" );
        }
      }
      window.scrollTo(0, 0);
      this.flashMessagesService.show(mensagensDeErro, { cssClass: tipo,  timeout: 7000, showCloseBtn: true, closeOnClick: true });
      this.modal.closeAll();
    }

    createMarker(lat, lng){
        let iconMarkerLeaflet = {
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        };
        return marker(
            [lat, lng],
            { icon: icon({
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                iconUrl: 'assets/marker-icon.png',
                shadowUrl: 'assets/marker-shadow.png'
                })
            }
        )
    }
}