export class PerfilLogado {

  readonly name: string;
  readonly email: string;
  readonly fone: string;
  readonly imagem: string;

  constructor(name: string, email: string, fone: string, imagem: string)
  {
    this.name = name;
    this.email = email;
    this.fone = fone;
    this.imagem = imagem;

  }
  
}