import { CategoriaProtocolo, CategoriaProtocoloBody } from '../../model';

export class CategoriaProtocoloConverter {
  
  public static convertObject(categoria: CategoriaProtocoloBody): CategoriaProtocolo {
    let objeto = new CategoriaProtocolo(categoria.id,
      categoria.titulo,
      categoria.descricao,
      categoria.imagem,
      categoria.template.id,
      categoria.created_at);
    return objeto;
  }

}