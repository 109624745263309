import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core"

@Component({
  templateUrl: '500.component.html'
})

export class P500Component {

  constructor(public translate: TranslateService) { }

}
