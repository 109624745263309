import { Protocolo, StatusProtocolo, CategoriaProtocolo, AgrupamentoProtocoloPost, ProtocoloAgrupamentoPost, AgrupamentoProtocolo, Responsavel, Autor } from '../../model';
var AgrupamentoProtocoloConverter = /** @class */ (function () {
    function AgrupamentoProtocoloConverter() {
    }
    AgrupamentoProtocoloConverter.convertPost = function (id, protocolos, usuario, descricao, previsaoEncerramento, categoria) {
        var protocolosPost = protocolos.map(function (protocolo) { return new ProtocoloAgrupamentoPost(protocolo.id); });
        var objeto = new AgrupamentoProtocoloPost(id, usuario.id, descricao, categoria.id, null, previsaoEncerramento, null, protocolosPost);
        return objeto;
    };
    AgrupamentoProtocoloConverter.convertPostProtocolos = function (id, protocolos, usuario, descricao, categoria) {
        var protocolosPost = protocolos.map(function (id) { return new ProtocoloAgrupamentoPost(id); });
        var objeto = new AgrupamentoProtocoloPost(id, usuario.id, descricao, categoria.id, null, null, null, protocolosPost);
        return objeto;
    };
    AgrupamentoProtocoloConverter.convertObject = function (agrupamento) {
        var _this = this;
        var responsavel = new Responsavel(agrupamento.responsavel.id, agrupamento.responsavel.name, agrupamento.responsavel.email, agrupamento.responsavel.fone, agrupamento.responsavel.imagem, agrupamento.responsavel.verificado, agrupamento.responsavel.admin, agrupamento.responsavel.bairro);
        var protocolos = [];
        if (agrupamento.protocolos) {
            agrupamento.protocolos.forEach(function (prot) { return protocolos.push(_this.convertProtocoloObject(prot.protocolo)); });
        }
        var objeto = new AgrupamentoProtocolo(agrupamento.id, agrupamento.created_at, agrupamento.descricao, responsavel, agrupamento.status.id, agrupamento.status.titulo, agrupamento.categoriaServico, agrupamento.previsaoEncerramento, protocolos);
        return objeto;
    };
    AgrupamentoProtocoloConverter.convertProtocoloObject = function (protocolo) {
        var autor = null;
        if (protocolo.autor) {
            autor = new Autor(protocolo.autor.id, protocolo.autor.name, protocolo.autor.email, protocolo.autor.fone, protocolo.autor.imagem, "protocolo.autor.verificado", protocolo.autor.admin, protocolo.autor.bairro);
        }
        var objeto = new Protocolo(protocolo.id, autor, protocolo.nome, protocolo.imagem, protocolo.latitude, protocolo.longitude, protocolo.mensagem, protocolo.created_at);
        var status = new StatusProtocolo(protocolo.status.id, protocolo.status.titulo);
        objeto.status = status;
        var categoria = new CategoriaProtocolo(protocolo.categoriaServico.id, protocolo.categoriaServico.titulo, protocolo.categoriaServico.descricao, protocolo.categoriaServico.imagem, protocolo.categoriaServico.template, protocolo.categoriaServico.created_at);
        objeto.categoriaServico = categoria;
        return objeto;
    };
    return AgrupamentoProtocoloConverter;
}());
export { AgrupamentoProtocoloConverter };
