import { ComponentFactoryResolver, OnInit, ViewContainerRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { InputComponent } from "../input/input.component";
import { ButtonComponent } from "../button/button.component";
import { SelectComponent } from "../select/select.component";
import { DateComponent } from "../date/date.component";
import { RadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { FroalaWysiwygComponent } from './../froala-wysiwyg/froala-wysiwyg.component';
import { FileComponent } from "../file/file.component";
import { ColorSelectComponent } from "../color-select/color-select.component";
import { DateTimeComponent } from '../date-time/date-time.component';
var componentMapper = {
    input: InputComponent,
    button: ButtonComponent,
    select: SelectComponent,
    date: DateComponent,
    radiobutton: RadiobuttonComponent,
    checkbox: CheckboxComponent,
    html: FroalaWysiwygComponent,
    file: FileComponent,
    color: ColorSelectComponent,
    datetime: DateTimeComponent,
};
var DynamicFieldDirective = /** @class */ (function () {
    function DynamicFieldDirective(resolver, container) {
        this.resolver = resolver;
        this.container = container;
    }
    DynamicFieldDirective.prototype.ngOnInit = function () {
        var factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.group = this.group;
    };
    return DynamicFieldDirective;
}());
export { DynamicFieldDirective };
