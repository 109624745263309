/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./not-data.component";
import * as i4 from "@ngx-translate/core";
var styles_NoteDataComponent = [i0.styles];
var RenderType_NoteDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoteDataComponent, data: {} });
export { RenderType_NoteDataComponent as RenderType_NoteDataComponent };
function View_NoteDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_NoteDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dados n\u00E3o encontrado. "]))], null, null); }
function View_NoteDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-warning text-center"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoteDataComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoteDataComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.message; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NoteDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoteDataComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NoteDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-data", [], null, null, null, View_NoteDataComponent_0, RenderType_NoteDataComponent)), i1.ɵdid(1, 49152, null, 0, i3.NoteDataComponent, [i4.TranslateService], null, null)], null, null); }
var NoteDataComponentNgFactory = i1.ɵccf("not-data", i3.NoteDataComponent, View_NoteDataComponent_Host_0, { visible: "visible", message: "message" }, {}, []);
export { NoteDataComponentNgFactory as NoteDataComponentNgFactory };
