import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class EventService {
    emitirChangeOnTranslator = new BehaviorSubject(String);

    public changeTranslateEvent(language) {
        this.emitirChangeOnTranslator.next(language);
    }
}
