import { MatDialogRef, } from '@angular/material/dialog';
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
var ProcoloAtendenteCategoriaDetailModalComponent = /** @class */ (function () {
    function ProcoloAtendenteCategoriaDetailModalComponent(translate, dialogRef, category) {
        this.translate = translate;
        this.dialogRef = dialogRef;
        this.category = category;
        console.log(this.category);
    }
    ProcoloAtendenteCategoriaDetailModalComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    ProcoloAtendenteCategoriaDetailModalComponent.prototype.ngOnInit = function () {
    };
    ProcoloAtendenteCategoriaDetailModalComponent.prototype.ngOnChanges = function (changes) {
        console.log("Chamou onchanges");
    };
    ProcoloAtendenteCategoriaDetailModalComponent.prototype.mapdate = function (date) {
        return date;
        var d = new Date(date);
        return d.getHours() + ":" + d.getMinutes() + " - " + (d.getDay() < 10 ? '0' : '') + d.getDay() + "/" + (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) + "/" + d.getFullYear();
    };
    return ProcoloAtendenteCategoriaDetailModalComponent;
}());
export { ProcoloAtendenteCategoriaDetailModalComponent };
