import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// Import containers
import { DefaultLayoutComponent } from './containers';
var APP_CONTAINERS = [
    DefaultLayoutComponent
];
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
registerLocaleData(localePt, 'pt');
var ɵ0 = {}, ɵ1 = [];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0, ɵ1 };
