import { APIService } from '../../../api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Protocolo } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from 'angular2-flash-messages';
var SelectResponsavelVideoChamadaComponent = /** @class */ (function () {
    function SelectResponsavelVideoChamadaComponent(apiService, router, dialogRef, translate, flashMessagesService, protocolo) {
        this.apiService = apiService;
        this.router = router;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.flashMessagesService = flashMessagesService;
        this.protocolo = protocolo;
        this.mensagemEnvio = '';
        this.loading = true;
        this.usuarioSelecionado = null;
        this.getResponsaveis();
    }
    SelectResponsavelVideoChamadaComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    SelectResponsavelVideoChamadaComponent.prototype.getResponsaveis = function () {
        var _this = this;
        this._usuarios = [];
        var retorno = this.apiService.getUsuariosPorCategoriaAtendimento(this.protocolo.categoriaServico.id);
        //let retorno = this.apiService.getUsuariosSistema();
        retorno.subscribe(function (result) {
            console.log("Usuarios: ", result);
            result
                .forEach(function (usuario) {
                _this._usuarios.push(usuario);
            });
            _this.loading = false;
        });
        return retorno;
    };
    Object.defineProperty(SelectResponsavelVideoChamadaComponent.prototype, "usuarios", {
        get: function () {
            return [].concat(this._usuarios);
        },
        enumerable: true,
        configurable: true
    });
    SelectResponsavelVideoChamadaComponent.prototype.selecionarUsuario = function (event) {
        this.usuarioSelecionado = event.value;
    };
    SelectResponsavelVideoChamadaComponent.prototype.criarVideoChamada = function () {
        var _this = this;
        this.loading = true;
        if (this.usuarioSelecionado) {
            this.apiService.createVideoChamada(this.protocolo.id, this.usuarioSelecionado.id).subscribe(function (data) {
                _this.lancarMensagem("Video chamada criada com sucesso!", "alert-success");
                _this.onNoClick();
                _this.loading = false;
            }, function (error) {
                _this.lancarMensagem("Erro ao criar video chamada!", "alert-danger");
                _this.onNoClick();
                _this.loading = false;
            });
        }
        else {
            this.lancarMensagem("Selecione um usuário para continuar", "alert-danger");
            this.loading = false;
        }
    };
    SelectResponsavelVideoChamadaComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    return SelectResponsavelVideoChamadaComponent;
}());
export { SelectResponsavelVideoChamadaComponent };
