import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DomainService } from './services/domain-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./services/domain-service";
var APIService = /** @class */ (function () {
    function APIService(httpClient, domainSvc) {
        var _this = this;
        this.httpClient = httpClient;
        this.domainSvc = domainSvc;
        this.client_id = '2';
        this.client_secret = 'l7xF7EsRAH3buuvuoiERhjAD84XWeKbPRPQea6JN';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.httpOptionsArquivos = {};
        this.endpoint = 'https://sethas.api.grt8.com.br:4026';
        console.log('Consumindo api na url: ', this.endpoint);
        this.getChaves().toPromise().then(function (data) {
            _this.client_secret = data['pessoal']['token'];
            _this.client_id = data['pessoal']['id'];
        });
        var retorno = this.getConfigs().subscribe(function (data) {
            data.map(function (cfg) {
                var prop = JSON.parse(JSON.stringify(cfg));
                if (prop.campo == "nomePrefeitura") {
                    localStorage.setItem('nomePrefeitura', prop.valor);
                }
                else if (prop.campo == "apiName") {
                    localStorage.setItem('apiName', prop.valor);
                }
                else if (prop.campo == "categoriaSuporte") {
                    localStorage.setItem('categoriaSuporte', prop.valor);
                }
            });
        });
    }
    APIService.prototype.extractData = function (res) {
        return res || {};
    };
    APIService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            console.error(error);
            console.log(operation + " failed: " + error.message);
            return of(result);
        };
    };
    APIService.prototype.criarPedidoSuporte = function (dados) {
        return this.httpClient.post(environment.servidor_suporte + "/protocolos/anonimo", dados);
    };
    APIService.prototype.subCategoriaSuporte = function () {
        return this.httpClient.get(environment.servidor_suporte + "/categoriaServicos");
    };
    APIService.prototype.getLocationPosition = function (position) {
        return this.httpClient.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + JSON.stringify(position) + "&key=" + environment.googlekey);
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM AUTENTICAÇÃO        ==============================
    // =================================================================================
    APIService.prototype.alteraSenhaAuth = function (dados) {
        return this.httpClient.post(this.endpoint + "/usuarios/perfil/alterarSenha", dados);
    };
    APIService.prototype.getChaves = function () {
        return this.httpClient.get(this.endpoint + "/tokens").pipe(map(this.extractData));
    };
    APIService.prototype.getProtocoloLog = function (id) {
        return this.httpClient.get(this.endpoint + "/logs/protocolos/" + id + "/baixarPdf", this.httpOptionsArquivos)
            .map(function (res) { return res; });
    };
    APIService.prototype.usuarioPossuiChaveValida = function () {
        return this.httpClient.get(this.endpoint + "/usuarios/perfil").toPromise().then(function (data) {
            return true;
        }, function () {
            return false;
        });
    };
    APIService.prototype.getPerfilUsuario = function () {
        return this.httpClient.get(this.endpoint + "/usuarios/perfil")
            .pipe(map(this.extractData));
    };
    APIService.prototype.getDadosCorona = function (apenasSuspeitos) {
        if (apenasSuspeitos === void 0) { apenasSuspeitos = 0; }
        return this.httpClient.get(this.endpoint + "/corona/resultados?apenasSuspeitos=" + apenasSuspeitos)
            .pipe(map(this.extractData));
    };
    APIService.prototype.getDadosCoronaPorData = function (inicial, final, pagina, apenasSuspeitos) {
        if (pagina === void 0) { pagina = 0; }
        if (apenasSuspeitos === void 0) { apenasSuspeitos = 0; }
        return this.httpClient.get(this.endpoint + "/corona/relatorio?created_at%2Fend=" + final + "&created_at%2Fstart=" + inicial + "&page=" + pagina + "&apenasSuspeitos=" + apenasSuspeitos + "&porPagina=100");
    };
    APIService.prototype.getCadastrosCoronaPorData = function (pagina, agenda) {
        if (pagina === void 0) { pagina = 0; }
        return this.httpClient.get(this.endpoint + "/cadastro_agendamento?agendamento_id=" + agenda + "&page=1&porPagina=100");
    };
    APIService.prototype.getMinhasInstituicoes = function () {
        return this.httpClient.get(this.endpoint + "/instituicoes_por_permissao").pipe(map(this.extractData));
    };
    APIService.prototype.getCadastrosAgendamentoData = function (idservico, pagina, filtros, inicial, final) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/cadastros/" + idservico + "?paginate=1&page=" + pagina + "&porPagina=15&porData=" + inicial, { params: filtros });
    };
    APIService.prototype.getCadastrosAgendamento = function (idservico, pagina, filtros) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/cadastros/" + idservico + "?paginate=1&page=" + pagina + "&porPagina=15", { params: filtros });
    };
    APIService.prototype.agendamentosRelatorio = function (queryString) {
        return this.httpClient
            .get(this.endpoint + "/v2/agendamentos/relatorio" + queryString)
            .pipe(map(this.extractData))
            .toPromise();
    };
    APIService.prototype.getDadosAgendamentos = function (inicial, final) {
        var dataFiltroInicio = new Date(inicial);
        var inicio_atendimento = ("0" + dataFiltroInicio.getDate()).slice(-2) + "/" + ("0" + (dataFiltroInicio.getMonth() + 1)).slice(-2) + "/" + dataFiltroInicio.getFullYear();
        return this.httpClient.get(this.endpoint + "/servicos/dashboard?inicio_atendimento=" + encodeURI(inicio_atendimento) + "&inicio=" + this.datetoYMD(inicial) + "&fim=" + this.datetoYMD(final)).pipe(map(this.extractData));
    };
    APIService.prototype.datetoYMD = function (date) {
        if (date.constructor.name === "Moment") {
            date = date.toDate();
        }
        try {
            date.getFullYear();
        }
        catch (e) {
            date = new Date(date.toString());
        }
        //return `${date.toString().split(" ")[3]}-${date.getMonth()+1<10?'0':''}${date.getMonth()+1}-${date.getDate()<10?'0':''}${date.getDate()}`
        return date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? '0' : '') + date.getDate();
    };
    APIService.prototype.getAgendamentosPorCPF = function (cpf) {
        return this.httpClient.get(this.endpoint + "/agendamentos/buscar?cpf=" + cpf);
    };
    APIService.prototype.getCadastrosCoronaData = function (pagina, filtros, inicial, final) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/cadastro_agendamento?page=" + pagina + "&porPagina=15&created_at%2Fstart=" + inicial + "&created_at%2Fend=" + final, { params: filtros });
    };
    APIService.prototype.getCadastrosCorona = function (pagina, filtros) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/cadastro_agendamento?page=" + pagina + "&porPagina=15", { params: filtros });
    };
    APIService.prototype.getAgendasCorona = function (pagina, semPaginacao) {
        if (pagina === void 0) { pagina = 1; }
        if (semPaginacao) {
            return this.httpClient.get(this.endpoint + "/agendamento?page=" + pagina + "&porPagina=15&semPaginacao=1");
        }
        return this.httpClient.get(this.endpoint + "/agendamento?page=" + pagina + "&porPagina=15");
    };
    APIService.prototype.getAgendasServico = function (idservico, pagina, semPaginacao) {
        if (pagina === void 0) { pagina = 1; }
        if (semPaginacao) {
            return this.httpClient.get(this.endpoint + "/servicos/" + idservico + "/agendas?semPaginacao=1&servico_agendamento_id=" + idservico);
        }
        return this.httpClient.get(this.endpoint + "/servicos/" + idservico + "/agendas?page=" + pagina + "&porPagina=15&servico_agendamento_id=" + idservico);
    };
    APIService.prototype.getInstituicoes = function (pagina, semPaginacao) {
        if (pagina === void 0) { pagina = 1; }
        if (semPaginacao) {
            return this.httpClient.get(this.endpoint + "/instituicoes?page=" + pagina + "&porPagina=30&semPaginacao=1");
        }
        return this.httpClient.get(this.endpoint + "/instituicoes?page=" + pagina + "&porPagina=30");
    };
    APIService.prototype.alterarVisibilidadeSetor = function (id, status) {
        return this.httpClient.post(this.endpoint + "/v2/setores/visibilidade/" + id, { status: status });
    };
    APIService.prototype.getSetores = function (id, pagina, semPaginacao) {
        if (pagina === void 0) { pagina = 1; }
        if (semPaginacao) {
            return this.httpClient.get(this.endpoint + "/instituicoes/" + id + "/setores?semPaginacao=1");
        }
        return this.httpClient.get(this.endpoint + "/instituicoes/" + id + "/setores?page=" + pagina + "&porPagina=30");
    };
    APIService.prototype.getServicosAgendamento = function (id, pagina, semPaginacao) {
        if (pagina === void 0) { pagina = 1; }
        if (semPaginacao) {
            return this.httpClient.get(this.endpoint + "/setores/" + id + "/servicos?page=" + pagina + "&porPagina=30&semPaginacao=1");
        }
        return this.httpClient.get(this.endpoint + "/setores/" + id + "/servicos?page=" + pagina + "&porPagina=30");
    };
    APIService.prototype.criarInstituicoes = function (update, id, dados) {
        if (update === void 0) { update = false; }
        if (id === void 0) { id = 0; }
        if (update) {
            return this.httpClient.post(this.endpoint + "/v2/instituicoes/" + id, dados);
        }
        else {
            return this.httpClient.post(this.endpoint + "/v2/instituicoes", dados);
        }
    };
    APIService.prototype.criarSetor = function (idinstituicao, update, id, dados) {
        if (update === void 0) { update = false; }
        if (id === void 0) { id = 0; }
        if (update) {
            return this.httpClient.post(this.endpoint + "/instituicoes/" + idinstituicao + "/setores/" + id, dados);
        }
        else {
            return this.httpClient.post(this.endpoint + "/instituicoes/" + idinstituicao + "/setores", dados);
        }
    };
    APIService.prototype.criarServico = function (idsetor, update, id, dados) {
        if (update === void 0) { update = false; }
        if (id === void 0) { id = 0; }
        if (update) {
            return this.httpClient.post(this.endpoint + "/setores/" + idsetor + "/servicos/" + id, dados);
        }
        else {
            return this.httpClient.post(this.endpoint + "/setores/" + idsetor + "/servicos", dados);
        }
    };
    APIService.prototype.deletarInstituicoes = function (id) {
        return this.httpClient.delete(this.endpoint + "/instituicoes/" + id);
    };
    APIService.prototype.deletarSetor = function (idinstituicao, id) {
        return this.httpClient.delete(this.endpoint + "/instituicoes/" + idinstituicao + "/setores/" + id);
    };
    APIService.prototype.deletarServico = function (idsetor, id) {
        return this.httpClient.delete(this.endpoint + "/setores/" + idsetor + "/servicos/" + id);
    };
    APIService.prototype.agendarCadastro = function (idCadastro, idAgenda) {
        return this.httpClient.post(this.endpoint + "/cadastro_agendamento/" + idCadastro + "/agenda/" + idAgenda, {});
    };
    APIService.prototype.deleteAgendarCadastro = function (idAgenda, idCadastro) {
        return this.httpClient.delete(this.endpoint + "/agendamentos/" + idAgenda + "/cadastros/" + idCadastro);
    };
    APIService.prototype.deleteAgenda = function (idServico, idAgenda) {
        return this.httpClient.delete(this.endpoint + "/servicos/" + idServico + "/agendas/" + idAgenda);
    };
    APIService.prototype.updateAgendarCadastro = function (idAgenda, idCadastro, dados) {
        return this.httpClient.put(this.endpoint + "/agendamentos/" + idAgenda + "/cadastros/" + idCadastro, tslib_1.__assign({}, dados));
    };
    APIService.prototype.criarAgendamento = function (update, id, dados) {
        if (update === void 0) { update = false; }
        if (id === void 0) { id = 0; }
        if (update) {
            return this.httpClient.post(this.endpoint + "/servicos/" + id + "/agendas", dados);
        }
        else {
            return this.httpClient.post(this.endpoint + "/servicos/" + id + "/agendas", dados);
        }
    };
    APIService.prototype.criarAgendamentoV2 = function (update, id, dados) {
        if (update === void 0) { update = false; }
        if (id === void 0) { id = 0; }
        if (update) {
            return this.httpClient.post(this.endpoint + "/v2/servicos/" + id + "/agendas_periodo", dados);
        }
        else {
            return this.httpClient.post(this.endpoint + "/v2/servicos/" + id + "/agendas_periodo", dados);
        }
    };
    APIService.prototype.reordenarCamposExtrasServico = function (ordem, idServico) {
        return this.httpClient.post(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos/set_order", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.getCamposExtrasServico = function (idServico, pagina, semPaginacao) {
        if (pagina === void 0) { pagina = 1; }
        if (semPaginacao === void 0) { semPaginacao = false; }
        if (!semPaginacao) {
            return this.httpClient.get(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos");
        }
        return this.httpClient.get(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos?paginate=1&page=" + pagina);
    };
    APIService.prototype.getCamposExtrasServicoExtraPorId = function (id, idServico) {
        return this.httpClient.get(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.criarCamposExtrasServicoExtra = function (campoExtra, idServico) {
        return this.httpClient.post(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos", campoExtra).pipe(tap(function (_) { return console.log("created campo extra id=" + campoExtra); }));
    };
    APIService.prototype.editarCamposExtrasServicoExtra = function (campoExtra, id, idServico) {
        return this.httpClient.post(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos/" + id, campoExtra).pipe(tap(function (_) { return console.log("updated campo extra id=" + id); }));
    };
    APIService.prototype.deleteCamposExtrasServicoExtra = function (id, idServico) {
        return this.httpClient.delete(this.endpoint + "/v2/campos_extras/" + idServico + "/servicos/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted campo extra id=" + id); }));
    };
    // =================================================================================
    // ==========       OPERAÇÕES PARA AGRUPAMENTO DE PROTOCOLO       ==================
    // =================================================================================
    APIService.prototype.getProtocolosDisponiveisSemChat = function (pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/agrupamento/protocolosDisponiveisSemChat?page=" + pagina + "&porPagina=100").pipe(map(this.extractData));
    };
    APIService.prototype.getProtocolosDisponiveisSemChatComFiltro = function (categoriaId) {
        if (categoriaId) {
            return this.httpClient.get(this.endpoint + "/agrupamento/protocolosDisponiveisSemChat?porPagina=100&categoriaServico=" + categoriaId + "&status%2Fnot=" + environment.id_status_encerrado).pipe(map(this.extractData));
        }
        else {
            return this.httpClient.get(this.endpoint + "/agrupamento/protocolosDisponiveisSemChat?porPagina=100&status%2Fnot=" + environment.id_status_encerrado).pipe(map(this.extractData));
        }
    };
    APIService.prototype.getAgrupamentos = function (pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/agrupamento/protocolo?porPagina=5&page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.getMeusAgrupamentos = function (pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/agrupamento/meusAgrupamentos?porPagina=5&page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.getAgrupamento = function (id) {
        return this.httpClient.get(this.endpoint + "/agrupamento/protocolo/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createAgrupamento = function (agrupamento) {
        return this.httpClient.post(this.endpoint + "/agrupamento/protocolo", agrupamento).pipe(tap(function (_) { return console.log("created agrupamento id=" + agrupamento); }));
    };
    APIService.prototype.deleteAgrupamento = function (id) {
        return this.httpClient.delete(this.endpoint + "/agrupamento/protocolo/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted agrupamento id=" + id); }));
    };
    APIService.prototype.updateAgrupamento = function (id, agrupamento) {
        return this.httpClient.put(this.endpoint + "/agrupamento/protocolo/" + id, agrupamento).pipe(tap(function (_) { return console.log("updated agrupamento id=" + id); }));
    };
    APIService.prototype.updateResponsavelAgrupamento = function (idAgrupamento, idUsuario) {
        return this.httpClient.post(this.endpoint + "/agrupamento/" + idAgrupamento + "/usuario/" + idUsuario + "/alterarAtribuicao", {}).pipe(tap(function (_) { return console.log("updated usu\u00E1rio agrupamento id=" + idAgrupamento); }));
    };
    APIService.prototype.updateAgrupamentoAdicionarProtocolos = function (idAgrupamento, agrupamento) {
        return this.httpClient.post(this.endpoint + "/agrupamento/" + idAgrupamento + "/adicionar", agrupamento).pipe(tap(function (_) { return console.log("updated usu\u00E1rio agrupamento id=" + idAgrupamento); }));
    };
    APIService.prototype.updateAgrupamentoRemoverProtocolos = function (idAgrupamento, agrupamento) {
        return this.httpClient.post(this.endpoint + "/agrupamento/" + idAgrupamento + "/excluir", agrupamento).pipe(tap(function (_) { return console.log("updated usu\u00E1rio agrupamento id=" + idAgrupamento); }));
    };
    APIService.prototype.enviarMensagemAgrupamentoProtocolos = function (idAgrupamento, mensagem) {
        return this.httpClient.post(this.endpoint + "/agrupamento/" + idAgrupamento + "/broadcastMensagem", mensagem).pipe(tap(function (_) { return console.log("enviando mensagem para os protocolos do agrupamento id=" + idAgrupamento); }));
    };
    APIService.prototype.alterarStatusAgrupamentoProtocolos = function (idAgrupamento, idStatus) {
        return this.httpClient.get(this.endpoint + "/agrupamento/" + idAgrupamento + "/status/" + idStatus + "/alterarStatus").pipe(tap(function (_) { return console.log("altera o status do agrupamento de protocolos id=" + idAgrupamento); }));
    };
    APIService.prototype.finalizarAgrupamentoProtocolos = function (idAgrupamento) {
        return this.httpClient.get(this.endpoint + "/agrupamento/" + idAgrupamento + "/finalizar").pipe(tap(function (_) { return console.log("finalizando o agrupamento de protocolos id=" + idAgrupamento); }));
    };
    // agrupamento/{idAgrupamento}/usuario/{idUsuario}/alterarAtribuicao
    // =================================================================================
    // ==========           OPERAÇÕES COM NOTICIAS        ==============================
    // =================================================================================
    APIService.prototype.getNoticias = function (pagina, categoria) {
        if (pagina === void 0) { pagina = 1; }
        if (categoria) {
            return this.httpClient.get(this.endpoint + "/noticias?page=" + pagina + "&categoria=" + categoria).pipe(map(this.extractData));
        }
        else {
            return this.httpClient.get(this.endpoint + "/noticias?page=" + pagina).pipe(map(this.extractData));
        }
    };
    APIService.prototype.getNoticia = function (id) {
        return this.httpClient.get(this.endpoint + "/noticias/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createNoticia = function (noticia) {
        return this.httpClient.post(this.endpoint + "/noticias", noticia).pipe(tap(function (_) { return console.log("created noticia id=" + noticia); }));
    };
    APIService.prototype.updateNoticia = function (id, noticia) {
        return this.httpClient.post(this.endpoint + "/noticias/" + id, noticia).pipe(tap(function (_) { return console.log("updated noticia id=" + id); }));
    };
    APIService.prototype.deleteNoticia = function (id) {
        return this.httpClient.delete(this.endpoint + "/noticias/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted noticia id=" + id); }));
    };
    APIService.prototype.getCategoriasNoticia = function () {
        return this.httpClient.get(this.endpoint + "/categoriaNoticias").pipe(map(this.extractData));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM CATEGORIA DE NOTICIAS        =================
    // =================================================================================
    APIService.prototype.getCategoriaNoticias = function () {
        return this.httpClient.get(this.endpoint + "/categoriaNoticias").pipe(map(this.extractData));
    };
    APIService.prototype.getCategoriaNoticia = function (id) {
        return this.httpClient.get(this.endpoint + "/categoriaNoticias/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createCategoriaNoticia = function (CategoriaNoticia) {
        return this.httpClient.post(this.endpoint + "/categoriaNoticias", CategoriaNoticia).pipe(tap(function (_) { return console.log("created CategoriaNoticia id=" + CategoriaNoticia); }));
    };
    APIService.prototype.updateCategoriaNoticia = function (id, CategoriaNoticia) {
        return this.httpClient.post(this.endpoint + "/categoriaNoticias/" + id, CategoriaNoticia).pipe(tap(function (_) { return console.log("updated CategoriaNoticia id=" + id); }));
    };
    APIService.prototype.deleteCategoriaNoticiaForcado = function (id) {
        return this.httpClient.delete(this.endpoint + "/categoriaNoticias/" + id + "/forced", this.httpOptions).pipe(tap(function (_) { return console.log("deleted CategoriaNoticia id=" + id); }));
    };
    APIService.prototype.deleteCategoriaNoticia = function (id) {
        return this.httpClient.delete(this.endpoint + "/categoriaNoticias/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted CategoriaNoticia id=" + id); }));
    };
    APIService.prototype.saveNoticiaImagem = function (id, image) {
        return this.httpClient
            .post(this.endpoint + "/v2/noticias/" + id + "/salvarImagem", image)
            .pipe(function (item) { return (item); });
    };
    APIService.prototype.removeNoticiaImagem = function (id, image) {
        return this.httpClient
            .delete(this.endpoint + "/v2/noticias/" + id + "/deleteImagem?url=" + image)
            .pipe(function (item) { return (item); });
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM INFORMAÇÕES      =================
    // =================================================================================
    APIService.prototype.deleteFotoInformacao = function (id) {
        return this.httpClient.delete(this.endpoint + "/informacoes/imagem/" + id).pipe();
    };
    APIService.prototype.createFotoInformacao = function (campos) {
        return this.httpClient.post(this.endpoint + "/informacoes/imagem", campos).pipe(tap(function (_) { return console.log("created moldura conteudo=" + campos); }));
    };
    APIService.prototype.getinformacoes = function (pagina, categoria) {
        if (pagina === void 0) { pagina = 1; }
        if (categoria) {
            return this.httpClient.get(this.endpoint + "/informacoes?page=" + pagina + "&categoria=" + categoria).pipe(map(this.extractData));
        }
        else {
            return this.httpClient.get(this.endpoint + "/informacoes?page=" + pagina).pipe(map(this.extractData));
        }
    };
    APIService.prototype.getinformacao = function (id) {
        return this.httpClient.get(this.endpoint + "/informacoes/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createinformacoes = function (informacoes) {
        return this.httpClient.post(this.endpoint + "/informacoes", informacoes).pipe(tap(function (_) { return console.log("created informacoes id=" + informacoes); }));
    };
    APIService.prototype.updateInformacao = function (id, informacoes) {
        return this.httpClient.post(this.endpoint + "/informacoes/" + id, informacoes).pipe(tap(function (_) { return console.log("updated informacoes id=" + id); }));
    };
    APIService.prototype.deleteinformacoes = function (id) {
        return this.httpClient.delete(this.endpoint + "/informacoes/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted informacoes id=" + id); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM CATEGORIA DE INFORMAÇÕES      =================
    // =================================================================================
    APIService.prototype.getCategoriasInformacoes = function () {
        return this.httpClient.get(this.endpoint + "/categoriaInformacoes").pipe(map(this.extractData));
    };
    APIService.prototype.getCategoriaInformacao = function (id) {
        return this.httpClient.get(this.endpoint + "/categoriaInformacoes/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.getCategoriaTempoMedio = function () {
        return this.httpClient.get(this.endpoint + "/atendimento/categorias/tempoMedio").pipe(map(this.extractData));
    };
    APIService.prototype.createCategoriaInformacoes = function (informacoes) {
        return this.httpClient.post(this.endpoint + "/categoriaInformacoes", informacoes).pipe(tap(function (_) { return console.log("created categoriaInformacoes id=" + informacoes); }));
    };
    APIService.prototype.enviarArquivoProtocolo = function (protocolo, dados) {
        return this.httpClient.post(this.endpoint + "/protocolos/" + protocolo + "/mensagens/arquivo", dados).pipe(tap(function (_) { return console.log("created arquivo em protocolo id=" + dados); }));
    };
    APIService.prototype.updateCategoriainformacoes = function (id, informacoes) {
        return this.httpClient.post(this.endpoint + "/categoriaInformacoes/" + id, informacoes).pipe(tap(function (_) { return console.log("updated categoriaInformacoes id=" + id); }));
    };
    APIService.prototype.deleteCategoriaInformacoes = function (id) {
        return this.httpClient.delete(this.endpoint + "/categoriaInformacoes/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted categoriaInformacoes id=" + id); }));
    };
    APIService.prototype.deleteCategoriaInformacoesForcado = function (id) {
        return this.httpClient.delete(this.endpoint + "/categoriaInformacoes/" + id + "/forced", this.httpOptions).pipe(tap(function (_) { return console.log("deleted categoriaInformacoes id=" + id); }));
    };
    APIService.prototype.reordenarCategoriaInformacoes = function (ordem) {
        return this.httpClient.post(this.endpoint + "/categoriaInformacoes/ordenar", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.updateGeocodingInfo = function (idProtocolo, lat, lgn) {
        return this.httpClient.put(this.endpoint + "/informacoes/" + idProtocolo + "/localizacao", {
            latitude: lat,
            longitude: lgn
        }).pipe();
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM STATUS        ==============================
    // =================================================================================
    APIService.prototype.getStatus = function () {
        return this.httpClient.get(this.endpoint + "/status").pipe(map(this.extractData));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM VIDEOCHAMADA        ==========================
    // =================================================================================
    APIService.prototype.getVideoChamadas = function (pagina, porPagina) {
        if (pagina === void 0) { pagina = 1; }
        var stringCon = this.endpoint + "/video_chamadas/atribuidas?page=" + pagina;
        if (porPagina) {
            stringCon = stringCon + ("&porPagina=" + porPagina);
        }
        return this.httpClient.get(stringCon).pipe(map(this.extractData));
    };
    APIService.prototype.getVideoToken = function () {
        return this.httpClient.get(this.endpoint + "/video_chamadas/token").pipe(map(this.extractData));
    };
    APIService.prototype.createVideoChamada = function (idprotocolo, idresponsavel) {
        return this.httpClient.post(this.endpoint + "/protocolos/" + idprotocolo + "/video_chamadas", {
            protocolo: idprotocolo,
            protocolo_id: idprotocolo,
            responsible_user_id: idresponsavel
        }).pipe(map(this.extractData));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM SERVIÇOS        ==============================
    // =================================================================================
    APIService.prototype.getServicosPaginado = function (pagina, area, categoria, subCategoria, status, bairro, macroregiao, dataInicial, dataFinal) {
        if (pagina === void 0) { pagina = 1; }
        var stringCon = this.endpoint + "/protocolos?page=" + pagina;
        if (area) {
            stringCon = stringCon + ("&area_categoria=" + area);
        }
        if (categoria) {
            stringCon = stringCon + ("&categoriaServico=" + categoria);
        }
        if (subCategoria) {
            stringCon = stringCon + ("&subCategoriaServico=" + subCategoria);
        }
        if (status) {
            stringCon = stringCon + ("&status=" + status);
        }
        // if (porPagina) { stringCon = stringCon + `&porPagina=${porPagina}`; }
        if (bairro) {
            stringCon = stringCon + ("&bairro_id=" + bairro);
        }
        if (macroregiao) {
            stringCon = stringCon + ("&macroRegiao=" + macroregiao);
        }
        if (dataInicial) {
            stringCon = stringCon + ("&data_inicio=" + dataInicial);
        }
        if (dataFinal) {
            stringCon = stringCon + ("&data_fim=" + dataFinal);
        }
        return this.httpClient.get(stringCon).pipe(map(this.extractData));
    };
    APIService.prototype.getServicosSemPagina = function (pagina, categoria, subCategoria, status, bairro) {
        if (pagina === void 0) { pagina = 1; }
        var stringCon = this.endpoint + "/protocolos?page=" + pagina;
        if (categoria) {
            stringCon = stringCon + ("&categoriaServico=" + categoria);
        }
        if (subCategoria) {
            stringCon = stringCon + ("&subCategoriaServico=" + subCategoria);
        }
        if (status) {
            stringCon = stringCon + ("&status=" + status);
        }
        if (bairro) {
            stringCon = stringCon + ("&bairro_id=" + bairro);
        }
        stringCon = stringCon + "&porPagina=100";
        return this.httpClient.get(stringCon).pipe(map(this.extractData));
    };
    APIService.prototype.getProtocolosResumidos = function (area, categoria, subCategoria, status, bairro, macro, datainicial, datafinal) {
        var stringCon = this.endpoint + "/v2/protocolos/resumido";
        if (categoria || subCategoria || status || bairro) {
            stringCon = stringCon + "?conditions=";
        }
        if (categoria) {
            stringCon = stringCon + ("categoriaServico:=:" + categoria + (subCategoria || status || bairro ? ";" : ""));
        }
        if (subCategoria) {
            stringCon = stringCon + ("subCategoriaServico:=:" + subCategoria + (status || bairro ? ";" : ""));
        }
        if (status) {
            stringCon = stringCon + ("status:=:" + status + (bairro ? ";" : ""));
        }
        if (bairro) {
            stringCon = stringCon + ("bairro_id:=:" + bairro);
        }
        if (!(categoria || subCategoria || status || bairro) && (datainicial || datafinal)) {
            stringCon = stringCon + "?";
        }
        if ((categoria || subCategoria || status || bairro) && (datainicial || datafinal)) {
            stringCon = stringCon + "&";
        }
        if (datainicial) {
            stringCon = stringCon + ("data_inicio=" + datainicial);
        }
        if (datainicial) {
            stringCon = stringCon + "&";
        }
        if (datafinal) {
            stringCon = stringCon + ("data_fim=" + datafinal);
        }
        if (!(categoria || subCategoria || status || bairro) && !(datainicial || datafinal) && (macro || area)) {
            stringCon = stringCon + "?";
        }
        if ((categoria || subCategoria || status || bairro) || (datainicial || datafinal) && (macro || area)) {
            stringCon = stringCon + "&";
        }
        if (macro) {
            stringCon = stringCon + ("macro_regiao=" + macro);
        }
        else if (area) {
            stringCon = stringCon + ("area_categoria=" + area);
        }
        return this.httpClient.get(stringCon).pipe(map(this.extractData));
    };
    APIService.prototype.getServicos = function () {
        return this.httpClient.get(this.endpoint + "/protocolos").pipe(map(this.extractData));
    };
    APIService.prototype.getAgendamentosPorData = function (idServico, idAgenda) {
        return this.httpClient.get(this.endpoint + "/servicos/" + idServico + "/agendas/" + idAgenda).pipe(map(this.extractData));
    };
    APIService.prototype.getCadastroAgendamentosPorData = function (idServico, dataAgenda) {
        return this.httpClient.get(this.endpoint + "/cadastros/" + idServico + "?data=" + dataAgenda).pipe(map(this.extractData));
    };
    APIService.prototype.getDiasDisponiveisAgendamento = function (servicoId) {
        return this.httpClient.get(this.endpoint + "/servicos/" + servicoId + "/dias").pipe(map(this.extractData));
    };
    APIService.prototype.getServicosPorData = function (inicial, final, pagina, status, categoria, subcategoria, bairros, macros) {
        if (pagina === void 0) { pagina = 0; }
        var statusStr = status && status !== 0 ? '&status=' + status : '';
        var categoriaStr = categoria && categoria !== 0 ? '&categoriaServico=' + categoria : '';
        var subStr = subcategoria && subcategoria !== 0 ? '&subCategoriaServico=' + subcategoria : '';
        var bairrosStr = bairros && bairros !== 0 ? '&bairro_id=' + bairros : '';
        var macrosStr = macros && macros !== 0 ? '&macroRegiao=' + macros : '';
        return this.httpClient.get(this.endpoint + "/protocolos?created_at%2Fend=" + final + "&created_at%2Fstart=" + inicial + "&page=" + pagina + statusStr + categoriaStr + subStr + bairrosStr + macrosStr + "&porPagina=100").pipe(map(this.extractData));
    };
    APIService.prototype.getAtendimentoProtocolosAbertosPorCategoria = function (categoriaId) {
        if (categoriaId === void 0) { categoriaId = 0; }
        if (categoriaId === 0) {
            return this.httpClient.get(this.endpoint + "/atendimento/meusProtocolos?status=" + environment.id_status_recebido).pipe(map(this.extractData));
        }
        else {
            return this.httpClient.get(this.endpoint + "/atendimento/meusProtocolos?status=" + environment.id_status_recebido + "&categoriaServico=" + categoriaId).pipe(map(this.extractData));
        }
    };
    APIService.prototype.getAtendimentosAbertosPorUser = function () {
        return this.httpClient.get(this.endpoint + "/atendimento/meusAtendimentosAbertos").pipe(map(this.extractData));
    };
    APIService.prototype.getProtocolo = function (id) {
        return this.httpClient.get(this.endpoint + "/protocolos/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.getProtocolov2 = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/protocolos?conditions=id:=:" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createProtocolo = function (Servico) {
        return this.httpClient.post(this.endpoint + "/protocolos", Servico).pipe(tap(function (_) { return console.log("created protocolo id=" + Servico); }));
        ;
    };
    APIService.prototype.createProtocoloSemUser = function (Servico) {
        return this.httpClient
            .post(this.endpoint + "/protocolos/anonimo", Servico)
            .pipe(tap(function (_) { return console.log("created protocolo id=" + Servico); }));
    };
    APIService.prototype.updateProtocolo = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/protocolos/" + id, Servico).pipe(tap(function (_) { return console.log("updated Protocolo id=" + id); }));
    };
    APIService.prototype.updateProtocoloAtendente = function (id, novoAtendenteId) {
        return this.httpClient.post(this.endpoint + "/protocolos/" + id + "/alterarAtendente", {
            atendente: novoAtendenteId
        }).pipe(tap(function (_) { return console.log("updated atendente Protocolo id=" + id); }));
    };
    APIService.prototype.updateProtocoloCategoria = function (id, novaCategoria, novaSubCategoria) {
        return this.httpClient.post(this.endpoint + "/protocolos/" + id + "/alterarCategoria", {
            categoriaServico: novaCategoria,
            subCategoriaServico: novaSubCategoria,
        }).pipe(tap(function (_) { return console.log("updated atendente Protocolo id=" + id); }));
    };
    APIService.prototype.deleteProtocolo = function (id) {
        return this.httpClient.delete(this.endpoint + "/protocolos/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted Protocolo id=" + id); }));
    };
    APIService.prototype.getProtocolosEntrada = function () {
        return this.httpClient.get(this.endpoint + "/v2/entrada").pipe(map(this.extractData));
    };
    // =================================================================================
    // ==========        OPERAÇÕES COM CATEGORIA SERVIÇOS ==============================
    // =================================================================================
    APIService.prototype.reordenarCategorias = function (ordem) {
        return this.httpClient.post(this.endpoint + "/categoriaServicos/ordenar", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.getSubCategoriaServicos = function () {
        return this.httpClient.get(this.endpoint + "/categoriaServicos").pipe(map(this.extractData));
    };
    APIService.prototype.getCategoriaServicos = function () {
        return this.httpClient.get(this.endpoint + "/categoriaServicos").pipe(map(this.extractData));
    };
    APIService.prototype.getCategoriaServicosv2 = function () {
        return this.httpClient.get(this.endpoint + "/v2/categoriaServicos").pipe(map(this.extractData));
    };
    APIService.prototype.getStatusTotais = function () {
        return this.httpClient.get(this.endpoint + "/status").pipe(map(this.extractData));
    };
    APIService.prototype.getMinhasCategoriaServicos = function (id) {
        var url = this.endpoint + "/atendimento/minhasCategorias" + (id ? "?area_categoria_servico=" + id : '');
        return this.httpClient.get(url).pipe(map(this.extractData));
    };
    APIService.prototype.getMinhasCategoriaServicosAgrupamento = function () {
        return this.httpClient.get(this.endpoint + "/agrupamento/minhasCategorias").pipe(map(this.extractData));
    };
    APIService.prototype.getCategoriaServico = function (id) {
        return this.httpClient.get(this.endpoint + "/categoriaServicos/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createCategoriaServico = function (Servico) {
        return this.httpClient.post(this.endpoint + "/categoriaServicos", Servico).pipe(tap(function (_) { return console.log("created categoriaServicos id=" + Servico); }));
    };
    APIService.prototype.deleteCategoriaServico = function (id) {
        return this.httpClient.delete(this.endpoint + "/categoriaServicos/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted categoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.deleteCategoriaServicoForcado = function (id) {
        return this.httpClient.delete(this.endpoint + "/categoriaServicos/" + id + "/forced", this.httpOptions).pipe(tap(function (_) { return console.log("deleted categoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.updateCategoriaServico = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/categoriaServicos/" + id, Servico).pipe(tap(function (_) { return console.log("updated categoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.alterarVisibilidadeCategoria = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/categoriaServicos/" + id + "/visibilidade", { visivel: estado }).pipe(tap(function (_) { return console.log("alter categoriaServicos visibility id=" + id + " to estate=" + estado); }));
    };
    // =================================================================================
    // ==========        OPERAÇÕES COM AREA CATEGORIA SERVIÇOS ==============================
    // =================================================================================
    APIService.prototype.reordenarAreaCategorias = function (ordem) {
        return this.httpClient.post(this.endpoint + "/areaCategoriaServicos/ordenar", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.getAreaCategoriaServicos = function () {
        return this.httpClient.get(this.endpoint + "/areaCategoriaServicos?portal=true").pipe(map(this.extractData));
    };
    APIService.prototype.getAreaCategoriaServico = function (id) {
        return this.httpClient.get(this.endpoint + "/areaCategoriaServicos/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createAreaCategoriaServico = function (Servico) {
        return this.httpClient.post(this.endpoint + "/areaCategoriaServicos", Servico).pipe(tap(function (_) { return console.log("created categoriaServicos id=" + Servico); }));
    };
    APIService.prototype.deleteAreaCategoriaServico = function (id) {
        return this.httpClient.delete(this.endpoint + "/areaCategoriaServicos/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted categoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.deleteAreaCategoriaServicoForcado = function (id) {
        return this.httpClient.delete(this.endpoint + "/areaCategoriaServicos/" + id + "/forced", this.httpOptions).pipe(tap(function (_) { return console.log("deleted categoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.updateAreaCategoriaServico = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/areaCategoriaServicos/" + id, Servico).pipe(tap(function (_) { return console.log("updated categoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.alterarAreaVisibilidadeCategoria = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/areaCategoriaServicos/" + id + "/visibilidade", { visivel: estado }).pipe(tap(function (_) { return console.log("alter categoriaServicos visibility id=" + id + " to estate=" + estado); }));
    };
    // =================================================================================
    // ==========        OPERAÇÕES COM SUB CATEGORIA SERVIÇOS ==========================
    // =================================================================================
    APIService.prototype.getSubCategoriaServico = function (id) {
        return this.httpClient.get(this.endpoint + "/subCategoriaServicos/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createSubCategoriaServico = function (Servico) {
        return this.httpClient.post(this.endpoint + "/subCategoriaServicos", Servico).pipe(tap(function (_) { return console.log("created SubCategoriaServicos id=" + Servico); }));
    };
    APIService.prototype.deleteSubCategoriaServico = function (id) {
        return this.httpClient.delete(this.endpoint + "/subCategoriaServicos/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted SubCategoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.updateSubCategoriaServico = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/subCategoriaServicos/" + id, Servico).pipe(tap(function (_) { return console.log("updated SubCategoriaServicos Servico id=" + id); }));
    };
    APIService.prototype.reordenarCategoriaServico = function (ordem) {
        return this.httpClient.post(this.endpoint + "/subCategoriaServicos/set_order", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM PESQUISAS       ==============================
    // =================================================================================
    APIService.prototype.getOpcoes = function (idpergunta, pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/v2/pesquisas/perguntas/" + idpergunta + "/opcoes?paginate=1&page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.getOpcao = function (idopcao) {
        return this.httpClient.get(this.endpoint + "/pesquisas/perguntas/opcoes/" + idopcao).pipe(map(this.extractData));
    };
    APIService.prototype.updateOpcoes = function (idopcao, dados) {
        return this.httpClient.put(this.endpoint + "/v2/pesquisas/perguntas/opcoes/" + idopcao, dados).pipe(tap(function (_) { return console.log("created pesquisa id=" + dados); }));
    };
    APIService.prototype.createOpcoes = function (idpergunta, dados) {
        return this.httpClient.post(this.endpoint + "/v2/pesquisas/perguntas/" + idpergunta + "/opcoes", dados).pipe(tap(function (_) { return console.log("created enquete id=" + dados); }));
    };
    APIService.prototype.deleteOpcoes = function (idopcao) {
        return this.httpClient.delete(this.endpoint + "/v2/pesquisas/perguntas/opcoes/" + idopcao).pipe(map(this.extractData));
    };
    APIService.prototype.getPesquisas = function (pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/pesquisas?page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.getPesquisasByTitle = function (titulo, pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/pesquisas?page=" + pagina + "&titulo=" + titulo).pipe(map(this.extractData));
    };
    APIService.prototype.getPerguntasPesquisa = function (id, pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/v2/pesquisas/" + id + "/perguntas?paginate=1&page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.getPerguntaPesquisa = function (idpergunta, idpesquisa) {
        return this.httpClient.get(this.endpoint + "/pesquisas/perguntas/" + idpergunta).pipe(map(this.extractData));
    };
    APIService.prototype.updatePerguntaPesquisa = function (idpergunta, idpesquisa, conteudo) {
        return this.httpClient.put(this.endpoint + "/v2/pesquisas/perguntas/" + idpergunta, conteudo).pipe(map(this.extractData));
    };
    APIService.prototype.deletePerguntaPesquisa = function (idpergunta) {
        return this.httpClient.delete(this.endpoint + "/v2/pesquisas/perguntas/" + idpergunta).pipe(map(this.extractData));
    };
    APIService.prototype.alterarVisibilidadePesquisa = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/pesquisas/" + id + "/visibilidade", { visibilidade: estado }).pipe(tap(function (_) { return console.log("alter pesquisa visibility id=" + id + " to estate=" + estado); }));
    };
    APIService.prototype.getResultadoPesquisa = function (id) {
        return this.httpClient.get(this.endpoint + "/pesquisas/" + id + "/respostas").pipe(map(this.extractData));
    };
    APIService.prototype.getResultadoPesquisaPaginada = function (id, pagina, inicial, final, selecionado, idPesquisa, nameUser) {
        if (pagina === void 0) { pagina = 1; }
        var selecionadoFilter = "";
        var idFilter = "";
        var nomeUsuario = "";
        if (selecionado != -1) {
            selecionadoFilter += "&selecionado=" + (selecionado == true ? 1 : (selecionado == false ? 0 : ""));
        }
        if (idPesquisa != null && idPesquisa != "") {
            idFilter += "&id=" + idPesquisa;
        }
        if (nameUser != null && nameUser != "") {
            nomeUsuario += "&name_user=" + nameUser;
        }
        return this.httpClient.get(this.endpoint + "/pesquisas/" + id + "/respostas/paginadas?page=" + pagina + "&created_at%2Fstart=" + inicial + "&created_at%2Fend=" + final + selecionadoFilter + idFilter + nomeUsuario).pipe(map(this.extractData));
    };
    APIService.prototype.deletarResultadoPesquisa = function (id) {
        return this.httpClient.delete(this.endpoint + "/pesquisas/resposta/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.deletarPesquisa = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/pesquisas/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.reordenarPesquisa = function (ordem) {
        return this.httpClient.post(this.endpoint + "/v2/pesquisas/ordenar", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.salvadaAnotacao = function (id, texto, selecionado) {
        return this.httpClient.post(this.endpoint + "/pesquisas/resposta/" + id, {
            'resposta_texto': texto ? texto : "",
            'selecionado': selecionado == true ? 1 : (selecionado == false ? 0 : ""),
            '_method': 'put'
        }).pipe(map(this.extractData));
    };
    APIService.prototype.getDashboardPesquisa = function (id) {
        return this.httpClient.get(this.endpoint + "/pesquisas/relatorio/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.getDashboardBairrosPesquisa = function (id) {
        return this.httpClient.get(this.endpoint + "/pesquisas/" + id + "/respostas/bairros").pipe(map(this.extractData));
    };
    APIService.prototype.getPesquisa = function (id) {
        return this.httpClient.get(this.endpoint + "/pesquisas/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.updatePesquisa = function (enquete, id) {
        return this.httpClient.put(this.endpoint + "/v2/pesquisas/" + id, enquete).pipe(tap(function (_) { return console.log("created pesquisa id=" + enquete); }));
    };
    APIService.prototype.createPesquisa = function (enquete) {
        return this.httpClient.post(this.endpoint + "/v2/pesquisas", enquete).pipe(tap(function (_) { return console.log("created enquete id=" + enquete); }));
    };
    APIService.prototype.createPesquisaPergunta = function (idpesquisa, conteudo) {
        return this.httpClient.post(this.endpoint + "/v2/pesquisas/" + idpesquisa + "/perguntas", conteudo).pipe(tap(function (_) { return console.log("created enquete id=" + conteudo); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM ENQUETES       ==============================
    // =================================================================================
    APIService.prototype.getEnquete = function (id) {
        return this.httpClient.get(this.endpoint + "/enquetes/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.getResultadoBairros = function (id) {
        return this.httpClient.get(this.endpoint + "/enquetes/" + id + "/bairros").pipe(map(this.extractData));
    };
    APIService.prototype.getEnquetes = function (pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/enquetes?page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.createEnquete = function (enquete) {
        return this.httpClient.post(this.endpoint + "/enquetes", enquete).pipe(tap(function (_) { return console.log("created enquete id=" + enquete); }));
    };
    APIService.prototype.updateEnquete = function (enquete, id) {
        return this.httpClient.put(this.endpoint + "/enquetes/" + id, enquete).pipe(tap(function (_) { return console.log("created enquete id=" + enquete); }));
    };
    APIService.prototype.deleteEnquete = function (id) {
        return this.httpClient.delete(this.endpoint + "/enquetes/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted enquete id=" + id); }));
    };
    APIService.prototype.alterarVisibilidade = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/enquetes/" + id + "/visibilidade", { visivel: estado }).pipe(tap(function (_) { return console.log("alter enquete visibility id=" + id + " to estate=" + estado); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM USUÁRIOS       ================
    // =================================================================================
    APIService.prototype.getUsuarios = function (pagina) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/usuarios?page=" + pagina).pipe(map(this.extractData));
    };
    APIService.prototype.getUsuariosFiltrado = function (pagina, data) {
        if (pagina === void 0) { pagina = 1; }
        return this.httpClient.get(this.endpoint + "/usuarios/filtrar?page=" + pagina, { params: data }).pipe(map(this.extractData));
    };
    APIService.prototype.getUsuariosSistema = function () {
        return this.httpClient.get(this.endpoint + "/usuario/buscarTodosAdmins").pipe(map(this.extractData));
    };
    APIService.prototype.getUsuario = function (id) {
        return this.httpClient.get(this.endpoint + "/usuarios/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createUsuario = function (usuario) {
        return this.httpClient.post(this.endpoint + "/usuarios", usuario).pipe(tap(function (_) { return console.log("created usuario id=" + usuario); }));
    };
    APIService.prototype.updateUsuario = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/usuarios/" + id, Servico).pipe(tap(function (_) { return console.log("updated catgoria Servico id=" + id); }));
    };
    APIService.prototype.deleteUsuario = function (id) {
        return this.httpClient.delete(this.endpoint + "/usuarios/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted enquete id=" + id); }));
    };
    APIService.prototype.createUsuarioSistema = function (usuario) {
        return this.httpClient.post(this.endpoint + "/usuario/admin/cadastrar", usuario).pipe(tap(function (_) { return console.log("created usuario sistema id=" + usuario); }));
    };
    APIService.prototype.getUsuarioSistema = function (id) {
        return this.httpClient.get(this.endpoint + "/usuario/admin/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.getUsuariosPorCategoria = function (categoriaId) {
        return this.httpClient.get(this.endpoint + "/agrupamento/usuarios/DisponiveisParaAgrupar/" + categoriaId).pipe(map(this.extractData));
    };
    APIService.prototype.getUsuariosPorCategoriaAtendimento = function (categoriaId) {
        return this.httpClient.get(this.endpoint + "/atendimento/usuarios/disponiveis/" + categoriaId).pipe(map(this.extractData));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM TEMPLATES       ================
    // =================================================================================
    APIService.prototype.getTemplates = function () {
        return this.httpClient.get(this.endpoint + "/templates").pipe(map(this.extractData));
    };
    APIService.prototype.getTemplate = function (id) {
        return this.httpClient.get(this.endpoint + "/templates/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createTemplate = function (Servico) {
        return this.httpClient.post(this.endpoint + "/templates", Servico).pipe(tap(function (_) { return console.log("created enquete id=" + Servico); }));
    };
    APIService.prototype.updateTemplate = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/templates/" + id, Servico).pipe(tap(function (_) { return console.log("updated catgoria Servico id=" + id); }));
    };
    APIService.prototype.deleteTemplate = function (id) {
        return this.httpClient.delete(this.endpoint + "/templates/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted enquete id=" + id); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM BAIRROS       ================
    // =================================================================================
    APIService.prototype.getBairros = function () {
        return this.httpClient.get(this.endpoint + "/bairros").pipe(map(this.extractData));
    };
    APIService.prototype.updateGeocodingBairro = function (idProtocolo, titulo, lat, lgn) {
        return this.httpClient.post(this.endpoint + "/bairros/" + idProtocolo, {
            latitude: lat,
            longitude: lgn,
            '_method': 'put'
        }).pipe();
    };
    APIService.prototype.getBairro = function (id) {
        return this.httpClient.get(this.endpoint + "/bairros/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createBairro = function (Servico) {
        return this.httpClient.post(this.endpoint + "/bairros", Servico).pipe(tap(function (_) { return console.log("created enquete id=" + Servico); }));
    };
    APIService.prototype.updateBairro = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/bairros/" + id, Servico).pipe(tap(function (_) { return console.log("updated catgoria Servico id=" + id); }));
    };
    APIService.prototype.deleteBairro = function (id) {
        return this.httpClient.delete(this.endpoint + "/bairros/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted enquete id=" + id); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM MACRO REGIOES       ================
    // =================================================================================
    APIService.prototype.getMacroRegioes = function () {
        return this.httpClient.get(this.endpoint + "/macroRegioes").pipe(map(this.extractData));
    };
    APIService.prototype.getMacroRegiao = function (id) {
        return this.httpClient.get(this.endpoint + "/macroRegioes/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createMacroRegioes = function (Servico) {
        return this.httpClient.post(this.endpoint + "/macroRegioes", Servico).pipe(tap(function (_) { return console.log("created macroregiao id=" + Servico); }));
    };
    APIService.prototype.updateMacroRegioes = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/macroRegioes/" + id, Servico).pipe(tap(function (_) { return console.log("updated macroregiao Servico id=" + id); }));
    };
    APIService.prototype.deleteMacroRegioes = function (id) {
        return this.httpClient.delete(this.endpoint + "/macroRegioes/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted macroregiao id=" + id); }));
    };
    APIService.prototype.relatorioUsuariosRegiao = function (params) {
        return this.httpClient
            .get(this.endpoint + "/usuarios/relatorio_por_regiao", { params: params })
            .toPromise();
    };
    // =================================================================================
    // ===============      OPERAÇÕES COM GRUPO DE INSCRICAO       =====================
    // =================================================================================
    APIService.prototype.reordenarGrupoInscricao = function (ordem) {
        return this.httpClient.post(this.endpoint + "/v2/courses_group/set_order", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.getGrupoInscricao = function (title, enabled) {
        return this.httpClient.get(this.endpoint + "/v2/courses_group?conditions=" + title + enabled).pipe(map(this.extractData));
    };
    APIService.prototype.getGrupoInscricaoPorId = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/courses_group/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createGrupoInscricao = function (grupo) {
        return this.httpClient.post(this.endpoint + "/v2/courses_group", grupo).pipe(tap(function (_) { return console.log("created grupo inscri\u00E7\u00E3o id=" + grupo); }));
    };
    APIService.prototype.updateGrupoInscricao = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/v2/courses_group/" + id, Servico).pipe(tap(function (_) { return console.log("updated grupo inscri\u00E7\u00E3o id=" + id); }));
    };
    APIService.prototype.deleteGrupoInscricao = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/courses_group/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted grupo inscri\u00E7\u00E3o id=" + id); }));
    };
    APIService.prototype.alterarVisibilidadeGrupoInscricao = function (id, enabled) {
        return this.httpClient.post(this.endpoint + "/v2/courses_group/" + id + "/visibilidade", { enabled: enabled }).pipe(tap(function (_) { return console.log("alter grupo inscri\u00E7\u00E3o id=" + id + " to enabled=" + enabled); }));
    };
    APIService.prototype.moverInscricao = function (idInscricao, idCurso) {
        return this.httpClient.post(this.endpoint + "/v2/course_enrollment/" + idInscricao, { course_id: idCurso, _method: 'PUT' }).pipe();
    };
    // =================================================================================
    // ====================      OPERAÇÕES COM CURSOS       ============================
    // =================================================================================
    APIService.prototype.reordenarCursos = function (ordem) {
        return this.httpClient.post(this.endpoint + "/v2/course/set_order", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.getCursos = function (id, title, enabled) {
        return this.httpClient.get(this.endpoint + "/v2/course?conditions=course_group_id:=:" + id + ";" + title + enabled).pipe(map(this.extractData));
    };
    APIService.prototype.getCursoPorId = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/course/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createCurso = function (curso) {
        return this.httpClient.post(this.endpoint + "/v2/course", curso).pipe(tap(function (_) { return console.log("created curso id=" + curso); }));
    };
    APIService.prototype.updateCurso = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/v2/course/" + id, Servico).pipe(tap(function (_) { return console.log("updated curso id=" + id); }));
    };
    APIService.prototype.deleteCurso = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/course/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted curso id=" + id); }));
    };
    APIService.prototype.alterarVisibilidadeCurso = function (id, enabled) {
        return this.httpClient.post(this.endpoint + "/v2/course/" + id + "/visibilidade", { enabled: enabled }).pipe(tap(function (_) { return console.log("alter curso id=" + id + " to enabled=" + enabled); }));
    };
    // =================================================================================
    // ====================      OPERAÇÕES COM CAMPOS EXTRAS       =====================
    // =================================================================================
    APIService.prototype.reordenarCursosCamposExtras = function (ordem) {
        return this.httpClient.post(this.endpoint + "/v2/course/extra_field/set_order", ordem).pipe(tap(function (_) { return console.log("reordenando obj=" + ordem); }));
    };
    APIService.prototype.getCamposExtras = function (campoExtra) {
        return this.httpClient.get(this.endpoint + "/v2/course/extra_field_by_course/" + campoExtra).pipe(map(this.extractData));
    };
    APIService.prototype.getCampoExtraPorId = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/course/extra_field/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createCampoExtra = function (campoExtra) {
        return this.httpClient.post(this.endpoint + "/v2/course/extra_field", campoExtra).pipe(tap(function (_) { return console.log("created campo extra id=" + campoExtra); }));
    };
    APIService.prototype.updateCampoExtra = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/v2/course/extra_field/" + id, Servico).pipe(tap(function (_) { return console.log("updated campo extra id=" + id); }));
    };
    APIService.prototype.deleteCampoExtra = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/course/extra_field/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted campo extra id=" + id); }));
    };
    // =================================================================================
    // ====================      OPERAÇÕES COM CAMPOS EXTRAS       =====================
    // =================================================================================
    APIService.prototype.getInscricao = function (cousedID, page) {
        return this.httpClient
            .get(this.endpoint + "/v2/course_enrollment?page=" + page + "&conditions=course_id:=:" + cousedID)
            .pipe(map(this.extractData));
    };
    APIService.prototype.updateInscricaoFinal = function (cousedID, formData) {
        return this.httpClient.post(this.endpoint + "/v2/course_enrollment/" + cousedID, formData).pipe(map(this.extractData));
    };
    APIService.prototype.getInscricaoPorId = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/course_enrollment/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.deleteInscricaoPorId = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/course_enrollment/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted inscricao id=" + id); }));
    };
    APIService.prototype.updateInscricao = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/v2/course_enrollment/" + id, Servico).pipe(tap(function (_) { return console.log("updated campo extra id=" + id); }));
    };
    APIService.prototype.getCourseEnrollment = function (cousedEnrollmentId) {
        return this.httpClient.get(this.endpoint + "/v2/course_enrollment/fields/" + cousedEnrollmentId).pipe(map(this.extractData));
    };
    APIService.prototype.updateCourseEnrollment = function (id, valor) {
        return this.httpClient.post(this.endpoint + "/v2/course_enrollment/fields/" + id, valor).pipe(tap(function (_) { return console.log("updated course enrollment id=" + id); }));
    };
    APIService.prototype.updateCourseEnrollmentFiles = function (valor) {
        return this.httpClient.post(this.endpoint + "/v2/course_enrollment/files", valor).pipe(tap(function (_) { return console.log("updated course enrollment file id=" + valor); }));
    };
    APIService.prototype.getFiltered = function (cousedID, page, filters) {
        var query = Object
            .entries(filters)
            .filter(function (filter) { return filter[1]; })
            .map(function (queries) { return queries[0] + "=" + queries[1]; });
        var pagination = page ? "page=" + page + "&" : '';
        return this.httpClient
            .get(this.endpoint + "/v2/" + cousedID + "/course_enrollments?" + pagination + query);
    };
    // =================================================================================
    // ==========           PARAMETROS DE CONFIGURACAO        ==========================
    // =================================================================================
    APIService.prototype.getConfigs = function () {
        return this.httpClient.get(this.endpoint + "/configs").pipe(map(this.extractData));
    };
    APIService.prototype.getConfigPorNome = function (chave) {
        return this.httpClient.get(this.endpoint + "/configs/" + chave).pipe(map(this.extractData));
    };
    APIService.prototype.createConfig = function (config) {
        return this.httpClient.post(this.endpoint + "/configs", config).pipe(tap(function (_) { return console.log("created config nome=" + config); }));
    };
    APIService.prototype.updateConfig = function (chave, valor) {
        return this.httpClient.post(this.endpoint + "/configs/" + chave, valor).pipe(tap(function (_) { return console.log("updated config chave=" + chave); }));
    };
    APIService.prototype.deleteConfig = function (chave) {
        return this.httpClient.delete(this.endpoint + "/configs/" + chave, {}).pipe(tap(function (_) { return console.log("deleted config chave=" + chave); }));
    };
    // =================================================================================
    // ============           PARAMETROS DE PERMISSÔES        ==========================
    // =================================================================================
    APIService.prototype.getPermissoesPerm = function () {
        return this.httpClient.get(this.endpoint + "/administrativo/permissoes").pipe(map(this.extractData));
    };
    APIService.prototype.getConfigPorId = function (chave) {
        return this.httpClient.get(this.endpoint + "/administrativo/permissoes/" + chave).pipe(map(this.extractData));
    };
    APIService.prototype.createPermissoes = function (config) {
        return this.httpClient.post(this.endpoint + "/administrativo/permissoes", config).pipe(tap(function (_) { return console.log("created config nome=" + config); }));
    };
    APIService.prototype.updatePermissoes = function (chave, valor) {
        return this.httpClient.post(this.endpoint + "/administrativo/permissoes/" + chave, valor).pipe(tap(function (_) { return console.log("updated config chave=" + chave); }));
    };
    APIService.prototype.deletePermissoes = function (chave) {
        return this.httpClient.delete(this.endpoint + "/administrativo/permissoes/" + chave, {}).pipe(tap(function (_) { return console.log("deleted config chave=" + chave); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM A PT. ADMINISTRATIVA        ==================
    // =================================================================================
    APIService.prototype.getRoles = function () {
        return this.httpClient.get(this.endpoint + "/administrativo/funcoes").pipe(map(this.extractData));
    };
    APIService.prototype.getRole = function (id) {
        return this.httpClient.get(this.endpoint + "/administrativo/funcoes/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createRole = function (Servico) {
        return this.httpClient.post(this.endpoint + "/administrativo/funcoes", Servico).pipe(tap(function (_) { return console.log("created enquete id=" + Servico); }));
    };
    APIService.prototype.updateRole = function (id, Servico) {
        return this.httpClient.post(this.endpoint + "/administrativo/funcoes/" + id, Servico).pipe(tap(function (_) { return console.log("updated catgoria Servico id=" + id); }));
    };
    APIService.prototype.deleteRole = function (id) {
        return this.httpClient.delete(this.endpoint + "/administrativo/funcoes/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted enquete id=" + id); }));
    };
    // =================================================================================
    // ===========================         FRONTENDS        ============================
    // =================================================================================
    APIService.prototype.getFrontEnds = function () {
        return this.httpClient.get(this.endpoint + "/v2/frontend").pipe(map(this.extractData));
    };
    APIService.prototype.getFrontEnd = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/frontend/" + id).pipe(map(this.extractData));
    };
    APIService.prototype.createFrontEnd = function (frontend) {
        console.log('Create', frontend);
        return this.httpClient.post(this.endpoint + "/v2/frontend", frontend).pipe(tap(function (_) { return console.log("created frontend id=" + frontend); }));
    };
    APIService.prototype.updateFrontEnd = function (id, frontend) {
        return this.httpClient.post(this.endpoint + "/v2/frontend/" + id, frontend).pipe(tap(function (_) { return console.log("updated frontend id=" + id); }));
    };
    APIService.prototype.deleteFrontEnd = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/frontend/" + id, this.httpOptions).pipe(tap(function (_) { return console.log("deleted frontend id=" + id); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES DASHBOASRD          ==============================
    // =================================================================================
    APIService.prototype.getDados = function (tipo) {
        return this.httpClient
            .get(this.endpoint + "/estatisticas/tipo/" + tipo)
            .toPromise();
    };
    APIService.prototype.getDadosCategorias = function (inicial, final) {
        return this.httpClient.get(this.endpoint + "/estatisticas/protocolosPorCategoria?&inicio=" + this.datetoYMD(inicial) + "&fim=" + this.datetoYMD(final));
    };
    APIService.prototype.getStatusPorCategorias = function (inicial, final) {
        return this.httpClient.get(this.endpoint + "/estatisticas/protocolosPorCategoriaEStatus?&inicio=" + this.datetoYMD(inicial) + "&fim=" + this.datetoYMD(final));
    };
    APIService.prototype.getProtocolosEmAtrasoDashBoard = function () {
        return this.httpClient.get(this.endpoint + "/v2/protocolosEmAtrasoDashBoard");
    };
    APIService.prototype.getCursosDashboard = function (inicial, final) {
        return this.httpClient.get(this.endpoint + "/estatisticas/cursos?&inicio=" + this.datetoYMD(inicial) + "&fim=" + this.datetoYMD(final));
    };
    APIService.prototype.atribuiRoleAUsuario = function (idUsuario, idFuncao) {
        return this.httpClient.get(this.endpoint + "/administrativo/usuario/" + idUsuario + "/funcao/" + idFuncao + "/atribuir").pipe(tap(function (_) { return console.log("atribuiu role id=" + idFuncao + " ao usuario " + idUsuario); }));
    };
    APIService.prototype.desvinculaRoleNoUsuario = function (idUsuario, idFuncao) {
        return this.httpClient.get(this.endpoint + "/administrativo/usuario/" + idUsuario + "/funcao/" + idFuncao + "/desvincular").pipe(tap(function (_) { return console.log("desvinculou role id=" + idFuncao + " ao usuario " + idUsuario); }));
    };
    APIService.prototype.atribuiInstituicaoAUsuario = function (idUsuario, idFuncao) {
        return this.httpClient.get(this.endpoint + "/administrativo/usuario/" + idUsuario + "/instituicao/" + idFuncao + "/atribuir").pipe(tap(function (_) { return console.log("atribuiu instituicao id=" + idFuncao + " ao usuario " + idUsuario); }));
    };
    APIService.prototype.desvinculaInstituicaoNoUsuario = function (idUsuario, idFuncao) {
        return this.httpClient.get(this.endpoint + "/administrativo/usuario/" + idUsuario + "/instituicao/" + idFuncao + "/desvincular").pipe(tap(function (_) { return console.log("desvinculou instituicao id=" + idFuncao + " ao usuario " + idUsuario); }));
    };
    APIService.prototype.getPermissoes = function () {
        return this.httpClient.get(this.endpoint + "/administrativo/permissoes").pipe(map(this.extractData));
    };
    APIService.prototype.atribuiPermissaoNaFuncao = function (idFuncao, idPermissao) {
        return this.httpClient.get(this.endpoint + "/administrativo/funcao/" + idFuncao + "/permissao/" + idPermissao + "/atribuir").pipe(tap(function (_) { return console.log("atribuiu permissao id=" + idPermissao + " a funcao " + idFuncao); }));
    };
    APIService.prototype.desvinculaPermissaoNaFuncao = function (idFuncao, idPermissao) {
        return this.httpClient.get(this.endpoint + "/administrativo/funcao/" + idFuncao + "/permissao/" + idPermissao + "/desvincular").pipe(tap(function (_) { return console.log("desvinculou permissao id=" + idPermissao + " a funcao " + idFuncao); }));
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM ATENDIMENTO        ==============================
    // =================================================================================
    APIService.prototype.getProtocoloAtendentePorCategoria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var users, categories;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.get(this.endpoint + "/v2/atendentesCategoria").pipe(map(this.extractData)).toPromise().then(function (data) {
                            return data;
                        })];
                    case 1:
                        users = _a.sent();
                        return [4 /*yield*/, this.getCategoriaServicos().toPromise().then(function (data) {
                                return data;
                            })];
                    case 2:
                        categories = _a.sent();
                        categories.forEach(function (category) {
                            category["users"] = [];
                            category["lastUser"] = null;
                            users.forEach(function (user) {
                                if (user.categorias_atendimentos.find(function (i) { return i.id === category.id; }) && user.email != "gestor@admin.com") {
                                    if (category.users.length) {
                                        category.users.push(user);
                                    }
                                    else {
                                        category["users"] = [user];
                                    }
                                    // Adiciona o último usuário logado à categoria
                                    if (category.lastUser && category.lastUser.log_max && category.lastUser.log_max.length && user && user.log_max && user.log_max.length) {
                                        if (_this.formatDate(category.lastUser.log_max[0].created_at) < _this.formatDate(user.log_max[0].created_at)) {
                                            category["lastUser"] = user;
                                        }
                                    }
                                    else {
                                        category["lastUser"] = user;
                                    }
                                }
                            });
                            // console.log(category)
                            category.users.sort(function (a, b) {
                                if (a.log_max && a.log_max.length && b.log_max && b.log_max.length)
                                    return _this.formatDate(b.log_max[0].created_at).getTime() - _this.formatDate(a.log_max[0].created_at).getTime();
                                else
                                    return -1;
                            });
                        });
                        return [2 /*return*/, categories];
                }
            });
        });
    };
    APIService.prototype.formatDate = function (date) {
        console.log(date);
        if (!date)
            return null;
        var stringSplited = date.split(" - ");
        if (stringSplited) {
            var hourSplited = stringSplited[1].split(":");
            var dateSplited = stringSplited[0].split("/");
            console.log(date, stringSplited);
            if (hourSplited && dateSplited)
                return new Date(dateSplited[2], parseInt(dateSplited[1]) - 1, dateSplited[0], hourSplited[0], hourSplited[1]);
            else
                return null;
        }
        return null;
    };
    APIService.prototype.iniciarAtendimento = function (protocolo) {
        return this.httpClient.post(this.endpoint + "/atendimento/iniciarAtendimento", protocolo).pipe(tap(function (_) { return console.log("iniciou atendimento protocolo =" + protocolo); }));
    };
    APIService.prototype.encerrarAtendimento = function (protocolo) {
        return this.httpClient.post(this.endpoint + "/atendimento/encerrarAtendimento", protocolo).pipe(tap(function (_) { return console.log("encerrou atendimento protocolo =" + protocolo); }));
    };
    APIService.prototype.enviaProtocoloResposta = function (resposta) {
        return this.httpClient.post(this.endpoint + "/protocoloRespostas", resposta).pipe(tap(function (_) { return console.log("enviou a resposta =" + resposta); }));
    };
    APIService.prototype.enviaRespostaParaProtocolo = function (protocoloId, resposta) {
        return this.httpClient.post(this.endpoint + "/protocolos/" + protocoloId + "/mensagens", resposta).pipe(tap(function (_) { return console.log("enviou a resposta =" + resposta); }));
    };
    APIService.prototype.getProtocoloRespostas = function (protocoloId) {
        return this.httpClient.get(this.endpoint + "/protocolos/" + protocoloId + "/mensagens").pipe(map(this.extractData));
    };
    APIService.prototype.getMinhasCategoriasAtendimento = function () {
        return this.httpClient.get(this.endpoint + "/atendimento/minhasCategorias").pipe(map(this.extractData));
    };
    APIService.prototype.getMinhasAreas = function () {
        return this.httpClient.get(this.endpoint + "/v2/atendimento/minhasAreas").pipe(map(this.extractData));
    };
    APIService.prototype.getMeusProtocolosEmAtendimento = function () {
        return this.httpClient.get(this.endpoint + "/atendimento/meusAtendimentosAbertos").pipe(map(this.extractData));
    };
    APIService.prototype.getMeusProtocolosEmAberto = function () {
        return this.httpClient.get(this.endpoint + "/atendimento/meusProtocolos").pipe(map(this.extractData));
    };
    APIService.prototype.getUsuarioCategoriasServico = function () {
        return this.httpClient.get(this.endpoint + "/atendimento/categoriasServico").pipe(map(this.extractData));
    };
    APIService.prototype.createUsuarioCategoriaServico = function (usuarioCategoriaServico) {
        return this.httpClient.post(this.endpoint + "/atendimento/categoriasServico", usuarioCategoriaServico).pipe(tap(function (_) { return console.log("created usuarioCategoriaServico id=" + usuarioCategoriaServico); }));
    };
    APIService.prototype.deleteUsuarioCategoriaServico = function (usuarioCategoriaServico) {
        return this.httpClient.post(this.endpoint + "/atendimento/categoriasServico/conjunto", usuarioCategoriaServico).pipe(tap(function (_) { return console.log("created usuarioCategoriaServico id=" + usuarioCategoriaServico); }));
    };
    APIService.prototype.updateConfirmarMensagens = function (idProtocolo) {
        return this.httpClient.get(this.endpoint + "/protocolo/" + idProtocolo + "/confirmarMensagens").pipe();
    };
    APIService.prototype.updateStatusAtendimento = function (idProtocolo, status) {
        return this.httpClient.put(this.endpoint + "/protocolos/" + idProtocolo + "/statusAtendimento", { statusatendimento: status }).pipe();
    };
    // =================================================================================
    // ==========           OPERAÇÕES COM SELFIES        ==============================
    // =================================================================================
    // Recupera a lista de modulos de selfies cadastrado
    APIService.prototype.criarModuloSelfies = function (data) {
        return this.httpClient.post(this.endpoint + "/modulos/fotos", data).pipe();
    };
    // Recupera o modulo de selfies por id passado
    APIService.prototype.editaModuloSelfies = function (data, id) {
        return this.httpClient.post(this.endpoint + "/modulos/fotos/" + id, data).pipe();
    };
    // Deleta o modulos de selfies por id passado
    APIService.prototype.deletarModuloSelfies = function (id) {
        return this.httpClient.delete(this.endpoint + "/modulos/fotos/" + id).pipe();
    };
    // Recupera a lista de modulos de selfies cadastrado
    APIService.prototype.getListModulosSelfies = function (page) {
        return this.httpClient.get(this.endpoint + "/modulos/fotos?page=" + page + "&portal=1").pipe();
    };
    // Recupera a lista de modulos de selfies cadastrado
    APIService.prototype.getModuloSelfies = function (id) {
        return this.httpClient.get(this.endpoint + "/modulos/fotos/" + id + "?portal=1").pipe();
    };
    // Recupera todas selfies para moderação de um determinado modulo
    APIService.prototype.getListSelfiesGeral = function (id, page) {
        return this.httpClient.get(this.endpoint + "/modulos/fotos/" + id + "/fotos?page=" + page + "&portal=1").pipe();
    };
    // Recupera todas selfies que precisam de aprovação de um determinado modulo
    APIService.prototype.getListSelfiesAprovar = function (id) {
        return this.httpClient.get(this.endpoint + "/modulos/fotos/" + id + "/paraAprovar").pipe();
    };
    // Recupera todas selfies que precisam de aprovação de um determinado modulo
    APIService.prototype.getListMoldurasPorModulo = function (id) {
        return this.httpClient.get(this.endpoint + "/modulos/fotos/" + id + "/molduras").pipe();
    };
    // Cria moldura para um modulo
    APIService.prototype.createMoldura = function (campos) {
        return this.httpClient.post(this.endpoint + "/modulos/fotos/molduras", campos).pipe(tap(function (_) { return console.log("created moldura conteudo=" + campos); }));
    };
    // Confirma aprovação da selfie para ser visualizada.
    APIService.prototype.aprovarSelfie = function (id) {
        return this.httpClient.post(this.endpoint + "/modulos/fotos/fotos/" + id, { _method: 'PUT', visivel: true }).pipe();
    };
    // Confirma aprovação da selfie para ser visualizada.
    APIService.prototype.deletarSelfie = function (id) {
        return this.httpClient.delete(this.endpoint + "/modulos/fotos/fotos/" + id).pipe();
    };
    // Confirma aprovação da selfie para ser visualizada.
    APIService.prototype.deletarMoldura = function (id) {
        return this.httpClient.delete(this.endpoint + "/modulos/fotos/molduras/" + id).pipe();
    };
    // =================================================================================
    // ==================== OPERAÇÕES COM MODULO FEIRINHA ==============================
    // =================================================================================
    // Recupera a lista de modulos cadastrado
    APIService.prototype.criarModuloFeirinha = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/modulo", data).pipe();
    };
    // Recupera o modulo por id passado
    APIService.prototype.editaModuloFeirinha = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/modulo/" + id, data).pipe();
    };
    // Deleta o modulos por id passado
    APIService.prototype.deletarModuloFeirinha = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/feirinha/modulo/" + id).pipe();
    };
    // Recupera a lista de modulos cadastrados
    APIService.prototype.getListModulosFeirinha = function (page) {
        return this.httpClient.get(this.endpoint + "/v2/feirinha/modulo?paginate=1&page=" + page).pipe();
    };
    // Recupera a modulo específico
    APIService.prototype.getModuloFeirinha = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/feirinha/modulo/" + id).pipe();
    };
    // Altera visibilidade
    APIService.prototype.alterarAreaVisibilidadeModuloFeirinha = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/modulo/" + id, { status: estado }).pipe(tap(function (_) { return console.log("alter moduloFeirinha visibility id=" + id + " to estate=" + estado); }));
    };
    // Recupera a lista de produtos de modulo específico
    APIService.prototype.getModuloFeirinhaProdutos = function (id, page, situacao, titulo, dataInicio, dataFim) {
        return this.httpClient.get(this.endpoint + "/v2/feirinha/modulo_produto/" + id + "?conditions=" + situacao + titulo + "&data_inicio=" + dataInicio + "&data_fim=" + dataFim + "&paginate=1&page=" + page).pipe();
    };
    // Recupera a lista com filtros de produtos de modulo específico
    APIService.prototype.getFiltroModuloFeirinhaProdutos = function (id, page, situacao, titulo, dataInicio, dataFim) {
        return this.httpClient.get(this.endpoint + "/v2/feirinha/modulo_produto/" + id + "?conditions=" + situacao + titulo + "&data_inicio=" + dataInicio + "&data_fim=" + dataFim + "&&paginate=1&page=" + page).pipe();
    };
    // Altera a situação do produto
    APIService.prototype.setSituacaoProduto = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/produto/" + id, data).pipe();
    };
    // Deleta produto
    APIService.prototype.deletarProdutoModulo = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/feirinha/produto/" + id).pipe();
    };
    // Recupera a lista de categorias de modulo específico
    APIService.prototype.getModuloFeirinhaCategorias = function (id, page) {
        return this.httpClient.get(this.endpoint + "/v2/feirinha/categoria?conditions=modulo_id:=:" + id + "&paginate=1&page=" + page).pipe();
    };
    // Recupera a modulo específico
    APIService.prototype.getModuloFeirinhaCategoria = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/feirinha/categoria/" + id).pipe();
    };
    // Recupera a lista de modulos cadastrado
    APIService.prototype.criarModuloFeirinhaCategoria = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/categoria", data).pipe();
    };
    // Recupera o modulo por id passado
    APIService.prototype.editaModuloFeirinhaCategoria = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/categoria/" + id, data).pipe();
    };
    // Altera a situação do categoria
    APIService.prototype.setSituacaoCategoria = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/categoria/" + id, data).pipe();
    };
    // Deleta categoria
    APIService.prototype.deletarModuloFeirinhaCategoria = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/feirinha/categoria/" + id).pipe();
    };
    // Altera visibilidade categoria
    APIService.prototype.alterarAreaVisibilidadeModuloFeirinhaCategoria = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/v2/feirinha/categoria/" + id, { status: estado }).pipe(tap(function (_) { return console.log("alter CategoriaFeirinha visibility id=" + id + " to estate=" + estado); }));
    };
    // =================================================================================
    // ============================= VITRINE ===========================================
    // =================================================================================
    APIService.prototype.createVitrine = function (data) {
        return this.httpClient
            .post(this.endpoint + "/v2/cadsol/vitrine", data)
            .pipe(map(function (res) { return res.data ? res.data : res; }))
            .toPromise();
    };
    APIService.prototype.getVitrines = function (page) {
        if (page === void 0) { page = 1; }
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine?paginate=1&page=" + page)
            .pipe(map(function (res) { return res.data; }))
            .toPromise();
    };
    APIService.prototype.getVitrine = function (vitrineId) {
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId)
            .pipe(map(function (res) { return res.data ? res.data : res; }))
            .toPromise();
    };
    APIService.prototype.updateVitrine = function (vitrineId, payload) {
        return this.httpClient
            .post(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId, payload)
            .pipe(map(function (res) { return res.data ? res.data : res; }))
            .toPromise();
    };
    APIService.prototype.deleteVitrine = function (vitrineId) {
        return this.httpClient
            .delete(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId)
            .pipe(map(function (res) { return res.data ? res.data : res; }))
            .toPromise();
    };
    APIService.prototype.createCategoriaVitrine = function (vitrineId, payload) {
        return this.httpClient
            .post(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/categoria", payload)
            .pipe(map(function (res) { return res.data; }))
            .toPromise();
    };
    APIService.prototype.getCategoriasVitrine = function (vitrineId, page) {
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/categoria?paginate=1&page=" + page)
            .pipe(map(function (res) { return res.data; }))
            .toPromise();
    };
    APIService.prototype.getCategoriasVitrineUnpagined = function (vitrineId) {
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/categoria")
            .pipe(map(function (res) { return res; }))
            .toPromise();
    };
    APIService.prototype.getCategoriaVitrine = function (vitrineId, id) {
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/categoria/" + id)
            .pipe(map(function (res) { return res; }))
            .toPromise();
    };
    APIService.prototype.updateCategoriaVitrine = function (vitrineId, categoriaId, payload) {
        return this.httpClient
            .post(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/categoria/" + categoriaId, payload)
            .pipe(map(function (res) { return res.data; }))
            .toPromise();
    };
    APIService.prototype.deleteCategoriaVitrine = function (vitrineId, categoriaId) {
        return this.httpClient
            .delete(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/categoria/" + categoriaId)
            .pipe(map(function (res) { return res.data ? res.data : res; }))
            .toPromise();
    };
    APIService.prototype.createProdutoVitrine = function (vitrineId, payload) {
        return this.httpClient
            .post(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/produto", payload)
            .pipe(map(function (res) { return res.data; }))
            .toPromise();
    };
    APIService.prototype.getProdutosVitrine = function (vitrineId, page, conditions) {
        var conditionsStr = conditions ? "&conditions=" + conditions : "";
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/produto?paginate=1&page=" + page + conditionsStr)
            .toPromise();
    };
    APIService.prototype.getProdutoVitrine = function (vitrineId, produtoId) {
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/produto/" + produtoId)
            .pipe(map(function (res) { return res; }))
            .toPromise();
    };
    APIService.prototype.updateProdutoVitrine = function (vitrineId, produtoId, payload) {
        return this.httpClient
            .post(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/produto/" + produtoId, payload)
            .pipe(map(function (res) { return res; }))
            .toPromise();
    };
    APIService.prototype.deleteProdutoVitrine = function (vitrineId, produtoId) {
        return this.httpClient
            .delete(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/produto/" + produtoId)
            .pipe(map(function (res) { return res.data; }))
            .toPromise();
    };
    APIService.prototype.getEmpreendimentosVitrine = function (vitrineId, filters) {
        if (filters === void 0) { filters = []; }
        var filterStr = filters
            .map(function (filter) { return filter[0] + "=" + filter[1]; })
            .join('&');
        return this.httpClient
            .get(this.endpoint + "/v2/cadsol/vitrine/" + vitrineId + "/empreendimento?" + filterStr)
            .pipe(map(function (res) { return res; }))
            .toPromise();
    };
    // =================================================================================
    // ========================== TIPO CARTEIRA ========================================
    // =================================================================================
    // Recupera Tipo carteira
    APIService.prototype.getTiposCarteira = function (page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/carteira/tipo?paginate=1&page=" + page + filter).pipe();
    };
    // Cria Tipo carteira
    APIService.prototype.criarTipoCarteira = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/carteira/tipo", data).toPromise();
        ;
    };
    // Recupera Tipo carteira específico
    APIService.prototype.getTipoCarteira = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/carteira/tipo/" + id).pipe();
    };
    // Recupera o modulo por id passado
    APIService.prototype.editarTipoCarteira = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/carteira/tipo/" + id, data).toPromise();
    };
    // Deleta Tipo carteira
    APIService.prototype.deletarTipoCarteira = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/carteira/tipo/" + id).toPromise();
    };
    // =================================================================================
    // ========================== CAMPOS CARTEIRA ======================================
    // =================================================================================
    // Recupera campos carteira
    APIService.prototype.getCamposCarteira = function (idCarteira, page) {
        return this.httpClient.get(this.endpoint + "/v2/carteira/" + idCarteira + "/tipo/campo_carteira?paginate=1&page=" + page).pipe();
    };
    // Cria campo carteira
    APIService.prototype.criarCampoCarteira = function (data, idCarteira) {
        return this.httpClient.post(this.endpoint + "/v2/carteira/" + idCarteira + "/tipo/campo_carteira", data).toPromise();
    };
    // Recupera campo carteira específico
    APIService.prototype.getCampoCarteira = function (idCarteira, id) {
        return this.httpClient.get(this.endpoint + "/v2/carteira/" + idCarteira + "/tipo/campo_carteira/" + id).pipe();
    };
    // Recupera o modulo por id passado
    APIService.prototype.editarCampoCarteira = function (data, idCarteira, id) {
        return this.httpClient.post(this.endpoint + "/v2/carteira/" + idCarteira + "/tipo/campo_carteira/" + id, data).toPromise();
    };
    // Deleta campo carteira
    APIService.prototype.deletarCampoCarteira = function (idCarteira, id) {
        return this.httpClient.delete(this.endpoint + "/v2/carteira/" + idCarteira + "/tipo/campo_carteira/" + id).toPromise();
    };
    // =================================================================================
    // ============================ GRUPO CARTEIRA =====================================
    // =================================================================================
    APIService.prototype.getGrupos = function () {
        return this.httpClient.get(this.endpoint + "/v2/grupo").pipe();
    };
    // Recupera Grupo carteira
    APIService.prototype.getGruposCarteira = function (page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/grupo?paginate=1&page=" + page + filter).pipe();
    };
    // Cria Grupo carteira
    APIService.prototype.criarGrupoCarteira = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/grupo", data).toPromise();
    };
    // Recupera Grupo carteira específico
    APIService.prototype.getGrupoCarteira = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/grupo/" + id).pipe();
    };
    // Recupera o modulo por id passado
    APIService.prototype.editarGrupoCarteira = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/grupo/" + id, data).toPromise();
    };
    // Deleta Grupo carteira
    APIService.prototype.deletarGrupoCarteira = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/grupo/" + id).toPromise();
    };
    // Atribui titulares ao Grupo carteira
    APIService.prototype.atribuirTitular = function (data, idGrupo) {
        return this.httpClient.post(this.endpoint + "/v2/grupo/" + idGrupo + "/atribuir", data).pipe();
    };
    // Desatribuir titulares ao Grupo carteira
    APIService.prototype.desatribuirTitular = function (data, idGrupo) {
        return this.httpClient.post(this.endpoint + "/v2/grupo/" + idGrupo + "/desatribuir", data).pipe();
    };
    // Recupera Grupo carteira específico
    APIService.prototype.getGrupoCarteiraTitulares = function (id, page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/grupo/" + id + "/titulares?paginate=1&page=" + page + filter).pipe();
    };
    // Recupera Grupo carteira específico
    APIService.prototype.getGrupoCarteiraTitularesNaoAtribuidos = function (id, page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/grupo/" + id + "/titulares_nao_atribuidos?paginate=1&page=" + page + filter).pipe();
    };
    // =================================================================================
    // ================================== TITULAR ======================================
    // =================================================================================
    // Recupera titulares
    APIService.prototype.getTitulares = function (page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/titular?paginate=1&page=" + page + filter).pipe();
    };
    // Cria titular
    APIService.prototype.criarTitular = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/titular", data).pipe();
    };
    // Recupera titular específico
    APIService.prototype.getTitular = function (id) {
        return this.httpClient.get(this.endpoint + "/v2/titular/" + id).pipe();
    };
    // Edita o titular
    APIService.prototype.editarTitular = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/titular/" + id, data).pipe();
    };
    // Deleta titular
    APIService.prototype.deletarTitular = function (id) {
        return this.httpClient.delete(this.endpoint + "/v2/titular/" + id).pipe();
    };
    // Bloquar/desbloquear titular
    APIService.prototype.bloqueioTitular = function (id) {
        return this.httpClient.post(this.endpoint + "/v2/titular/bloqueio/" + id, id).toPromise();
    };
    // =================================================================================
    // ============================= CARTEIRA TITULAR ==================================
    // =================================================================================
    // Atribui titulares a carteira
    APIService.prototype.atribuirTitularCarteira = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/nova-carteira/atribuir", data).pipe();
    };
    // Atribui titulares a carteira
    APIService.prototype.atribuirTitularCarteiraSemCampos = function (data) {
        return this.httpClient.post(this.endpoint + "/v2/nova-carteira/atribuir_titulares", data).pipe();
    };
    // Editacampos dos titulares
    APIService.prototype.editarCamposTitularCarteira = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/titular/carteira/" + id + "/editar_campos", data).pipe();
    };
    // status titular carteira
    APIService.prototype.statusTitularCarteira = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/titular/carteira/" + id + "/ativar_desativar", data).toPromise();
    };
    // Recupera titulares carteira
    APIService.prototype.getCarteiraTitularesAtribuidos = function (id, page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/titular/carteira/" + id + "?paginate=1&page=" + page + filter).pipe();
    };
    // Recupera titulares carteira não atribuidos
    APIService.prototype.getCarteiraTitularesNaoAtribuidos = function (id, page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/titular/carteira/" + id + "/nao_atribuidos?paginate=1&page=" + page + filter).pipe();
    };
    // Recupera titulares campos
    APIService.prototype.getCarteiraTitularesCampos = function (id, page, filter) {
        return this.httpClient.get(this.endpoint + "/v2/titular/carteira/" + id + "/campos_carteiras?paginate=1&page=" + page + filter).pipe();
    };
    // Remover vínculo de titular com carteira
    APIService.prototype.desatribuirTitularCarteira = function (id_carteira, id_titular) {
        return this.httpClient.post(this.endpoint + "/v2/carteiras/" + id_carteira + "/desatribuir/" + id_titular, {}).toPromise();
    };
    // =================================================================================
    // ======================== TITULARES +++++++++++++=================================
    // =================================================================================
    APIService.prototype.titularesIndex = function (page) {
        if (page === void 0) { page = 1; }
        return this.httpClient
            .get(this.endpoint + "/v2/titular?paginate=1&page=" + page)
            .toPromise();
    };
    APIService.prototype.titularCreate = function (data) {
        return this.httpClient
            .post(this.endpoint + "/v2/titular", data)
            .toPromise();
    };
    APIService.prototype.titularEdit = function (data, id) {
        return this.httpClient.post(this.endpoint + "/v2/titular/" + id, data)
            .pipe();
    };
    APIService.prototype.titularDelete = function (id) {
        return this.httpClient
            .delete(this.endpoint + "/v2/titular/" + id)
            .toPromise();
    };
    // =================================================================================
    // ==========      OPERAÇÕES DE RECUPERAR SENHA       ==============================
    // =================================================================================
    APIService.prototype.enviaEmail = function (email) {
        return this.httpClient.get(this.endpoint + "/recuperar_senha/disparar_email/" + email).pipe();
    };
    APIService.prototype.validarToken = function (data) {
        return this.httpClient.post(this.endpoint + "/recuperar_senha/validar_token", data).pipe();
    };
    APIService.prototype.alterarSenha = function (data) {
        return this.httpClient.post(this.endpoint + "/recuperar_senha/alterar_senha", data).pipe();
    };
    // =================================================================================
    // ==========          OPERAÇÕES DE EVENTOS           ==============================
    // =================================================================================
    // get all
    APIService.prototype.getEventos = function (page) {
        return this.httpClient.get(this.endpoint + "/eventos?page=" + page + "&apenas_pais=1").pipe();
    };
    // show
    APIService.prototype.getEvento = function (id) {
        return this.httpClient.get(this.endpoint + "/eventos/" + id).pipe();
    };
    // store
    APIService.prototype.addEvento = function (data) {
        return this.httpClient.post(this.endpoint + "/eventos", data).pipe();
    };
    // delete
    APIService.prototype.removeEvento = function (id) {
        return this.httpClient.delete(this.endpoint + "/eventos/" + id).pipe();
    };
    // update
    APIService.prototype.updateEvento = function (id, data) {
        return this.httpClient.post(this.endpoint + "/eventos/" + id, data).pipe();
    };
    APIService.prototype.alterarVisibilidadeEvento = function (id, estado) {
        return this.httpClient.post(this.endpoint + "/eventos/" + id + "/visibilidade", { visivel: estado }).pipe(tap(function (_) { return console.log("alter eventos visibility id=" + id + " to estate=" + estado); }));
    };
    // participantes
    APIService.prototype.getParticipantes = function (id, pagina) {
        return this.httpClient.get(this.endpoint + "/eventos/" + id + "/participantes?page=" + pagina + "}").pipe();
    };
    APIService.prototype.downloadParticipantes = function (eventoId) {
        return this.httpClient.get(this.endpoint + "/eventos/" + eventoId + "/relatorio_inscricoes", { responseType: "blob" });
    };
    // =================================================================================
    // =========================     POLOS     =========================================
    // =================================================================================
    APIService.prototype.getPolos = function (eventId) {
        return this.httpClient.get(this.endpoint + "/eventos/" + eventId + "/polos").pipe();
    };
    APIService.prototype.addPolo = function (eventId, data) {
        return this.httpClient.post(this.endpoint + "/eventos/" + eventId + "/polos", data).pipe();
    };
    APIService.prototype.showPolo = function (eventId, poloId) {
        return this.httpClient.get(this.endpoint + "/eventos/" + eventId + "/polos/" + poloId).pipe();
    };
    APIService.prototype.updatePolo = function (eventId, data) {
        return this.httpClient.post(this.endpoint + "/eventos/" + eventId + "/polos", data).pipe();
    };
    APIService.prototype.removePolo = function (eventId, poloId) {
        return this.httpClient.delete(this.endpoint + "/eventos/" + eventId + "/polos/" + poloId).pipe();
    };
    // =================================================================================
    // ========================     UPLOAD IMAGENS     =================================
    // =================================================================================
    APIService.prototype.uploadImagens = function (imagens) {
        return this.httpClient.post(this.endpoint + "/v2/imagemUpload/salvar", imagens).pipe();
    };
    APIService.prototype.uploadFileCreate = function (imagem) {
        return this.httpClient
            .post(this.endpoint + "/salvar_imagem", imagem)
            .pipe(function (item) { return item; })
            .toPromise();
        ;
    };
    APIService.prototype.removeFileCreate = function (url) {
        return this.httpClient
            .delete(this.endpoint + "/deletar_imagem?url=" + url)
            .toPromise();
    };
    APIService.prototype.uploadFileUpdate = function (productId, imagem) {
        return this.httpClient
            .post(this.endpoint + "/produto/" + productId + "/salvar_imagem", imagem)
            .pipe(function (item) { return item; })
            .toPromise();
    };
    APIService.prototype.removeFileUpdate = function (productId, imageId) {
        return this.httpClient
            .delete(this.endpoint + "/produto/" + productId + "/imagem/" + imageId + "/delete")
            .toPromise();
    };
    // =================================================================================
    // ============================ TAGS ===============================================
    // =================================================================================
    APIService.prototype.tagCreate = function (data) {
        return this.httpClient
            .post(this.endpoint + "/v2/tag", data)
            .toPromise();
    };
    APIService.prototype.tagsList = function (categoriaId, page) {
        if (page === void 0) { page = 0; }
        return this.httpClient
            .get(this.endpoint + "/v2/tag?conditions=categoria_id:ilike:" + categoriaId + "&paginate=" + Number(!!page) + "&page=" + page)
            .pipe(map(function (res) { return (res.data ? res.data : res); }));
    };
    APIService.prototype.tagShow = function (tagId) {
        return this.httpClient
            .get(this.endpoint + "/v2/tag/" + tagId)
            .pipe(map(function (res) { return res; }));
    };
    APIService.prototype.tagUpdate = function (data, tagId) {
        return this.httpClient
            .put(this.endpoint + "/v2/tag/" + tagId, data)
            .toPromise();
    };
    APIService.prototype.tagDelete = function (tagId) {
        return this.httpClient
            .delete(this.endpoint + "/v2/tag/" + tagId)
            .toPromise();
    };
    APIService.prototype.getInfotag = function (infoId) {
        return this.httpClient
            .get(this.endpoint + "/v2/tag/info_tags/" + infoId)
            .pipe(map(function (res) { return (res.data ? res.data : res); }));
    };
    APIService.prototype.aplicarTag = function (data) {
        return this.httpClient
            .post(this.endpoint + "/v2/tag/aplicar", data)
            .toPromise();
    };
    APIService.prototype.removerTag = function (tagId) {
        return this.httpClient
            .delete(this.endpoint + "/v2/tag/remover/" + tagId)
            .toPromise();
    };
    APIService.prototype.getSolicitations = function () {
        return this.httpClient
            .get(this.endpoint + "/v2/solicitacao_emprestimo")
            .toPromise();
    };
    APIService.prototype.updateSolicitation = function (_a) {
        var id = _a.id, status = _a.status, justify = _a.justify;
        return this.httpClient
            .put(this.endpoint + "/v2/solicitacao_emprestimo/" + id, tslib_1.__assign({ status: status }, (justify ? { justificativa_reprovacao: justify } : {})))
            .toPromise();
    };
    APIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIService_Factory() { return new APIService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DomainService)); }, token: APIService, providedIn: "root" });
    return APIService;
}());
export { APIService };
