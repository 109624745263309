
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
<div class="row">
  <small class="text-center col-md-12">
    <b>  {{ translate.instant("protocolos.atribuirCategoria") }} - {{protocolo.id}}</b><br><hr>
  </small>
</div>
<div mat-dialog-content class="text-center">
  <span>{{ translate.instant("protocolos.selecioneCategoria") }}</span>
  <br><hr>
  <mat-form-field *ngIf="categorias"  class="col-sm-12">
    <mat-select placeholder="Categoria" [(value)]="categoriaSelecionada" (selectionChange)="selecionaCategoria($event)" required>
      <ng-container *ngFor="let categoria of categorias">
        <mat-option [value]="categoria" *ngIf="categoria.visivel && categoria.integracao == null">
          {{categoria.area_categoria.titulo}} -> {{categoria.titulo}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="subCategorias"  class="col-sm-12">
    <mat-select placeholder="Sub Categoria" [(value)]="subCategoriaSelecionada" (selectionChange)="selecionaSubCategoria($event)" required>
      <mat-option *ngFor="let subCategoria of subCategorias" [value]="subCategoria">
        {{subCategoria.titulo}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions text-center>
  <button class="col-md-12" mat-raised-button color="primary" (click)="transferirAtendimento()" [disabled]="!liberarSubmicao"> {{ translate.instant("protocolos.atribuirCategoria") }} </button>
</div>