import { OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from "../../../api.service";
import { State } from "../state.service";
import { TranslateService } from "@ngx-translate/core";
var ValidarTokenComponent = /** @class */ (function () {
    function ValidarTokenComponent(flashMessagesService, apiService, state, translate) {
        var _this = this;
        this.flashMessagesService = flashMessagesService;
        this.apiService = apiService;
        this.state = state;
        this.translate = translate;
        this.form = { token: "" };
        this.alterarSenha = false;
        this.loading = false;
        this.success = function () {
            _this.alterarSenha = true;
            _this.state.set_token(_this.form.token);
        };
        this.error = function () {
            _this.lancarMensagem(_this.translate.instant("login.tokenInvalido"), "alert-danger");
            _this.loading = false;
        };
        this.done = function () {
            _this.loading = false;
        };
    }
    ValidarTokenComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.lancarMensagem(_this.translate.instant("login.tokenEnviado"), "alert-info");
        }, 0);
    };
    ValidarTokenComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    ValidarTokenComponent.prototype.onSubmit = function () {
        if (this.form.token) {
            this.loading = true;
            this.apiService
                .validarToken({ token: this.form.token })
                .subscribe(this.success, this.error, this.done);
        }
        else {
            this.lancarMensagem(this.translate.instant("login.tokenObrigatorio"), "alert-danger");
        }
    };
    return ValidarTokenComponent;
}());
export { ValidarTokenComponent };
