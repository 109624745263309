import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { APIService } from '../../../api.service';
import { TranslateService } from "@ngx-translate/core"
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-consulta-por-cpf-modal',
  templateUrl: './consulta-por-cpf-modal.component.html',
  styleUrls: ['./consulta-por-cpf-modal.component.css']
})

export class consultaPorCPFModalComponent{

  public cpf = "";
  public agendamentos = [];
  public loading;
  public buscou = false;

  constructor(
    private apiService: APIService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private flashMessagesService: FlashMessagesService,
    public dialogRef: MatDialogRef<consultaPorCPFModalComponent>,
    ) {
      
    }

    buscar(cpfinput) {
      if(!cpfinput) return;

      cpfinput = this.removeCaracteres(cpfinput);

      this.loading = true;
      this.buscou = true;
      this.apiService.getAgendamentosPorCPF(cpfinput).toPromise().then(data => {
        this.loading = false;
        this.formataAgendamento(data);
      })
      .catch(err => {
        this.loading = false;
        console.log(err)
      });
    }
    public lancarMensagem(mensagem, tipo) {
      this.flashMessagesService.show(mensagem,{ cssClass: tipo });
    }
    
    formataAgendamento(dados){
      this.agendamentos = [];
      dados.forEach(cadastroAgendamento => {
        if(cadastroAgendamento && cadastroAgendamento.agendamento && cadastroAgendamento.agendamento.json_vagas_hora){
          let jsonHoras = JSON.parse(cadastroAgendamento.agendamento.json_vagas_hora);
          let horarioSelecionado = null;
          jsonHoras.forEach(horario => {
            if(horario.id_agendados) Object.values(horario.id_agendados).forEach(id_agendado => {
              if(cadastroAgendamento.id == id_agendado && !horarioSelecionado) {
                horarioSelecionado = horario.datetime.date ? horario.datetime.date : horario.datetime;
              }
            });
          });
          cadastroAgendamento[`dataAgendamentoFormatada`] = horarioSelecionado;
          this.agendamentos.push(cadastroAgendamento);
        }
      }); 
      console.log(dados);
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      console.log(charCode)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode != 45 && charCode != 46) {
        return false;
      }
      return true;
  
    }

    deletarAgendamento(agendamento) {
      if (confirm("Você deseja deletar esse cadastro ? Ação irreversivel!")) {
        this.apiService.deleteAgendarCadastro(agendamento.agendamento_id, agendamento.id).subscribe(
          ()=>{
            this.loading = false;
            this.lancarMensagem("Agendamento removido com sucesso!", "alert-success");
            this.dialogRef.close();
          }
        );
      }
    }

    removeCaracteres(valor: string) {
      return valor.replace(/\D/g, '');
    }

}


