import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Constantes } from '../../../helpers/Constantes';
import { tileLayer, latLng, Map } from 'leaflet';
import { MapaHelper } from '../../../helpers';
import { ProtocoloService } from '../protocolo-service';
import { TranslateService } from "@ngx-translate/core";
var AtendimentoDetailModalComponent = /** @class */ (function () {
    function AtendimentoDetailModalComponent(translate, router, protocoloService, dialogRef, protocolo) {
        this.translate = translate;
        this.router = router;
        this.protocoloService = protocoloService;
        this.dialogRef = dialogRef;
        this.protocolo = protocolo;
        this.mensagemEnvio = '';
        this.urlIcon = Constantes.urlIconNotificacao;
        this.icone = Constantes.iconeNotificacao;
        this.iconMarkerLeaflet = Constantes.iconMarkerLeaflet;
        this.porcentagemProtocolos = [];
        this.loadMapInd();
    }
    AtendimentoDetailModalComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    AtendimentoDetailModalComponent.prototype.ngOnInit = function () {
    };
    AtendimentoDetailModalComponent.prototype.abrirGoogleMaps = function (protocolo) {
        if (protocolo.latitude && protocolo.longitude) {
            window.open("https://maps.google.com/maps?q=" + protocolo.latitude + "," + protocolo.longitude, "_blank");
        }
    };
    AtendimentoDetailModalComponent.prototype.ngOnChanges = function (changes) {
        console.log("Chamou onchanges");
        this.loadMapInd();
    };
    AtendimentoDetailModalComponent.prototype.onMapStart = function (map) {
        this.map.invalidateSize();
    };
    AtendimentoDetailModalComponent.prototype.onMapReady = function (map) {
        var _this = this;
        this.map = map;
        this.map.invalidateSize();
        // Em alguns casos o mapa não é invalidado e fica deslocado, não existindo
        // uma ação que seja disparada nesse caso, um timmer foi a unica maneira que encontrei.
        setTimeout(function () {
            if (_this.map) {
                _this.map.invalidateSize();
            }
        }, 1000);
    };
    AtendimentoDetailModalComponent.prototype.loadMapInd = function () {
        // Seta as configurações do mapa, a sequencia é importante
        console.log("Chamou loadMapInd ", this.protocolo.latitude, this.protocolo.longitude);
        this.centerMapIndv = latLng(this.protocolo.latitude, this.protocolo.longitude);
        this.leafletOptionsIndividual = {
            layers: [
                tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
            ],
            zoom: 18,
            center: latLng(this.protocolo.latitude, this.protocolo.longitude)
        };
        if (this.protocolo.latitude) {
            this.markerIndividual = MapaHelper.createMarker(this.protocolo);
            // Invalidando o mapa para que sua alternancia seja possivel  
            if (this.map)
                this.map.invalidateSize();
        }
    };
    AtendimentoDetailModalComponent.prototype.retornaFloat = function (val) {
        return parseFloat(val);
    };
    AtendimentoDetailModalComponent.prototype.encerrarAtendimento = function (protocolo) {
        this.protocoloService.selecionarConcluirProtocolo(protocolo);
    };
    AtendimentoDetailModalComponent.prototype.getProgressBar = function (protocolo) {
        var created_at = new Date(protocolo.created_at);
        var data_atual = new Date;
        if (protocolo.atendidoAs) {
            data_atual = new Date(protocolo.atendidoAs);
        }
        var prazo_conclusao = new Date(protocolo.prazo_de_conclusao);
        var porcentagemProtocolo = ((data_atual.getTime() - created_at.getTime()) * 100) / (prazo_conclusao.getTime() - created_at.getTime());
        if (porcentagemProtocolo > 100) {
            porcentagemProtocolo = 100;
        }
        if (porcentagemProtocolo <= 0) {
            porcentagemProtocolo = 0;
        }
        this.porcentagemProtocolos[protocolo.id] = porcentagemProtocolo.toFixed(2);
    };
    return AtendimentoDetailModalComponent;
}());
export { AtendimentoDetailModalComponent };
