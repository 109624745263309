import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService } from '../../../../../api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-multiplas-fotos',
  templateUrl: './multiplas-fotos.component.html',
  styleUrls: ['./multiplas-fotos.component.scss']
})
export class MultiplasFotosComponent {
  public selectedFile;
  public listMolduras;
  public loading = true;
  constructor(
    private apiService: APIService,
    private flashMessagesService: FlashMessagesService,
    public dialogRef: MatDialogRef<MultiplasFotosComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.carregarMolduras();
  }

  carregarMolduras() {
    this.apiService.getinformacao(this.data.id).toPromise().then(
      data => {
        this.listMolduras = data['imagens'];
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  createMoldura() {
    if (this.selectedFile) {
      this.loading = true;
      const formdata: FormData = new FormData();
      formdata.append('informacao', this.data.id);
      formdata.append('imagem', this.selectedFile, 'arquivo.jpg');
      this.apiService.createFotoInformacao(formdata).toPromise().then(
      () => {
        this.lancarMensagem(this.translate.instant("categorias.imagemCriada"), 'alert-success');
        this.carregarMolduras();
      },
      (erro) => {
        this.lancarMensagemDeErro(erro['error']['error'], 'alert-danger');
        this.loading = false;
      });
    }
  }
  public lancarMensagemDeErro(mensagem, tipo) {

    const campos = Object.keys(mensagem);
    const mensagensDeErro = [];

    for (const campo of campos) {
      mensagensDeErro.push(mensagem[campo]);
    }

    this.flashMessagesService.show(mensagensDeErro.join('<br>'), { cssClass: tipo, timeout: 9900 });
  }
  deletarMoldura(id) {
    this.apiService.deleteFotoInformacao(id).toPromise().then(
      data => {
        this.getMoldura(id);
        this.lancarMensagem(this.translate.instant("categorias.imagemRemovida"), 'alert-success');
      },
      () => this.lancarErro()
    );
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem, { cssClass: tipo });
  }

  lancarErro() {
    this.lancarMensagem(this.translate.instant("categorias.errorTentarOperacao"), 'alert-danger');
  }

  private getMoldura (id) {
    this.listMolduras.map((element, index) => {
      if (element.id === id) {
        this.listMolduras.splice(index, 1);
      }
    });
  }

}
