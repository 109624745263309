import { OnInit } from "@angular/core";
var ColorSelectComponent = /** @class */ (function () {
    function ColorSelectComponent() {
    }
    ColorSelectComponent.prototype.ngOnInit = function () { };
    ColorSelectComponent.prototype.atualizouCor = function (cor) {
        console.log(cor);
        this.group.patchValue({
            cor: cor
        });
    };
    return ColorSelectComponent;
}());
export { ColorSelectComponent };
