export class Autor {

    id: number;
    name: string;
    email: string;
    fone: string;
    imagem: string;
    verificado: string;
    admin: boolean;
    bairro: string;

    constructor(id: number ,
        name: string,
        email: string,
        fone: string,
        imagem: string,
        verificado: string,
        admin: boolean,
        bairro: string){
      this.id=id;
      this.name=name;
      this.email=email;
      this.fone=fone;
      this.imagem=imagem;
      this.verificado=verificado;
      this.admin=admin;
      this.bairro=bairro;
    }

}