import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FlashMessagesModule } from 'angular2-flash-messages';
import { RecaptchaModule } from 'ng-recaptcha';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { WINDOW_PROVIDERS } from './window.providers';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { ProcoloAtendenteCategoriaDetailModalComponent } from './views/protocolo/protocolo-atendente-categoria-detail-modal/protocolo-atendente-categoria-detail-modal.component';
import { AlterarSenhaComponent } from './views/recuperar-senha/alterar-senha/alterar-senha.component';
import { RecuperarSenhaComponent } from './views/recuperar-senha/recuperar-senha.component';
import { ValidarTokenComponent } from './views/recuperar-senha/validar-token/validar-token.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from '@coreui/angular';

// Ant design ngx-zorro
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MaterialModule } from './material.module';

import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpsRequestInterceptor } from './auth/interceptor.module';

import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrMasker4Module } from 'brmasker4';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { CgsComponentsModule } from './components/cgs-components.module';
import { AsideMenuComponent } from './containers/menu/aside-menu/aside-menu.component';
import { EventService } from './event-service';
import { HelpersMudule } from './helpers/helpers.module';
import { DomainService } from './services/domain-service';
import { PagerService } from './services/pager-service.service';
import { BairrosModalComponent } from './views/bairros/bairros-list/bairros-modal/bairros-modal.component';
import { InformacoesMapaModalComponent } from './views/categoria-informacoes/informacoes/informacoes-list/informacoes-modal/informacoes-mapa-modal.component';
import { MultiplasFotosComponent } from './views/categoria-informacoes/informacoes/informacoes-list/multiplas-fotos/multiplas-fotos.component';
import { CopiarSubCategoriaModalComponent } from './views/categoria-servico/sub-categoria/sub-categoria-list/copiar-sub-modal/copiar-sub-modal.component';
import { EnqueteResultadoComponent } from './views/enquete/enquete-resultado/enquete-resultado.component';
import { CopiarCampoExtraModalComponent } from './views/inscricao/campos-extras/campos-extras-list/copiar-campos-extra-modal/copiar-campos-extra-modal.component';
import { AtendimentoChatModalComponent } from './views/protocolo/atendimento-chat-modal/atendimento-chat-modal.component';
import { AtendimentoDetailModalComponent } from './views/protocolo/atendimento-detail-modal/atendimento-detail-modal.component';
import { AtendimentoAcoesComponent } from './views/protocolo/atendimento-presencial/atendimento-acoes/atendimento-acoes.component';
import { consultaPorCPFModalComponent } from './views/protocolo/consulta-por-cpf-modal/consulta-por-cpf-modal.component';
import { novoAtendimentoModalComponent } from './views/protocolo/novo-atendimento-modal/novo-atendimento-modal.component';
import { SelectResponsavelAtendimentoComponent } from './views/protocolo/select-responsavel-atendimento/select-responsavel-atendimento.component';
import { SelectResponsavelVideoChamadaComponent } from './views/protocolo/select-responsavel-videochamada/select-responsavel-videochamada.component';
import { SelectNovaCategoriaComponent } from './views/protocolo/selet-nova-categoria/selet-nova-categoria.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

registerLocaleData(localePt, 'pt');

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxCoolDialogsModule.forRoot(),
    FlashMessagesModule.forRoot(),
    Ng2GoogleChartsModule,
    NgxLoadingModule.forRoot({}),
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    MatCardModule,
    MatChipsModule,
    MatTabsModule,
    HelpersMudule,
    CgsComponentsModule,
    AngularDualListBoxModule,
    LeafletModule.forRoot(),
    LeafletMarkerClusterModule,
    NgxAutoScrollModule,
    RecaptchaModule,
    NgZorroAntdModule,
    NzLayoutModule,
    BrMasker4Module,
    NgxMatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    AsideMenuComponent,
    AtendimentoChatModalComponent,
    ProcoloAtendenteCategoriaDetailModalComponent,
    AtendimentoAcoesComponent,
    SelectResponsavelVideoChamadaComponent,
    SelectResponsavelAtendimentoComponent,
    SelectNovaCategoriaComponent,
    InformacoesMapaModalComponent,
    BairrosModalComponent,
    CopiarSubCategoriaModalComponent,
    CopiarCampoExtraModalComponent,
    MultiplasFotosComponent,
    AtendimentoDetailModalComponent,
    EnqueteResultadoComponent,
    RecuperarSenhaComponent,
    ValidarTokenComponent,
    AlterarSenhaComponent,
    novoAtendimentoModalComponent,
    consultaPorCPFModalComponent
  ],
  entryComponents: [
    AtendimentoChatModalComponent,
    ProcoloAtendenteCategoriaDetailModalComponent,
    AtendimentoAcoesComponent,
    SelectResponsavelVideoChamadaComponent,
    SelectResponsavelAtendimentoComponent,
    SelectNovaCategoriaComponent,
    InformacoesMapaModalComponent,
    BairrosModalComponent,
    CopiarSubCategoriaModalComponent,
    CopiarCampoExtraModalComponent,
    MultiplasFotosComponent,
    AtendimentoDetailModalComponent,
    EnqueteResultadoComponent,
    novoAtendimentoModalComponent,
    consultaPorCPFModalComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt-BR'
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: []
    },
    WINDOW_PROVIDERS,
    PagerService,
    DomainService,
    EventService,
    NgxMatNativeDateModule
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
