import { Opcao } from '../../form/Opcao';

export class EnquetePost {

    public id: number;
    public titulo: string;
    public descricao: string;
    public resPrivado: boolean;
    public dataTermino: string;
    public opcoes: Array<Opcao> =[];

    constructor(titulo: string, descricao: string, resPrivado: boolean,
        dataTermino: string, opcoes: Array<Opcao>){
        this.titulo=titulo;
        this.descricao=descricao;
        this.resPrivado=resPrivado;
        this.dataTermino=dataTermino;
        this.opcoes=opcoes;
    }
    
}