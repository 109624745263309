import { Injectable, EventEmitter } from '@angular/core';
import { Protocolo } from '../../model/Protocolo';

@Injectable({
    providedIn: 'root'
})
export class ProtocoloService {

    emitirAtenderProtocolo = new EventEmitter<Protocolo>();
    emitirVisualizarProtocolosAbertos = new EventEmitter();
    emitirVisualizarMapaProtocolosAbertos = new EventEmitter();
    emitirVisualizarProtocolosAtendimento = new EventEmitter();
    emitirVisualizarMapaProtocolosAtendimento = new EventEmitter();
    emitirConcluirAtendimento = new EventEmitter();
    emitirConcluirProtocolo = new EventEmitter<Protocolo>();
    emitirRemoverListaProtocolo = new EventEmitter<Protocolo>();
    
    public selecionarConcluirProtocolo(protocolo: Protocolo) {
        this.emitirConcluirProtocolo.emit(protocolo);
    }

    public pegarConcluirProtocolo() {
        return this.emitirConcluirProtocolo;
    }

    public selecionarRemoverListagemProtocolo(protocolo) {
        this.emitirRemoverListaProtocolo.emit(protocolo);
    }

    public pegarRemoverListagemProtocolo() {
        return this.emitirRemoverListaProtocolo;
    }

    public selecionarProtocolo(protocolo: Protocolo){
        this.emitirAtenderProtocolo.emit(protocolo);
    }

    public visualizarProtocolosAbertos(){
        this.emitirVisualizarProtocolosAbertos.emit();
    }

    public visualizarMapaProtocolosAbertos(){
        this.emitirVisualizarMapaProtocolosAbertos.emit();
    }
    
    public visualizarProtocolosAtendimento(){
        this.emitirVisualizarProtocolosAtendimento.emit();
    }

    public visualizarMapaProtocolosAtendimento(){
        this.emitirVisualizarMapaProtocolosAtendimento.emit();
    }

    public concluirAtendimento(){
        this.emitirConcluirAtendimento.emit();
    }
}