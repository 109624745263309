import { tileLayer, latLng, marker, icon, popup, Map, divIcon} from 'leaflet';
import { Protocolo } from '../model';
import { Constantes } from './Constantes';

export class MapaHelper {

  public static iconMarkerLeaflet: any = Constantes.iconMarkerLeaflet;

  constructor() {
    throw new Error('Essa classe não deve ser instanciada');
  }

  static createMarkerPopup(protocolo: Protocolo) {
    return this.createMarker(protocolo)
    .bindPopup(this.getTemplate(protocolo), {autoPan: true});
  }

  static createMarkerColourPopup(protocolo: Protocolo, colour) {
    return this.createColourMarker(protocolo, colour)
    .bindPopup(this.getTemplate(protocolo), {autoPan: true});
  }

  static createMarker(protocolo: Protocolo) {
    return marker([protocolo.latitude, protocolo.longitude],
      { icon: icon(this.iconMarkerLeaflet) });
  }

  static createColourMarker(protocolo: Protocolo, colour) {

    const markerHtmlStyles = `
      background-color: ${colour};
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      left: -0.75rem;
      top: -0.75rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF
    `;

    return marker([protocolo.latitude, protocolo.longitude],
      { icon:
        divIcon({
          iconAnchor: [0, 12],
          popupAnchor: [0, -21],
          html: `<span style="${markerHtmlStyles}" />`
        })
      });
  }

  static createMapOptions(latitude: number, longitude: number, zoom: number = 16, zoomMaximo: number = 16) {
    return {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: zoomMaximo, attribution: '...' })
      ],
      zoom: zoom,
      center: latLng(latitude, longitude),
    };
  }

  static getTemplate(protocolo: Protocolo): string {
    return `
    <div class="row">
    <div class="col-sm-12">
    <div class="row">
    <div class="col-sm-12">
    <h5 class="font-weight-bold text-nowrap text-center mt-1">${protocolo.nomeFormatado}</h5>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-12">
    <strong><b>ID:</b> ${protocolo.id}</strong>
    </div>
    <div class="col-sm-12">
    <strong><b>Status:</b> ${protocolo.status.titulo}</strong>
    </div>
    <div class="col-sm-12">
    <strong><b>Categoria:</b> ${protocolo.categoriaServico.titulo}</strong>
    </div>
    <div class="col-sm-12">
    ${protocolo.imagem ? "<img src='" + protocolo.imagem + "' height='150px' width='150px'>" : ""}
    </div>
    </div>
    </div>
    </div>
    </div>
    `;
  }
  
  static getTemplateResumido(protocolo: Protocolo): string {
    return `
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <strong><b>ID:</b> ${protocolo.id}</strong>
          </div>
          <div class="col-sm-12">
            <strong><b>Status:</b> ${protocolo.status.titulo}</strong>
          </div>
          <div class="col-sm-12">
            <strong><b>Categoria:</b> ${protocolo.categoriaServico.titulo}</strong>
          </div>
  
        </div>
      </div>
    </div>
    `;
  }
}