<section *ngIf="!alterarSenha">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

  <div class="app-body recover-password-body">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
          <flash-messages class="text-center"></flash-messages><br>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="card-group">
              <div class="card recover-password-card">
                <div class="card-body">
                  <form (keydown.enter)="onSubmit()"><br><br>
                    <h1> {{ translate.instant("login.validarToken") }} </h1>
                    <p class="text-muted">
                      {{ translate.instant("login.insiraToken") }}
                    </p><br>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        name="token"
                        [(ngModel)]="form['token']"
                        placeholder="Token"
                        required>
                    </div>

                    <div class="row">
                      <div class="col-12" class="text-center" style="margin: auto"> 
                        <button
                          type="button"
                          (click)="onSubmit()"
                          class="btn btn-primary btn-md px-6 text-center"
                          style="margin: auto"> {{ translate.instant("login.validar") }} </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 align-items-center justify-content-center">
          <div class="col-2 text-center">
            <img class="logo-by-mobby" src="/assets/img/logo-mobby.png" alt="">
          </div>
          <div class="col-1 text-center">by</div>
          <div class="col-2 text-center">
            <img class="logo-grt8" src="/assets/img/logo-grt8.png" alt="">
          </div>
        </div>
      </div>
    </main>
  </div>
</section>

<section *ngIf="alterarSenha">
  <app-alterar-senha>
  </app-alterar-senha>
</section>
