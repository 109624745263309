import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
@Component({
  selector: "app-color-select",
  template: `
  <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
<label class="radio-label-padding">{{field.label}}:</label>
<input style="color:white" matInput
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      [cpOKButton]="true"
      [(colorPicker)]="field.value"
      [formControlName]="field.name"
      (colorPickerChange)="atualizouCor($event)"/>
<mat-hint></mat-hint>
<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</mat-form-field>
`,
  styles: []
})
export class ColorSelectComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
  atualizouCor(cor){
    console.log(cor);
    this.group.patchValue({
      cor:cor
    })
  }
}