import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var EventService = /** @class */ (function () {
    function EventService() {
        this.emitirChangeOnTranslator = new BehaviorSubject(String);
    }
    EventService.prototype.changeTranslateEvent = function (language) {
        this.emitirChangeOnTranslator.next(language);
    };
    EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
