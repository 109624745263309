export const environment = {
  production: true,
  api_url: {
    'localhost': 'https://sethas.api.grt8.com.br:4026',
    'sethas': 'https://sethas.api.grt8.com.br:4026',
  },
  api_url_secure: {
    'localhost': 'https://sethas.api.grt8.com.br:4026',
    'sethas': 'https://sethas.api.grt8.com.br:4026',
  },
  app_name: {
    localhost: "api.sethas",
    "192": "api.sethas",
    'sethas': "api.sethas",
  },
  prefeituraNome: {
    localhost: "Economia Solidaria - RN",
    "192": "Economia Solidaria - RN ",
    "sethas": "Economia Solidaria - RN ",
  },
  google_site_key: "6Lf2gbUUAAAAAKlDzTV4jcilyF2AFBNIaOdP3q07",
  socket_server_name: "socketcluster.mobby.app.br",
  socket_server_port: 8001,
  id_status_andamento: 8,
  id_status_recebido: 1,
  id_status_encerrado: 9,
  servidor_suporte: "http://grt8.server.grt8.com.br:8000",
  googlekey: "AIzaSyBqAPjqQ6N07aff8gvBWs8G5CaskNx_To4",
};
