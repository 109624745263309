import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from '../../../../../api.service';
import { TranslateService } from "@ngx-translate/core";
var CopiarSubCategoriaModalComponent = /** @class */ (function () {
    function CopiarSubCategoriaModalComponent(apiService, flashMessagesService, dialogRef, translate, modal, data) {
        this.apiService = apiService;
        this.flashMessagesService = flashMessagesService;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.modal = modal;
        this.data = data;
        this.loading = false;
    }
    CopiarSubCategoriaModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.getCategoriaServicos().subscribe(function (data) {
            console.log(data);
            _this.categorias = data;
        });
    };
    CopiarSubCategoriaModalComponent.prototype.pegarSubCategorias = function (id) {
        var _this = this;
        console.log("Buscar, ", id);
        this.apiService.getCategoriaServico(id).subscribe(function (data) {
            console.log(data);
            _this.subCategorias = data['sub_categoria_servicos'];
        }, function (error) {
            console.log(error);
            _this.subCategorias = null;
        });
    };
    CopiarSubCategoriaModalComponent.prototype.copiarCategorias = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sucesso, erro;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sucesso = 0;
                        erro = 0;
                        this.loading = true;
                        return [4 /*yield*/, this.subCategorias.forEach(function (subcat) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var formdata;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            formdata = new FormData();
                                            formdata.append("titulo", subcat['titulo']);
                                            formdata.append("categoriaServico", this.data.toString());
                                            formdata.append("mensagem", subcat['mensagem']);
                                            return [4 /*yield*/, this.apiService.createSubCategoriaServico(formdata).toPromise().then(function (data) {
                                                    sucesso++;
                                                }, function (error) {
                                                    erro++;
                                                })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        this.lancarMensagem(this.translate.instant("categorias.subcategoria.confirma"), 'alert-success');
                        this.modal.closeAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    CopiarSubCategoriaModalComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    CopiarSubCategoriaModalComponent.prototype.lancarMensagemDeErro = function (mensagem, tipo) {
        var campos = Object.keys(mensagem);
        var mensagensDeErro = "";
        if ((typeof mensagem) == 'string') {
            // Erros com mensagem direta
            mensagensDeErro = mensagem;
        }
        else {
            // Erros com multiplas entradas
            for (var _i = 0, campos_1 = campos; _i < campos_1.length; _i++) {
                var campo = campos_1[_i];
                mensagensDeErro += ((typeof mensagem[campo]) == 'string' ? mensagem[campo] + "<br>" : mensagem[campo][0] + "<br>");
            }
        }
        window.scrollTo(0, 0);
        this.flashMessagesService.show(mensagensDeErro, { cssClass: tipo, timeout: 7000, showCloseBtn: true, closeOnClick: true });
        this.modal.closeAll();
    };
    return CopiarSubCategoriaModalComponent;
}());
export { CopiarSubCategoriaModalComponent };
