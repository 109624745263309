import { NgModule } from "@angular/core";
import { MatButtonToggleModule, MatDialogModule, MatExpansionModule, MatSidenavModule, MatSlideToggleModule } from '@angular/material';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { QRCodeModule } from "angularx-qrcode";
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxPrintModule } from "ngx-print";
@NgModule({
  imports: [
    MatBadgeModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatRadioModule,
    MaterialFileInputModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    QRCodeModule,
    NgxPrintModule,
    MatDialogModule
  ],
  exports: [
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatRadioModule,
    MaterialFileInputModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatMenuModule,
    MatTabsModule,
    MatProgressBarModule,
    QRCodeModule,
    NgxPrintModule,
    MatDialogModule
  ]
})
export class MaterialModule {}