import { map, tap } from 'rxjs/operators';
import { APIService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../api.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, api) {
        this.http = http;
        this.api = api;
    }
    AuthenticationService.prototype.login = function (username, password, recaptcha) {
        return this.http.post(this.api.endpoint + "/login/administrativo", {
            username: username,
            password: password,
            response: recaptcha,
            grant_type: "password",
            client_secret: this.api.client_secret,
            client_id: this.api.client_id
        }).pipe(map(function (user) {
            if (user && user.access_token) {
                localStorage.setItem('currentUser', JSON.stringify(user));
            }
            return user;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        localStorage.removeItem('currentPerfil');
        return localStorage.removeItem('currentUser');
    };
    AuthenticationService.prototype.updateMyProfile = function (dados) {
        return this.http.post(this.api.endpoint + "/usuarios/perfil/alterarImagem", dados).pipe(tap(function (_) { return console.log("updated user profile"); }));
    };
    AuthenticationService.prototype.carregarPerfil = function () {
        var retorno = this.api.getPerfilUsuario();
        retorno.subscribe(function (result) {
            localStorage.setItem('currentPerfil', JSON.stringify(result));
            return result;
        });
        return retorno;
    };
    AuthenticationService.prototype.consultarPerfil = function () {
        return this.api.getPerfilUsuario();
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.APIService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
