import { Component, Inject } from '@angular/core';
import { APIService } from '../../../../api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Protocolo, PerfilBody } from '../../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core"
import { AuthenticationService } from '../../../../auth/authentication.service';

@Component({
  selector: 'app-atendimento-acoes',
  templateUrl: './atendimento-acoes.component.html',
  styleUrls: ['./atendimento-acoes.component.scss']
})
export class AtendimentoAcoesComponent {
  public mensagens: any;
  public mensagemEnvio = '';
  public selectStatus: any;
  public statusSelecionado: any;
  public loading = false;
  public dadosPerfil;
  constructor(
    private apiService: APIService,
    private router: Router,
    public dialogRef: MatDialogRef<AtendimentoAcoesComponent>,
    public translate: TranslateService,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log(data)
      this.authService.consultarPerfil().subscribe((result: PerfilBody) => {
        this.dadosPerfil = result;
        // console.log(result, "chamou perfil", this.dadosPerfil);
      });
  }
  onNoClick(): void {
    this.dialogRef.close(this.data.agendado);
  }
  action(tipo){
    this.loading = true;
    if(tipo == 'iniciar'){
      let dataAtual = new Date();
      let dados = {
        atendido_as: `${dataAtual.getFullYear()}-${("0" + (dataAtual.getMonth() + 1)).slice(-2)}-${("0" + dataAtual.getDate()).slice(-2)} ${("0" + dataAtual.getHours()).slice(-2)}:${("0" + dataAtual.getMinutes()).slice(-2)}:${("0" + dataAtual.getSeconds()).slice(-2)}`, 
        atendido_por: this.dadosPerfil['id'],
        atendido: 1,
        nao_compareceu: 0, 
      };
      if(!this.data.agendado.chamado_as) dados['chamado_as'] = `${dataAtual.getFullYear()}-${("0" + (dataAtual.getMonth() + 1)).slice(-2)}-${("0" + dataAtual.getDate()).slice(-2)} ${("0" + dataAtual.getHours()).slice(-2)}:${("0" + dataAtual.getMinutes()).slice(-2)}:${("0" + dataAtual.getSeconds()).slice(-2)}`;
      this.updateCadastro(dados);
      this.dialogRef.close();
    }
    if(tipo == 'chamar'){
      let dataAtual = new Date();
      let dados = {
        chamado_as: `${dataAtual.getFullYear()}-${("0" + (dataAtual.getMonth() + 1)).slice(-2)}-${("0" + dataAtual.getDate()).slice(-2)} ${("0" + dataAtual.getHours()).slice(-2)}:${("0" + dataAtual.getMinutes()).slice(-2)}:${("0" + dataAtual.getSeconds()).slice(-2)}`, 
        atendido_por: this.dadosPerfil['id'],
        nao_compareceu: 0, 
        atendido: 0,
      };
      this.updateCadastro(dados);
      this.dialogRef.close();
    }
    if(tipo == 'encerrar'){
      let dataAtual = new Date();
      let dados = {
        nao_compareceu: 1, 
        atendido: 0,
      };
      this.updateCadastro(dados);
      this.dialogRef.close();
    }
    if(tipo == 'remover'){
      if (confirm("Você deseja deletar esse cadastro ? Ação irreversivel!")) {
        this.apiService.deleteAgendarCadastro(this.data.agendado.agendamento_id, this.data.agendado.id).subscribe(
          ()=>{
            this.loading = false;
            this.dialogRef.close();
          }
        );
      }
    }
  }
  alterouDados(texto){
    this.loading = true;
    let dados = {
      descricao_atendimento: texto
    };
    this.updateCadastro(dados);
  }
  updateCadastro(dados){
    this.apiService.updateAgendarCadastro(this.data.agendado.agendamento_id, this.data.agendado.id, dados).subscribe(
      data => {
        console.log(data);
        this.loading = false;
      }
    )
  }
}

