import { environment } from '../../environments/environment';
var Constantes = /** @class */ (function () {
    function Constantes() {
    }
    Constantes.urlIconNotificacao = './assets/img/notificacao.svg';
    Constantes.urlIconConnecta = './assets/img/connecta.png';
    Constantes.urlIconPrefeitura = function (appname) { return environment.app_name[appname] ? ('./assets/img/brand/' + environment.app_name[appname] + '.logo.png') : null; };
    Constantes.urlIconLoginPrefeitura = function (appname) { return environment.app_name[appname] ? ('./assets/img/brand/' + environment.app_name[appname] + '.icon.png') : null; };
    Constantes.urlBrasaoPrefeitura = function (appname) { return environment.app_name[appname] ? ('./assets/img/brand/' + environment.app_name[appname] + '.brasao.png') : null; };
    Constantes.iconeNotificacao = {
        url: './assets/img/notificacao3.svg',
        scaledSize: {
            width: 40,
            height: 60
        }
    };
    Constantes.iconMarkerLeaflet = {
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/marker-icon.png',
        shadowUrl: 'assets/marker-shadow.png'
    };
    return Constantes;
}());
export { Constantes };
