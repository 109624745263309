var EnquetePost = /** @class */ (function () {
    function EnquetePost(titulo, descricao, resPrivado, dataTermino, opcoes) {
        this.opcoes = [];
        this.titulo = titulo;
        this.descricao = descricao;
        this.resPrivado = resPrivado;
        this.dataTermino = dataTermino;
        this.opcoes = opcoes;
    }
    return EnquetePost;
}());
export { EnquetePost };
