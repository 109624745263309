import { APIService } from '../../../api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Protocolo } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from 'angular2-flash-messages';
import { ProtocoloService } from '../protocolo-service';
var SelectNovaCategoriaComponent = /** @class */ (function () {
    function SelectNovaCategoriaComponent(apiService, router, dialogRef, translate, protocoloService, flashMessagesService, protocolo) {
        this.apiService = apiService;
        this.router = router;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.protocoloService = protocoloService;
        this.flashMessagesService = flashMessagesService;
        this.protocolo = protocolo;
        this.mensagemEnvio = '';
        this.loading = true;
        this.categoriaSelecionada = null;
        this.subCategoriaSelecionada = null;
        this.liberarSubmicao = false;
        this.getCategorias();
    }
    SelectNovaCategoriaComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    SelectNovaCategoriaComponent.prototype.getCategorias = function () {
        var _this = this;
        this._categorias = [];
        var retorno = this.apiService.getCategoriaServicos();
        //let retorno = this.apiService.getUsuariosSistema();
        retorno.subscribe(function (result) {
            result
                .forEach(function (categoria) {
                _this._categorias.push(categoria);
            });
            _this.loading = false;
        });
        return retorno;
    };
    Object.defineProperty(SelectNovaCategoriaComponent.prototype, "categorias", {
        get: function () {
            return this._categorias;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectNovaCategoriaComponent.prototype, "subCategorias", {
        get: function () {
            return this._subCategorias;
        },
        enumerable: true,
        configurable: true
    });
    SelectNovaCategoriaComponent.prototype.selecionaCategoria = function (event) {
        this.categoriaSelecionada = event.value;
        console.log(event.value);
        this._subCategorias = event.value.sub_categoria_servicos ? event.value.sub_categoria_servicos : [];
    };
    SelectNovaCategoriaComponent.prototype.selecionaSubCategoria = function (event) {
        this.subCategoriaSelecionada = event.value;
        this.liberarSubmicao = true;
    };
    SelectNovaCategoriaComponent.prototype.transferirAtendimento = function () {
        var _this = this;
        this.loading = true;
        if (this.categoriaSelecionada) {
            this.apiService.updateProtocoloCategoria(this.protocolo.id, this.categoriaSelecionada.id, this.subCategoriaSelecionada.id).subscribe(function (data) {
                _this.lancarMensagem(_this.translate.instant("protocolos.transferidoSucessoAtendimento"), "alert-success");
                _this.protocoloService.selecionarRemoverListagemProtocolo(_this.protocolo);
                _this.onNoClick();
                _this.loading = false;
            }, function (error) {
                _this.lancarMensagem(_this.translate.instant("protocolos.transferidoErroAtendimento"), "alert-danger");
                _this.onNoClick();
                _this.loading = false;
            });
        }
        else {
            this.lancarMensagem(this.translate.instant("protocolos.selecioneUmUsuario"), "alert-danger");
            this.loading = false;
        }
    };
    SelectNovaCategoriaComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    return SelectNovaCategoriaComponent;
}());
export { SelectNovaCategoriaComponent };
