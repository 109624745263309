import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from '../../../../../api.service';

import { TranslateService } from "@ngx-translate/core"

@Component({
    selector: 'copiar-campos-extra-modal',
    templateUrl: './copiar-campos-extra-modal.component.html',
    styleUrls: ['./copiar-campos-extra-modal.component.scss']
})

export class CopiarCampoExtraModalComponent implements OnInit {


    public grupos;
    public setores;
    public cursos;
    public campos;

    public selectedGrupo = null;
    public selectedSetor = null;
    public selectedCurso = null;

    public enabled = 'enabled:ilike:';
    public title = 'title:ilike:;';


    public subCategorias;
    public categorias;
    public categoriaServico;
    public loading = false;
    public copiarCategoriasData;
    constructor(
        private apiService: APIService,
        private flashMessagesService: FlashMessagesService,
        public dialogRef: MatDialogRef<CopiarCampoExtraModalComponent>,
        public translate: TranslateService,
        public modal: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
      this.getGrupos();
    }

    public getGrupos() {

      this.loading = true;
      this.apiService.getGrupoInscricao(this.title, this.enabled).subscribe((data: Array<object>) => {
        this.grupos = data;
        this.grupos.forEach((grupo:any) => {

        })
        this.loading = false;
      });
    }

    public selectGrupo(grupo){

      // Seleciona o grupo
      this.selectedGrupo = grupo;

      // Limpa as variáveis
      this.setores = [];
      this.cursos = [];
      this.campos = [];
      this.selectedCurso = null;
      this.selectedSetor = null;

      // Inicializa as variáveis

      if(this.selectedGrupo.instituicao){
        if(this.selectedGrupo.instituicao.setores){
          this.setores = this.selectedGrupo.instituicao.setores;
        }
      }
    }

    public selectSetor(setor){
      // Seleciona o setor
      this.selectedSetor = setor;

      // Limpa as variáveis
      this.cursos = [];
      this.campos = [];
      this.selectedCurso = null;

      // Inicializa as variáveis
      if(this.selectedGrupo.courses){
        var c = [];
        this.selectedGrupo.courses.forEach((curso:any) => {
          if(curso.setor_agendamento_id == setor.id){
            c.push(curso)
          }
        })
        this.cursos = c;
      }
    }

    public selectCurso(curso){
      // Seleciona o setor
      this.selectedCurso = curso;

      // Limpa as variáveis
      this.campos = [];

      this.loading = true;
      this.apiService.getCamposExtras(curso.id).subscribe((data: Array<object>) => {
        this.campos = data;
        this.loading = false;
      });
    }

    public mapType(type){
      var options = [
        {
          name: "Data", value: "date"
        },{
          name: "Texto", value: "string"
        },{
          name: "Numero", value: "number"
        },{
          name: "Arquivo", value: "file"
        },{
          name: "Foto", value: "media"
        },
      ];

      return options.find( o => o.value == type).name;
    }

    public async copiarCampos(){
      let sucesso = 0;
      let erro = 0;
      this.loading = true;
      this.campos.forEach( async (campo:any) => {
        const formdata: FormData = new FormData();

        formdata.append("title", campo.title);
        formdata.append("type", campo.type);
        formdata.append("required", campo.required ? '1': '0');
        formdata.append("course_id", this.data.toString());

        await this.apiService.createCampoExtra(formdata).toPromise().then(data => {

          sucesso ++;
        }, error => {

          erro ++;
        });
      })
      this.modal.closeAll();
    }

}