export class ConfiguracaoMapa {

    latitudeCidade: number;
    longitudeCidade: number;
    zoomMapaCidade: number;

    constructor(latitudeCidade: number, longitudeCidade: number, zoomMapaCidade: number)
    {
        this.latitudeCidade=latitudeCidade;
        this.longitudeCidade=longitudeCidade;
        this.zoomMapaCidade=zoomMapaCidade;
    }

}