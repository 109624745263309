import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from "angular2-flash-messages";

@Component({
  selector: "manage-images",
  templateUrl: "./manage-images.component.html",
  styleUrls: ["./manage-images.component.scss"],
})
export class ManageImagesComponent {
  @Output() saveImage = new EventEmitter<any>();
  @Output() removeImage = new EventEmitter<any>();

  public title: string;
  public imageSelected = null;
  public loading = false;

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ManageImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public images: any,
    private flashMessagesService: FlashMessagesService,
  ) {}

  public newImage(image) {
    this.saveImage.emit(image);
  }

  public deleteImage(image) {
    this.removeImage.emit(image);
  }

  onFileChanged(event) {
    this.newImage(event.target.files[0]);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem, {
      cssClass: tipo,
      timeout: 7000,
      showCloseBtn: true,
      closeOnClick: true,
    });
  }
}
