import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from "@angular/router"
import { APIService } from "../../../api.service"
import { State } from "../state.service"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})

export class AlterarSenhaComponent implements OnInit {

  public form: any = {
    newPassword: "",
    confirmPassword: ""
  }
  public loading = false

  constructor(
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private apiService: APIService,
    private state: State,
    public translate: TranslateService
  ) { }

  ngOnInit(){}

  public lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem,{ cssClass: tipo });
  }

  success = () => {
    this.loading = false
    this.state.password_was_changed()
    this.router.navigate(["/"])
  }

  error = (error) => {
    this.lancarMensagem(this.translate.instant("login.erro"), "alert-danger");
  }

  onSubmit(){
    if(this.form.newPassword && this.form.newPassword === this.form.confirmPassword){
      this.loading = true

      this.apiService.alterarSenha({
        token: this.state.get_data().token,
        email: this.state.get_data().email,
        senha: this.form.newPassword
      }).subscribe(this.success, this.error)

    }else{
      this.lancarMensagem(this.translate.instant("login.senhasCompativeis"), "alert-danger");
    }
  }

}
