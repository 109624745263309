import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxLoadingModule } from 'ngx-loading';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from './../material.module';
import { DateTimeComponent } from './date-time/date-time.component';
import { ButtonComponent, CheckboxComponent, ColorSelectComponent, DateComponent, DynamicFieldDirective, DynamicFormComponent, FeedbackComponent, FileComponent, FroalaWysiwygComponent, InputComponent, NoteDataComponent, PaginatorComponent, RadiobuttonComponent, SelectComponent, ManageImagesComponent } from './index';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    QuillModule,
    NgxLoadingModule.forRoot({}),
    MatNativeDateModule,
    MatDialogModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
  ],
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    ColorSelectComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    FeedbackComponent,
    FileComponent,
    FroalaWysiwygComponent,
    InputComponent,
    PaginatorComponent,
    RadiobuttonComponent,
    SelectComponent,
    DateComponent,
    DateTimeComponent,
    NoteDataComponent,
    ManageImagesComponent
  ],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    ColorSelectComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    FeedbackComponent,
    FileComponent,
    FroalaWysiwygComponent,
    InputComponent,
    PaginatorComponent,
    RadiobuttonComponent,
    SelectComponent,
    DateComponent,
    DateTimeComponent,
    NoteDataComponent,
    ManageImagesComponent
  ],
  entryComponents: [
    ButtonComponent,
    CheckboxComponent,
    ColorSelectComponent,
    DynamicFormComponent,
    FileComponent,
    FroalaWysiwygComponent,
    InputComponent,
    PaginatorComponent,
    RadiobuttonComponent,
    SelectComponent,
    DateComponent,
    DateTimeComponent,
    NoteDataComponent,
    FeedbackComponent,
    ManageImagesComponent
  ],
  providers: [
    NgxMatTimepickerModule
  ]
})
export class CgsComponentsModule {}