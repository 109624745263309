import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var CanActivateViaAuthGuard = /** @class */ (function () {
    function CanActivateViaAuthGuard(router) {
        this.router = router;
    }
    CanActivateViaAuthGuard.prototype.canActivate = function (next, state) {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/entrar'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    CanActivateViaAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanActivateViaAuthGuard_Factory() { return new CanActivateViaAuthGuard(i0.ɵɵinject(i1.Router)); }, token: CanActivateViaAuthGuard, providedIn: "root" });
    return CanActivateViaAuthGuard;
}());
export { CanActivateViaAuthGuard };
