import { APIService } from '../../../../api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PerfilBody } from '../../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from '../../../../auth/authentication.service';
var AtendimentoAcoesComponent = /** @class */ (function () {
    function AtendimentoAcoesComponent(apiService, router, dialogRef, translate, authService, data) {
        var _this = this;
        this.apiService = apiService;
        this.router = router;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.authService = authService;
        this.data = data;
        this.mensagemEnvio = '';
        this.loading = false;
        // console.log(data)
        this.authService.consultarPerfil().subscribe(function (result) {
            _this.dadosPerfil = result;
            // console.log(result, "chamou perfil", this.dadosPerfil);
        });
    }
    AtendimentoAcoesComponent.prototype.onNoClick = function () {
        this.dialogRef.close(this.data.agendado);
    };
    AtendimentoAcoesComponent.prototype.action = function (tipo) {
        var _this = this;
        this.loading = true;
        if (tipo == 'iniciar') {
            var dataAtual = new Date();
            var dados = {
                atendido_as: dataAtual.getFullYear() + "-" + ("0" + (dataAtual.getMonth() + 1)).slice(-2) + "-" + ("0" + dataAtual.getDate()).slice(-2) + " " + ("0" + dataAtual.getHours()).slice(-2) + ":" + ("0" + dataAtual.getMinutes()).slice(-2) + ":" + ("0" + dataAtual.getSeconds()).slice(-2),
                atendido_por: this.dadosPerfil['id'],
                atendido: 1,
                nao_compareceu: 0,
            };
            if (!this.data.agendado.chamado_as)
                dados['chamado_as'] = dataAtual.getFullYear() + "-" + ("0" + (dataAtual.getMonth() + 1)).slice(-2) + "-" + ("0" + dataAtual.getDate()).slice(-2) + " " + ("0" + dataAtual.getHours()).slice(-2) + ":" + ("0" + dataAtual.getMinutes()).slice(-2) + ":" + ("0" + dataAtual.getSeconds()).slice(-2);
            this.updateCadastro(dados);
            this.dialogRef.close();
        }
        if (tipo == 'chamar') {
            var dataAtual = new Date();
            var dados = {
                chamado_as: dataAtual.getFullYear() + "-" + ("0" + (dataAtual.getMonth() + 1)).slice(-2) + "-" + ("0" + dataAtual.getDate()).slice(-2) + " " + ("0" + dataAtual.getHours()).slice(-2) + ":" + ("0" + dataAtual.getMinutes()).slice(-2) + ":" + ("0" + dataAtual.getSeconds()).slice(-2),
                atendido_por: this.dadosPerfil['id'],
                nao_compareceu: 0,
                atendido: 0,
            };
            this.updateCadastro(dados);
            this.dialogRef.close();
        }
        if (tipo == 'encerrar') {
            var dataAtual = new Date();
            var dados = {
                nao_compareceu: 1,
                atendido: 0,
            };
            this.updateCadastro(dados);
            this.dialogRef.close();
        }
        if (tipo == 'remover') {
            if (confirm("Você deseja deletar esse cadastro ? Ação irreversivel!")) {
                this.apiService.deleteAgendarCadastro(this.data.agendado.agendamento_id, this.data.agendado.id).subscribe(function () {
                    _this.loading = false;
                    _this.dialogRef.close();
                });
            }
        }
    };
    AtendimentoAcoesComponent.prototype.alterouDados = function (texto) {
        this.loading = true;
        var dados = {
            descricao_atendimento: texto
        };
        this.updateCadastro(dados);
    };
    AtendimentoAcoesComponent.prototype.updateCadastro = function (dados) {
        var _this = this;
        this.apiService.updateAgendarCadastro(this.data.agendado.agendamento_id, this.data.agendado.id, dados).subscribe(function (data) {
            console.log(data);
            _this.loading = false;
        });
    };
    return AtendimentoAcoesComponent;
}());
export { AtendimentoAcoesComponent };
