<div mat-dialog-content class="info-mapa-modal">
    <h5>
        {{ translate.instant("categorias.localizarInformacao") }}
    </h5>
    <p>
        {{ translate.instant("categorias.movaOMapa") }}
    </p>
    <div
        id="leafletMapId"
        class="leafletmap"
    ></div>
    <button type="button" (click)="atualizarLocalizacaoMapa()" class="btn btn-primary float-right mt-3">
        {{ translate.instant("categorias.atualizar") }}
    </button>
</div>  