import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class State {
  private data = {
    email: "",
    token: "",
    changed: false
  }

  password_was_changed(){
    this.data.changed = true
  }

  set_email(email){
    this.data.email = email
  }

  set_token(token){
    this.data.token = token
  }

  get_data(){
    return this.data
  }
}
