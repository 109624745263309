import { CanActivateViaAuthGuard } from './auth/can-activate-via-auth.guard';
import { Routes } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { RecuperarSenhaComponent } from './views/recuperar-senha/recuperar-senha.component';
var ɵ0 = {
    title: 'Erro 404'
}, ɵ1 = {
    title: 'Erro 500'
}, ɵ2 = {
    title: 'Entrar'
}, ɵ3 = {
    title: 'Recuperar senha'
}, ɵ4 = {
    title: 'Registro'
}, ɵ5 = {
    title: 'Relatorios'
}, ɵ6 = {
    title: 'Relatorios'
}, ɵ7 = {
    title: 'Home'
};
export var routes = [
    {
        path: '',
        redirectTo: 'entrada',
        pathMatch: 'full',
    },
    {
        path: '404',
        component: P404Component,
        data: ɵ0
    },
    {
        path: '500',
        component: P500Component,
        data: ɵ1
    },
    {
        path: 'entrar',
        component: LoginComponent,
        data: ɵ2
    },
    {
        path: 'recuperar-senha',
        component: RecuperarSenhaComponent,
        data: ɵ3
    },
    {
        path: 'registro',
        component: RegisterComponent,
        data: ɵ4
    },
    {
        path: 'relatorios',
        loadChildren: './views/relatorios/relatorios.module#RelatoriosModule',
        data: ɵ5
    },
    {
        path: 'relatorios-web',
        component: DefaultLayoutComponent,
        canActivate: [CanActivateViaAuthGuard],
        loadChildren: './views/relatorios/relatorios.module#RelatoriosModule',
        data: ɵ6
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [CanActivateViaAuthGuard],
        data: ɵ7,
        children: [
            {
                path: 'entrada',
                loadChildren: './views/entrada/entrada.module#EntradaModule',
            },
            {
                path: 'dashboard',
                loadChildren: './views/dashboard/dashboard.module#DashboardModule',
            },
            {
                path: 'dashboard-corona',
                loadChildren: './views/dashboard-corona/dashboard-corona.module#DashboardCoronaModule',
            },
            {
                path: 'atendimento',
                loadChildren: './views/protocolo/protocolo.module#ProtocoloModule'
            },
            {
                path: '',
                loadChildren: './views/cadastro.module#CadastroModule'
            },
            {
                path: 'administrativo',
                loadChildren: './views/administrativo/administrativo.module#AdministrativoModule'
            },
            {
                path: 'modulos',
                loadChildren: './views/modulos/modulos.module#ModulosModule'
            },
        ]
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full',
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
