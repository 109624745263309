var PerfilLogado = /** @class */ (function () {
    function PerfilLogado(name, email, fone, imagem) {
        this.name = name;
        this.email = email;
        this.fone = fone;
        this.imagem = imagem;
    }
    return PerfilLogado;
}());
export { PerfilLogado };
