import { OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from '../../api.service';
import { State } from './state.service';
import { TranslateService } from "@ngx-translate/core";
var RecuperarSenhaComponent = /** @class */ (function () {
    function RecuperarSenhaComponent(flashMessagesService, apiService, state, translate) {
        var _this = this;
        this.flashMessagesService = flashMessagesService;
        this.apiService = apiService;
        this.state = state;
        this.translate = translate;
        this.form = { emailcpf: "" };
        this.chaveRecaptcha = "";
        this.enviarEmail = true;
        this.validarToken = false;
        this.loading = false;
        this.success = function (data) {
            _this.validarToken = true;
            _this.state.set_email(_this.form.emailcpf);
        };
        this.error = function (data) {
            _this.lancarMensagem(data.error.error, "alert-danger");
            _this.loading = false;
        };
        this.done = function () {
            _this.loading = false;
        };
    }
    RecuperarSenhaComponent.prototype.ngOnInit = function () { };
    RecuperarSenhaComponent.prototype.resolved = function (captchaResponse) {
        this.chaveRecaptcha = captchaResponse;
    };
    RecuperarSenhaComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    RecuperarSenhaComponent.prototype.onSubmit = function () {
        if (this.chaveRecaptcha) {
            this.loading = true;
            this
                .apiService
                .enviaEmail(this.form.emailcpf)
                .subscribe(this.success, this.error, this.done);
        }
        else {
            this.lancarMensagem(this.translate.instant("login.respondaValidacao"), "alert-danger");
        }
    };
    return RecuperarSenhaComponent;
}());
export { RecuperarSenhaComponent };
