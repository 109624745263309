import { MatDialogRef } from "@angular/material";
var FeedbackComponent = /** @class */ (function () {
    function FeedbackComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    FeedbackComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return FeedbackComponent;
}());
export { FeedbackComponent };
