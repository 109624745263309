import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, Inject } from '@angular/core';
import { Protocolo } from '../../../model';
import { Constantes } from '../../../helpers/Constantes';
import { tileLayer, latLng, marker, icon, Map } from 'leaflet';
import { MapaHelper } from '../../../helpers';
import { ProtocoloService } from '../protocolo-service';
import { TranslateService } from "@ngx-translate/core"

@Component({
  // selector: 'app-atendimento-detail-modal',
  templateUrl: './atendimento-detail-modal.component.html',
  styleUrls: ['./atendimento-detail-modal.component.css']
})

export class AtendimentoDetailModalComponent implements OnInit, OnChanges{
  public mensagens: any;
  public mensagemEnvio = '';
  public selectStatus: any;
  public statusSelecionado: any;

  public urlIcon: string = Constantes.urlIconNotificacao;
  public icone: any = Constantes.iconeNotificacao;
  public iconMarkerLeaflet: any = Constantes.iconMarkerLeaflet;
  public leafletOptionsIndividual: any;
  public markerIndividual: any;
  public centerMapIndv;
  public map: Map;
  public porcentagemProtocolos = [];

  constructor(
    public translate: TranslateService,
    private router: Router,
    private protocoloService: ProtocoloService,
    public dialogRef: MatDialogRef<AtendimentoDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public protocolo: any) {
      this.loadMapInd();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
  abrirGoogleMaps(protocolo) {
    if (protocolo.latitude && protocolo.longitude) {
      window.open(`https://maps.google.com/maps?q=${protocolo.latitude},${protocolo.longitude}`, "_blank");

    }
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log("Chamou onchanges");
    this.loadMapInd();
  }

  onMapStart(map: Map) {
    this.map.invalidateSize();
  }

  onMapReady(map: Map) {
    this.map = map;
    this.map.invalidateSize();

    // Em alguns casos o mapa não é invalidado e fica deslocado, não existindo
    // uma ação que seja disparada nesse caso, um timmer foi a unica maneira que encontrei.
    setTimeout(() => {
      if (this.map) {
        this.map.invalidateSize();
      }
    }, 1000);
  }
  loadMapInd() {
    // Seta as configurações do mapa, a sequencia é importante
    console.log("Chamou loadMapInd ", this.protocolo.latitude, this.protocolo.longitude);
    this.centerMapIndv = latLng(this.protocolo.latitude, this.protocolo.longitude);
    this.leafletOptionsIndividual = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
      ],
      zoom: 18,
      center: latLng(this.protocolo.latitude, this.protocolo.longitude)
    };
    if (this.protocolo.latitude) {
      this.markerIndividual = MapaHelper.createMarker(this.protocolo);
      // Invalidando o mapa para que sua alternancia seja possivel  
      if (this.map) this.map.invalidateSize();
    }
  }
  public retornaFloat(val) {
    return parseFloat(val);
  }
  public encerrarAtendimento(protocolo) {
    this.protocoloService.selecionarConcluirProtocolo(protocolo);
  }

  public getProgressBar(protocolo) {
    let created_at = new Date(protocolo.created_at);
    let data_atual = new Date;
    if(protocolo.atendidoAs){
      data_atual = new Date(protocolo.atendidoAs);
    }
    let prazo_conclusao = new Date(protocolo.prazo_de_conclusao);

    let porcentagemProtocolo = ((data_atual.getTime() - created_at.getTime()) * 100) / (prazo_conclusao.getTime() - created_at.getTime());

    if (porcentagemProtocolo > 100){
      porcentagemProtocolo = 100;
    }

    if (porcentagemProtocolo <= 0){
      porcentagemProtocolo = 0;
    }
    
    this.porcentagemProtocolos[protocolo.id] = porcentagemProtocolo.toFixed(2);
  }

}
