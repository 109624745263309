/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./novo-atendimento-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./novo-atendimento-modal.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/material/dialog";
var styles_novoAtendimentoModalComponent = [i0.styles];
var RenderType_novoAtendimentoModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_novoAtendimentoModalComponent, data: {} });
export { RenderType_novoAtendimentoModalComponent as RenderType_novoAtendimentoModalComponent };
export function View_novoAtendimentoModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "100%"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sanitizer.bypassSecurityTrustResourceUrl(_co.url); _ck(_v, 1, 0, currVal_0); }); }
export function View_novoAtendimentoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-novo-atendimento-modal", [], null, null, null, View_novoAtendimentoModalComponent_0, RenderType_novoAtendimentoModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.novoAtendimentoModalComponent, [i3.DomSanitizer, i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], null, null); }
var novoAtendimentoModalComponentNgFactory = i1.ɵccf("app-novo-atendimento-modal", i2.novoAtendimentoModalComponent, View_novoAtendimentoModalComponent_Host_0, {}, {}, []);
export { novoAtendimentoModalComponentNgFactory as novoAtendimentoModalComponentNgFactory };
