export class SubCategoriaProtocolo {

    id: number;
    titulo: string;
    categoriaServico: number;
    created_at: string;

    //created_at deveria ser Date()
    constructor(id: number, titulo: string, categoriaServico: number, created_at: string)
    {
        this.id=id;
        this.titulo=titulo;
        this.categoriaServico=categoriaServico;
        this.created_at=created_at;
    }

}