import { CategoriaProtocolo } from '../../model';
var CategoriaProtocoloConverter = /** @class */ (function () {
    function CategoriaProtocoloConverter() {
    }
    CategoriaProtocoloConverter.convertObject = function (categoria) {
        var objeto = new CategoriaProtocolo(categoria.id, categoria.titulo, categoria.descricao, categoria.imagem, categoria.template.id, categoria.created_at);
        return objeto;
    };
    return CategoriaProtocoloConverter;
}());
export { CategoriaProtocoloConverter };
