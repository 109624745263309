<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-12 mx-auto">
                    <br><flash-messages class="text-center"></flash-messages>
                  </div>
                </div>
                <form (keydown.enter)="onSubmit()"><br><br>
                  <h1> {{ translate.instant("login.entrar") }} </h1>
                  <p class="text-muted">{{ translate.instant("login.entreNaSuaConta") }}</p><br>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text" class="form-control" name="email" [(ngModel)]="formulario.username" [placeholder]="translate.instant('login.email')" autocomplete="username" required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" name="password" [(ngModel)]="formulario.password"
                    [placeholder]="translate.instant('login.senha')" autocomplete="current-password" required>
                  </div>

                  <a class="recover-password" href="#/recuperar-senha">
                    {{ translate.instant("login.recuperarSenha") }}
                  </a>

                  <div class="input-group mb-4">
                    <re-captcha (resolved)="resolved($event)" siteKey="{{googlesitetoken}}" style="margin: auto;"></re-captcha>
                  </div><br>

                  <div class="row">
                    <div class="col-12" class="text-center" style="margin: auto"> 
                      <button type="button" [disabled]="!chaveRecaptcha || !formulario.password || !formulario.username" (click)="onSubmit()" class="btn btn-primary btn-md px-6 text-center" style="margin: auto">
                        {{ translate.instant("login.entrar") }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card text-white bg-login py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div><br>
                  <img class="img-login-icon" src="assets/img/brand/api.sethas.brasao.png">
                  <h3 class="text-white">SETHAS - Ecosol</h3><br>
                  <h3 class="text-white">{{ translate.instant("login.painelAdministrativo") }}</h3><br>
                  <p>{{ translate.instant("login.painelResponsavel") }}</p><br>
                  <p><strong>{{ translate.instant("login.loginApenasParaAdministradores") }}</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 align-items-center justify-content-center">
        <div class="col-2 text-center">
          <img class="logo-by-mobby" src="/assets/img/logo-mobby.png" alt="">
        </div>
        <div class="col-1 text-center">by</div>
        <div class="col-2 text-center">
          <img class="logo-grt8" src="/assets/img/logo-grt8.png" alt="">
        </div>
      </div>
    </div>
  </main>
</div>
