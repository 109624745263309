import { Component, Inject } from '@angular/core';
import { APIService } from '../../../api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Protocolo, Usuario } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core"
import { FlashMessagesService } from 'angular2-flash-messages';
import { UsuarioPageBody } from '../../../model/api/UsuarioPageBody';
import { MatSelectChange } from '@angular/material';

@Component({
  selector: 'select-responsavel-videochamada-modal',
  templateUrl: './select-responsavel-videochamada.component.html',
  styleUrls: ['./select-responsavel-videochamada.component.css']
})
export class SelectResponsavelVideoChamadaComponent {
  public mensagens: any;
  public mensagemEnvio = '';
  public selectStatus: any;
  public statusSelecionado: any;
  public _usuarios;
  public loading = true;
  public usuarioSelecionado: Usuario = null;
  constructor(
    private apiService: APIService,
    private router: Router,
    public dialogRef: MatDialogRef<SelectResponsavelVideoChamadaComponent>,
    public translate: TranslateService,
    private flashMessagesService: FlashMessagesService,

    @Inject(MAT_DIALOG_DATA) public protocolo: Protocolo) {
    this.getResponsaveis();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public getResponsaveis() {
      this._usuarios = [];
      let retorno = this.apiService.getUsuariosPorCategoriaAtendimento(this.protocolo.categoriaServico.id);
      //let retorno = this.apiService.getUsuariosSistema();
      
      retorno.subscribe((result: Array<UsuarioPageBody>) => {
        console.log("Usuarios: ", result);
        result
        .forEach(usuario => {
          this._usuarios.push(usuario);
        });
        this.loading = false;
      });
      return retorno;
    
  }

  get usuarios(): Array<Usuario>{
    return [].concat(this._usuarios);
  }

  selecionarUsuario(event: MatSelectChange) {
    this.usuarioSelecionado = <Usuario>event.value;
  }

  public criarVideoChamada(){
    this.loading = true;
    if(this.usuarioSelecionado){
      this.apiService.createVideoChamada(this.protocolo.id, this.usuarioSelecionado.id).subscribe(data => {
        this.lancarMensagem("Video chamada criada com sucesso!", "alert-success");
        this.onNoClick();
        this.loading = false;
      },
      error => {
        this.lancarMensagem("Erro ao criar video chamada!", "alert-danger");
        this.onNoClick();
        this.loading = false;
      });
    } else {
      this.lancarMensagem("Selecione um usuário para continuar", "alert-danger");
      this.loading = false;
    }
  }

  public lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem,{ cssClass: tipo });  
  }
  
}

