import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var ProtocoloService = /** @class */ (function () {
    function ProtocoloService() {
        this.emitirAtenderProtocolo = new EventEmitter();
        this.emitirVisualizarProtocolosAbertos = new EventEmitter();
        this.emitirVisualizarMapaProtocolosAbertos = new EventEmitter();
        this.emitirVisualizarProtocolosAtendimento = new EventEmitter();
        this.emitirVisualizarMapaProtocolosAtendimento = new EventEmitter();
        this.emitirConcluirAtendimento = new EventEmitter();
        this.emitirConcluirProtocolo = new EventEmitter();
        this.emitirRemoverListaProtocolo = new EventEmitter();
    }
    ProtocoloService.prototype.selecionarConcluirProtocolo = function (protocolo) {
        this.emitirConcluirProtocolo.emit(protocolo);
    };
    ProtocoloService.prototype.pegarConcluirProtocolo = function () {
        return this.emitirConcluirProtocolo;
    };
    ProtocoloService.prototype.selecionarRemoverListagemProtocolo = function (protocolo) {
        this.emitirRemoverListaProtocolo.emit(protocolo);
    };
    ProtocoloService.prototype.pegarRemoverListagemProtocolo = function () {
        return this.emitirRemoverListaProtocolo;
    };
    ProtocoloService.prototype.selecionarProtocolo = function (protocolo) {
        this.emitirAtenderProtocolo.emit(protocolo);
    };
    ProtocoloService.prototype.visualizarProtocolosAbertos = function () {
        this.emitirVisualizarProtocolosAbertos.emit();
    };
    ProtocoloService.prototype.visualizarMapaProtocolosAbertos = function () {
        this.emitirVisualizarMapaProtocolosAbertos.emit();
    };
    ProtocoloService.prototype.visualizarProtocolosAtendimento = function () {
        this.emitirVisualizarProtocolosAtendimento.emit();
    };
    ProtocoloService.prototype.visualizarMapaProtocolosAtendimento = function () {
        this.emitirVisualizarMapaProtocolosAtendimento.emit();
    };
    ProtocoloService.prototype.concluirAtendimento = function () {
        this.emitirConcluirAtendimento.emit();
    };
    ProtocoloService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProtocoloService_Factory() { return new ProtocoloService(); }, token: ProtocoloService, providedIn: "root" });
    return ProtocoloService;
}());
export { ProtocoloService };
