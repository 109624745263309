
  <div class="row">
    <small class="text-center col-md-12">
      <b>  {{ translate.instant("protocolos.mensagemDoProtocolo") }} {{protocolo.id}}</b><br><hr>
    </small>
  </div>
<div mat-dialog-content>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
  <div class="scrollbody" ngx-auto-scroll>
    <div  *ngFor="let mensagem of mensagens"  [ngClass]="{'bg-info float-right' : mensagem.interno, 'bg-light float-left' : !mensagem.interno, 'w-75 shadow p-2 mb-3 rounded text-left':true}" >{{mensagem.mensagem}}
      <hr class="hr-chat-message"><div class="w-100 text-right"> <small class="text-muted">{{mensagem.created_at | dateConverter:'dd/MM/yyyy HH:mm'}}</small> </div>
    </div><br>  
  </div>
  <div class="card-footer text-muted"  *ngIf="temPermissao('protocolo.trocamensagemlistagem')">   
    <mat-form-field class="w-100">
      <textarea matInput #mensagemEnvio autofocus rows="3" maxlength="1000" placeholder="Mensagem" (keyup.enter)="enviarMensagem(mensagemEnvio.value); mensagemEnvio.value=''"></textarea>
      <mat-hint align="start"><strong> {{ translate.instant("protocolos.tecleEnterParaEnviar") }} </strong> </mat-hint>
      <mat-hint align="end">{{mensagemEnvio.value.length}} / 1000 </mat-hint>
    </mat-form-field> 
  </div>

</div>
<div mat-dialog-actions text-center>
  <hr>
  <div class="row">
    <small class="text-center col-md-12">
      <b>{{ translate.instant("protocolos.alteracaoDeStatus") }} </b><br><hr>
    </small>
  </div>
  <mat-form-field class="col-md-8">
    <mat-select [placeholder]="translate.instant('protocolos.alteracaoDeStatus')" [(value)]="statusSelecionado">
      <mat-option>{{ translate.instant("protocolos.status") }}</mat-option>
      <mat-option *ngFor="let opcoes of selectStatus" [value]="opcoes.id">{{opcoes.titulo}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button class="col-md-4" mat-raised-button color="primary" (click)="alterarStatus()"> {{ translate.instant('protocolos.alterar') }} </button>
</div>