<div mat-dialog-content class="limite-tamanho">
  <div>
    <h4 mat-dialog-title>{{enquetes.titulo}}<br>
      <small>{{enquetes.descricao}}</small><br>
      <small><b>{{ translate.instant("enquete.enquete") }}</b> </small><small *ngIf="enquetes.resPrivado"> {{ translate.instant("enquete.ocultarUsuarios") }} </small> <small *ngIf="!enquetes.resPrivado"> {{ translate.instant("enquete.visivelAosUsuarios") }} </small>
    </h4>
    <hr>
    <div *ngFor="let resultado of enquetes.opcoes">
      <b>{{ translate.instant("enquete.opcao") }}</b> {{resultado.titulo}}<small *ngIf="resultado.descricao"> ( {{resultado.descricao}} )</small><br>
      <b>{{ translate.instant("enquete.totalVotos") }}</b> {{resultado.votos}}<br>
      <b>{{ translate.instant("enquete.totalVotos") }}:</b><br><br>
      <div *ngIf="graficosProntos && datas[resultado.id] && labels[resultado.id]" class="chart-wrapper mt-6 mx-6 grafico-enquete">
        <canvas baseChart class="chart" [data]="datas[resultado.id]" [labels]="labels[resultado.id]" [options]="basicOptions"
        [chartType]="graficoTipoPie"></canvas>
      </div>
      <hr>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ translate.instant("enquete.fechar") }}</button>
</div>