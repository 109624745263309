var AgrupamentoProtocoloPost = /** @class */ (function () {
    function AgrupamentoProtocoloPost(id, responsavel, descricao, categoriaServico, status, previsaoEncerramento, dataEncerramento, protocolos) {
        //dataEncerramento: string;
        this.protocolos = [];
        this.id = id;
        this.responsavel = responsavel;
        this.descricao = descricao;
        this.categoriaServico = categoriaServico;
        this.status = status;
        this.previsaoEncerramento = previsaoEncerramento;
        //this.dataEncerramento=dataEncerramento;
        this.protocolos = protocolos;
    }
    return AgrupamentoProtocoloPost;
}());
export { AgrupamentoProtocoloPost };
