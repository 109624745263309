import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from '../../api.service'
import { State } from './state.service'
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})

export class RecuperarSenhaComponent implements OnInit {
  public form: any = { emailcpf: "" }
  private chaveRecaptcha = ""
  public enviarEmail = true
  public validarToken = false
  public loading = false

  constructor(
    private flashMessagesService: FlashMessagesService,
    private apiService: APIService,
    private state: State,
    public translate: TranslateService
  ) {}

  ngOnInit(){}

  resolved(captchaResponse: string){
    this.chaveRecaptcha = captchaResponse
  }

  public lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem,{ cssClass: tipo });
  }

  success = (data) => {
    this.validarToken = true
    this.state.set_email(this.form.emailcpf)
  }

  error = (data) => {
    this.lancarMensagem(data.error.error, "alert-danger")
    this.loading = false
  }

  done = () => {
    this.loading = false
  }

  onSubmit(){
    if(this.chaveRecaptcha){
      this.loading = true

      this
        .apiService
        .enviaEmail(this.form.emailcpf)
        .subscribe(this.success, this.error, this.done)

    }else{
      this.lancarMensagem(this.translate.instant("login.respondaValidacao"), "alert-danger");
    }
  }
}
