/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multiplas-fotos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i3 from "ngx-loading";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "./multiplas-fotos.component";
import * as i7 from "../../../../../api.service";
import * as i8 from "angular2-flash-messages/module/flash-messages.service";
import * as i9 from "@ngx-translate/core";
var styles_MultiplasFotosComponent = [i0.styles];
var RenderType_MultiplasFotosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultiplasFotosComponent, data: {} });
export { RenderType_MultiplasFotosComponent as RenderType_MultiplasFotosComponent };
function View_MultiplasFotosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-warning"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate.instant("categorias.naoExistemFotos"); _ck(_v, 1, 0, currVal_0); }); }
function View_MultiplasFotosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "col-md-3 mb-4 selfie-aprovar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-12 no-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "img-selfie"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "mt-4 text-cinza"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Criada em"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "text-cinza"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "h5", [["class", "mt-4 text-cinza"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-md btn-block btn-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletarMoldura(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.imagem, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.created_at; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.translate.instant("categorias.remover"); _ck(_v, 14, 0, currVal_2); }); }
export function View_MultiplasFotosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-loading", [], null, null, null, i2.View_NgxLoadingComponent_0, i2.RenderType_NgxLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.NgxLoadingComponent, [i3.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"] }, null), i1.ɵpod(2, { backdropBorderRadius: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 23, "div", [["class", "info-mapa-modal mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 21, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 20, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 19, "div", [["class", "card bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 18, "div", [["class", "card-body pb-2 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-6 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h5", [["class", "mt-4 text-cinza"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "col-6 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, [["fileInput", 1]], null, 0, "input", [["class", "form-control-file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary mb-2"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createMoldura() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiplasFotosComponent_1)), i1.ɵdid(24, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiplasFotosComponent_2)), i1.ɵdid(26, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _ck(_v, 2, 0, "3px"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = !_co.listMolduras; _ck(_v, 24, 0, currVal_4); var currVal_5 = _co.listMolduras; _ck(_v, 26, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.translate.instant("categorias.envieUmaFoto"); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.translate.instant("categorias.enviar"); _ck(_v, 17, 0, currVal_3); }); }
export function View_MultiplasFotosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multiplas-fotos", [], null, null, null, View_MultiplasFotosComponent_0, RenderType_MultiplasFotosComponent)), i1.ɵdid(1, 49152, null, 0, i6.MultiplasFotosComponent, [i7.APIService, i8.FlashMessagesService, i4.MatDialogRef, i9.TranslateService, i4.MAT_DIALOG_DATA], null, null)], null, null); }
var MultiplasFotosComponentNgFactory = i1.ɵccf("app-multiplas-fotos", i6.MultiplasFotosComponent, View_MultiplasFotosComponent_Host_0, {}, {}, []);
export { MultiplasFotosComponentNgFactory as MultiplasFotosComponentNgFactory };
