import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let adicionarToken = true;

        try {
            if(!(new URL(request.url).hostname).includes("grt8.com.br") 
            && !(new URL(request.url).hostname).includes("mobby.inf.br")
            && !(new URL(request.url).hostname).includes("localhost")
            && !(new URL(request.url).hostname).includes("natal.br")) adicionarToken = false;    
        } catch (error) {}

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.access_token && adicionarToken) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${currentUser.access_token}`,
                    'Accept': 'application/json',
                }
            });
        }
        return next.handle(request);
    }
}
