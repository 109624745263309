import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomainService } from './domain-service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./domain-service";
var MenuService = /** @class */ (function () {
    function MenuService(translate, domainSvc) {
        this.translate = translate;
        this.domainSvc = domainSvc;
    }
    MenuService.prototype.ngOnInit = function () {
    };
    MenuService.prototype.getText = function (key) {
        return this.translate.instant('menu.' + key);
    };
    MenuService.prototype.build = function () {
        var menu = {
            noCategorias: [],
            categorias: []
        };
        var keyMenu;
        this.user = JSON.parse(localStorage.getItem('currentPerfil'));
        menu['noCategorias'].push({
            name: this.getText('entrada'),
            url: '/entrada',
            icon: 'menu'
        });
        if (this.temPermissao('gerenciar.dashboard')) {
            menu['noCategorias'].push({
                name: this.getText('dashboard'),
                url: '/dashboard',
                icon: 'dashboard'
            });
        }
        // Atendimentos
        keyMenu = this.getText('atendimentos');
        if (this.temPermissao('gerenciar.protocolo') ||
            this.temPermissao('gerenciar.protocolotemporeal') ||
            this.temPermissao('gerenciar.videochamadas') ||
            this.temPermissao('gerenciar.atenderagendamento') ||
            this.temPermissao('gerenciar.atendenteslistagem') ||
            this.temPermissao('gerenciar.agrupamentoprotocolo')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
        }
        if (this.temPermissao('gerenciar.atendenteslistagem')) {
            menu[keyMenu].push({
                name: this.translate.instant('atendenteCategoria.atendentes_por_servico'),
                url: '/atendimento/atendente-categoria',
                icon: 'bell'
            });
        }
        if (this.temPermissao('gerenciar.protocolo')) {
            menu[keyMenu].push({
                name: this.getText('mapaAtendimentos'),
                url: '/atendimento/mapa-atendimentos',
                icon: 'bell'
            });
        }
        if (this.temPermissao('gerenciar.protocolo')) {
            menu[keyMenu].push({
                name: this.getText('aberturaDeProtocolo'),
                url: '/criar-protocolo',
                icon: 'bell'
            });
        }
        if (this.temPermissao('gerenciar.protocolo')) {
            menu[keyMenu].push({
                name: this.getText('protocolos'),
                url: '/atendimento/protocolos',
                icon: 'bell'
            });
        }
        if (this.temPermissao('gerenciar.protocolotemporeal')) {
            menu[keyMenu].push({
                name: this.getText('tempoReal'),
                url: '/atendimento/tempo-real',
                icon: 'icon-location-pin',
            });
        }
        if (this.temPermissao('gerenciar.agrupamentoprotocolo')) {
            menu[keyMenu].push({
                name: this.getText('ordensServico'),
                url: '/atendimento/ordens-servico',
                icon: 'fa fa-group'
            });
        }
        if (this.temPermissao('gerenciar.videochamadas')) {
            menu[keyMenu].push({
                name: this.getText('videochamada'),
                url: '/videochamadas',
                icon: 'fa fa-group'
            });
        }
        // agendamentos presenciais
        keyMenu = this.getText('agendamentoPresencial');
        if (this.temPermissao('gerenciar.atenderagendamento') ||
            this.temPermissao('gerenciar.controleagendamento')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
        }
        // atender agendamento
        if (this.temPermissao('gerenciar.atenderagendamento')) {
            menu[keyMenu].push({
                name: this.getText('areaDeAtendimento'),
                url: '/atendimento/presencial',
                icon: 'contacts'
            });
        }
        // controle agendamento
        if (this.temPermissao('gerenciar.controleagendamento')) {
            menu[keyMenu].push({
                name: this.getText('cadastrarAgenda'),
                url: '/administrativo/dados-agendamentos',
                icon: 'form'
            });
        }
        // inscrições
        keyMenu = this.getText('inscricoes');
        if (this.temPermissao('gerenciar.inscricoes') ||
            this.temPermissao('atender.inscricoes') ||
            this.temPermissao('gerenciar.pesquisa')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
        }
        if (this.temPermissao('atender.inscricoes')) {
            menu[keyMenu].push({
                name: this.getText('gestaoDeInscricoes'),
                url: '/inscricoes',
                icon: 'container'
            });
        }
        if (this.temPermissao('gerenciar.inscricoes')) {
            menu[keyMenu].push({
                name: this.getText('cadastrarProgramacao'),
                url: '/grupo-inscricao',
                icon: 'check-square'
            });
        }
        if (this.temPermissao('gerenciar.pesquisa')) {
            menu[keyMenu].push({
                name: this.getText('formularios'),
                url: '/pesquisas',
                icon: 'form'
            });
        }
        // Publicações
        keyMenu = this.getText('publicacoes');
        if (this.temPermissao('gerenciar.noticia') ||
            this.temPermissao('gerenciar.categorianoticia') ||
            this.temPermissao('gerenciar.evento')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
        }
        if (this.temPermissao('gerenciar.noticia')) {
            menu[keyMenu].push({
                name: this.getText('noticias'),
                url: '/noticias',
                icon: 'solution'
            });
        }
        if (this.temPermissao('gerenciar.categorianoticia')) {
            menu[keyMenu].push({
                name: this.getText('categoriaNoticia'),
                url: '/noticias/categorias',
                icon: 'form'
            });
        }
        if (this.temPermissao('gerenciar.evento')) {
            menu[keyMenu].push({
                name: this.getText('evento'),
                url: '/eventos',
                icon: 'form'
            });
        }
        // Credsolidário
        if (this.temPermissao('gerenciar.solicitacaoemprestimo')) {
            menu['categorias'].push('Credsolidário');
            menu['Credsolidário'] = [];
            menu['Credsolidário'].push({
                name: 'Credsolidário',
                url: '/credsolidario/interessados',
                icon: 'exception'
            });
        }
        //Feira Virtual
        keyMenu = this.getText('feiraVirtual');
        /*   if (this.temPermissao('gerenciar.modulos')) {
               menu['categorias'].push(keyMenu);
               menu[keyMenu] = [];
               menu[keyMenu].push({
                   name: this.getText('feiraVirtual'),
                   url: '/modulos/modulo-feirinha',
                   icon: 'shop'
               });
   
           } */
        //Vitrine
        if (this.temPermissao('gerenciar.modulos')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
            menu[keyMenu].push({
                name: this.getText('vitrine'),
                url: '/modulos/vitrine',
                icon: 'shop'
            });
        }
        // Feed
        keyMenu = this.getText('feed');
        if (this.temPermissao('gerenciar.modulos')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
            menu[keyMenu].push({
                name: this.getText('feed'),
                url: '/modulos/selfies',
                icon: 'file-image'
            });
        }
        keyMenu = this.getText('carteiraVirtual');
        if (this.temPermissao('gerenciar.tipocarteira') || this.temPermissao('gerenciar.titulares')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
            if (this.temPermissao('gerenciar.tipocarteira')) {
                menu[keyMenu].push({
                    name: this.getText('tipoCarteira'),
                    url: '/modulos/titularidade/carteira',
                    icon: 'team'
                });
                // menu[keyMenu].push({
                //     name: this.getText('grupos'),
                //     url: '/modulos/titularidade/grupo',
                // });
            }
            // Titular
            if (this.temPermissao('gerenciar.titulares')) {
                menu[keyMenu].push({
                    name: this.getText('titulares'),
                    url: '/modulos/titularidade/titulares',
                });
            }
        }
        // Cadastros
        keyMenu = this.getText('cadastros');
        if (this.temPermissao('gerenciar.informacao') ||
            this.temPermissao('gerenciar.categoriainformacao') ||
            this.temPermissao('gerenciar.categoriaservico') ||
            this.temPermissao('gerenciar.enquete') ||
            this.temPermissao('gerenciar.user') ||
            this.temPermissao('gerenciar.template') ||
            this.temPermissao('gerenciar.controleagendamento') ||
            this.temPermissao('gerenciar.gerenciainstituicoes') ||
            this.temPermissao('gerenciar.bairro')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
        }
        // categorias de informacoes
        if (this.temPermissao('gerenciar.categoriainformacao')) {
            menu[keyMenu].push({
                name: this.getText('informacoes'),
                url: '/categoriaInformacoes',
                icon: 'form'
            });
        }
        // categorias de protocolos
        if (this.temPermissao('gerenciar.categoriaservico')) {
            menu[keyMenu].push({
                name: this.getText('catalogoServicos'),
                url: '/categoriaServicos',
                icon: 'form'
            });
        }
        // area categorias de protocolos
        if (this.temPermissao('gerenciar.categoriaservico')) {
            menu[keyMenu].push({
                name: this.getText('agrupamentoDeServico'),
                url: '/areaCategoriaServicos',
                icon: 'form'
            });
        }
        // Enquetes
        if (this.temPermissao('gerenciar.enquete')) {
            menu[keyMenu].push({
                name: this.getText('enquetes'),
                url: '/enquetes',
                icon: 'form'
            });
        }
        if (this.temPermissao('gerenciar.gerenciainstituicoes')) {
            menu[keyMenu].push({
                name: this.getText('instESetores'),
                url: '/administrativo/dados-instituicoes-adm',
                icon: 'form'
            });
        }
        // Macro Regiao
        if (this.temPermissao('gerenciar.bairro')) {
            menu[keyMenu].push({
                name: this.getText('macroregioes'),
                url: '/macro-regioes',
                icon: 'form'
            });
        }
        // Bairro
        if (this.temPermissao('gerenciar.bairro')) {
            menu[keyMenu].push({
                name: this.getText('regioes'),
                url: '/bairros',
                icon: 'form'
            });
        }
        // Administrativo
        keyMenu = this.getText('administrativo');
        if (this.temPermissao('gerenciar.user') || JSON.parse(localStorage.getItem('currentPerfil'))['id'] == 4) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
        }
        // Usuarios
        if (this.temPermissao('gerenciar.user')) {
            menu[keyMenu].push({
                name: this.getText('usuarios'),
                url: '/administrativo/manage-users',
                icon: 'team'
            });
        }
        // Configuracoes
        if (JSON.parse(localStorage.getItem('currentPerfil'))['id'] == 4) {
            menu[keyMenu].push({
                name: this.getText('funcoes'),
                url: '/administrativo/roles',
                icon: 'control'
            });
            menu[keyMenu].push({
                name: this.getText('configuracoes'),
                url: '/administrativo/configs',
                icon: 'fa fa-cog'
            });
            menu[keyMenu].push({
                name: this.getText('permissoes'),
                url: '/administrativo/permissoes',
                icon: 'fa fa-cog'
            });
        }
        // Gerencia frontEnds
        if (JSON.parse(localStorage.getItem('currentPerfil'))['id'] == 4) {
            menu[keyMenu].push({
                name: this.getText('frontEnds'),
                url: '/administrativo/frontends',
                icon: 'fa fa-cog'
            });
        }
        // Relatorios
        keyMenu = this.getText('relatorios');
        if (this.temPermissao('gerenciar.configuracoes')) {
            menu['categorias'].push(keyMenu);
            menu[keyMenu] = [];
            menu[keyMenu].push({
                name: this.getText('protocolospid'),
                url: '/relatorios/protocolo/unitario',
                icon: 'dot-chart'
            });
            menu[keyMenu].push({
                name: this.getText('protocoloPeriodo'),
                url: '/relatorios/protocolo/porperiodo',
                icon: 'fa fa-list'
            });
            menu[keyMenu].push({
                name: this.getText('usuariosRegiao'),
                url: '/relatorios-web/usuarios/regiao',
                icon: 'fa fa-list'
            });
            menu[keyMenu].push({
                name: this.getText('agendamentos'),
                url: '/relatorios-web/agendamentos',
                icon: 'fa fa-list'
            });
        }
        return menu;
    };
    MenuService.prototype.temPermissao = function (slug) {
        var retorno = false;
        this.user.funcoes.some(function (role) {
            retorno = role.permissoes.filter(function (perm) { return perm.slug == slug; }).length != 0;
            if (retorno) {
                return true;
            }
        });
        return retorno;
    };
    MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.DomainService)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
