import { Component, Output, EventEmitter, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "paginacao",
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent {
  @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() pager: any;
  constructor(public translate: TranslateService) {
  }

  setPage(pagenumber: number = 1) {
    console.log("Pagina solicitada: ", pagenumber)
    this.pageEvent.emit(pagenumber)
  }
}