import { Component, Inject } from '@angular/core';
import { APIService } from '../../../api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-enquete-resultado',
  templateUrl: './enquete-resultado.component.html',
  styleUrls: ['./enquete-resultado.component.css']
})

export class EnqueteResultadoComponent {
  public enquetes: any;
  public datas = [];
  public labels = [];
  public graficosProntos = false;
  public resultadoBairros: any;
  public basicOptions = {
    maintainAspectRatio: false,
  };
  public graficoTipoPie = 'pie';
  public coresGrafico = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4',
    '#f032e6', '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#fffac8',
    '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9'];
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<EnqueteResultadoComponent>,
    @Inject(MAT_DIALOG_DATA) public dados) {
      this.resultadoBairros = dados['resultadoBairros'];
      this.enquetes = dados['data'];
      for (let [votoOpcao, arrayItens] of Object.entries(this.resultadoBairros)) {
        this.datas[votoOpcao] = Array();
        this.labels[votoOpcao] = Array();
        console.log("Entradas : ", arrayItens)
        for (let [bairro, votos] of Object.entries(this.sort_object(arrayItens))) {
          this.datas[votoOpcao].push(votos);
          this.labels[votoOpcao].push(bairro);
        } 
      }
      console.log(this.datas);
      console.log("dados: ", this.enquetes, "resultados bairro: ", this.resultadoBairros);
    this.graficosProntos = true;
    }
    onNoClick(): void {
      this.dialogRef.close();
    }
    sort_object(obj) {
      let items = Object.keys(obj).map(function(key) {
          return [key, obj[key]];
      });
      items.sort(function(first, second) {
          return second[1] - first[1];
      });
      let sorted_obj={}
      items.forEach(element => {
        sorted_obj[element[0]] = element[1]
      });
      return(sorted_obj)
  } 
}

