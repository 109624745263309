import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../auth/authentication.service';
import { OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Constantes } from '../../helpers';
import { State } from '../recuperar-senha/state.service';
import { environment } from '../../../environments/environment';
import { DomainService } from '../../services/domain-service';
import { TranslateService } from '@ngx-translate/core';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authService, route, flashMessagesService, router, recoverState, domainSvc, translate) {
        this.authService = authService;
        this.route = route;
        this.flashMessagesService = flashMessagesService;
        this.router = router;
        this.recoverState = recoverState;
        this.domainSvc = domainSvc;
        this.translate = translate;
        this.formulario = {
            username: '',
            password: ''
        };
        this.loading = false;
        this.chaveRecaptcha = '';
        this.icone = Constantes.urlIconConnecta;
        this.googlesitetoken = environment.google_site_key;
        this.iconePrefeitura = Constantes.urlIconLoginPrefeitura(domainSvc.getPrimarySubDomain());
        this.nomePrefeitura = environment.prefeituraNome[domainSvc.getPrimarySubDomain()] ? environment.prefeituraNome[domainSvc.getPrimarySubDomain()] : localStorage.getItem('nomePrefeitura');
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.authService.logout();
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        if (this.recoverState.get_data().changed) {
            this.lancarMensagem('Senha alterada com sucesso', 'alert-success');
        }
    };
    LoginComponent.prototype.resolved = function (captchaResponse) {
        this.chaveRecaptcha = captchaResponse;
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.loading = true;
        if (this.chaveRecaptcha === '') {
            this.lancarMensagem('Responda a validação contra Bots (reCaptcha)', 'alert-danger');
            this.loading = false;
        }
        else {
            this.authService.login(this.formulario.username, this.formulario.password, this.chaveRecaptcha).subscribe(function (data) {
                console.log('autenticacao data:', data);
                _this.authService.carregarPerfil().subscribe(function () {
                    return _this.router.navigate([_this.returnUrl]);
                });
                _this.loading = false;
            }, function (error) {
                console.log('carregou data erro: ', error);
                if (error['status'] === 401 || error['status'] === 403) {
                    _this.lancarMensagem(error['error']['error'], 'alert-danger');
                }
                else {
                    console.log('Erro login', error);
                    _this.lancarMensagem('Erro, tente novamente mais tarde, caso o problema persista informe aos desenvolvedores: contato@grt8.com.br', 'alert-danger');
                }
                _this.loading = false;
            });
        }
    };
    Object.defineProperty(LoginComponent.prototype, "diagnostic", {
        get: function () { return JSON.stringify(this.formulario); },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo, timeout: 7000, showCloseBtn: true, closeOnClick: true });
    };
    return LoginComponent;
}());
export { LoginComponent };
