import { EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from "angular2-flash-messages";
var ManageImagesComponent = /** @class */ (function () {
    function ManageImagesComponent(translate, dialogRef, images, flashMessagesService) {
        this.translate = translate;
        this.dialogRef = dialogRef;
        this.images = images;
        this.flashMessagesService = flashMessagesService;
        this.saveImage = new EventEmitter();
        this.removeImage = new EventEmitter();
        this.imageSelected = null;
        this.loading = false;
    }
    ManageImagesComponent.prototype.newImage = function (image) {
        this.saveImage.emit(image);
    };
    ManageImagesComponent.prototype.deleteImage = function (image) {
        this.removeImage.emit(image);
    };
    ManageImagesComponent.prototype.onFileChanged = function (event) {
        this.newImage(event.target.files[0]);
    };
    ManageImagesComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    ManageImagesComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, {
            cssClass: tipo,
            timeout: 7000,
            showCloseBtn: true,
            closeOnClick: true,
        });
    };
    return ManageImagesComponent;
}());
export { ManageImagesComponent };
