import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from "../../../api.service"
import { State } from "../state.service"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-validar-token',
  templateUrl: './validar-token.component.html',
  styleUrls: ['./validar-token.component.scss']
})

export class ValidarTokenComponent implements OnInit {

  public form: any = { token: "" }
  public alterarSenha = false
  public loading = false

  constructor(
    private flashMessagesService: FlashMessagesService,
    private apiService: APIService,
    private state: State,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.lancarMensagem(this.translate.instant("login.tokenEnviado"), "alert-info");
    }, 0)
  }

  public lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem,{ cssClass: tipo });
  }

  success = () => {
    this.alterarSenha = true
    this.state.set_token(this.form.token)
  }

  error = () => {
    this.lancarMensagem(this.translate.instant("login.tokenInvalido"), "alert-danger");
    this.loading = false
  }

  done = () => {
    this.loading = false
  }

  onSubmit(){
    if(this.form.token){
      this.loading = true

      this.apiService
        .validarToken({token: this.form.token})
        .subscribe(this.success, this.error, this.done)

    }else{
      this.lancarMensagem(this.translate.instant("login.tokenObrigatorio"), "alert-danger");
    }
  }

}
