import { Protocolo, StatusProtocolo, CategoriaProtocolo, Autor } from '../../model';
var ProtocoloConverter = /** @class */ (function () {
    function ProtocoloConverter() {
    }
    ProtocoloConverter.convertObject = function (protocolo) {
        var autor;
        if (protocolo.autor) {
            autor = new Autor(protocolo.autor.id, protocolo.autor.name, protocolo.autor.email, protocolo.autor.fone, protocolo.autor.imagem, protocolo.autor.verificado, protocolo.autor.admin, protocolo.autor.bairro);
        }
        var objeto = new Protocolo(protocolo.id, autor, protocolo.nome, protocolo.imagem, protocolo.latitude, protocolo.longitude, protocolo.mensagem, protocolo.created_at);
        var status = new StatusProtocolo(protocolo.status.id, protocolo.status.titulo);
        objeto.status = status;
        var categoria = new CategoriaProtocolo(protocolo.categoriaServico.id, protocolo.categoriaServico.titulo, protocolo.categoriaServico.descricao, protocolo.categoriaServico.imagem, protocolo.categoriaServico.template, protocolo.categoriaServico.created_at);
        objeto.categoriaServico = categoria;
        return objeto;
    };
    return ProtocoloConverter;
}());
export { ProtocoloConverter };
