
import {map, tap} from 'rxjs/operators';
import { APIService } from './../api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PerfilBody, PerfilLogado } from '../model';
import { UsuarioSistema } from '../model/UsuarioSistema';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient, private api: APIService) { }

  login(username: string, password: string, recaptcha: string) {
    return this.http.post<any>(`${this.api.endpoint}/login/administrativo`, {
      username: username,
      password: password,
      response: recaptcha,
      grant_type: "password",
      client_secret: this.api.client_secret,
      client_id: this.api.client_id
    }).pipe(map(user => {
      if (user && user.access_token) {
        localStorage.setItem('currentUser', JSON.stringify(user));
      }
      return user;
    }));
  }
  logout() {
    localStorage.removeItem('currentPerfil');
    return localStorage.removeItem('currentUser');
  }

  updateMyProfile(dados): Observable<any> {
    return this.http.post(`${this.api.endpoint}/usuarios/perfil/alterarImagem`, dados).pipe(
      tap(_ => console.log(`updated user profile`)),
    );
  }
  carregarPerfil() {
    let retorno = this.api.getPerfilUsuario();
    retorno.subscribe((result: UsuarioSistema) => {
      localStorage.setItem('currentPerfil', JSON.stringify(result));
      return result;
    });
    return retorno;
  }

  consultarPerfil() {
    return this.api.getPerfilUsuario();
  }

}
