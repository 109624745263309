var CategoriaProtocolo = /** @class */ (function () {
    //created_at deveria ser Date()
    function CategoriaProtocolo(id, titulo, descricao, imagem, template, created_at) {
        this.id = id;
        this.titulo = titulo;
        this.descricao = descricao;
        this.imagem = imagem;
        this.template = template;
        this.created_at = created_at;
    }
    return CategoriaProtocolo;
}());
export { CategoriaProtocolo };
