export class CategoriaProtocolo {

    id: number;
    titulo: string;
    descricao: string;
    imagem: string;
    template: number;
    created_at: string;

    //created_at deveria ser Date()
    constructor(id: number, titulo: string, descricao: string,
        imagem: string, template: number, created_at: string)
    {
            this.id=id;
            this.titulo=titulo;
            this.descricao=descricao;
            this.imagem=imagem;
            this.template=template;
            this.created_at=created_at;
    }

}