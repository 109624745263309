import { EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
var DynamicFormComponent = /** @class */ (function () {
    function DynamicFormComponent(fb) {
        this.fb = fb;
        this.fields = [];
        this.submit = new EventEmitter();
        this.loaded = new EventEmitter();
    }
    DynamicFormComponent.prototype.ngOnInit = function () {
        this.form = this.createControl();
        this.loaded.emit(this.form);
    };
    Object.defineProperty(DynamicFormComponent.prototype, "value", {
        get: function () {
            return this.form.value;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormComponent.prototype.createControl = function () {
        var _this = this;
        var group = this.fb.group({});
        this.fields.forEach(function (field) {
            if (field.type === "button")
                return;
            var control = _this.fb.control(field.value, _this.bindValidations(field.validations || []));
            group.addControl(field.name, control);
        });
        return group;
    };
    DynamicFormComponent.prototype.bindValidations = function (validations) {
        if (validations.length > 0) {
            var validList_1 = [];
            validations.forEach(function (valid) {
                validList_1.push(valid.validator);
            });
            return Validators.compose(validList_1);
        }
        return null;
    };
    DynamicFormComponent.prototype.onSubmit = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.form.valid) {
            this.submit.emit(this.form.value);
        }
        else {
            this.validateAllFormFields(this.form);
        }
    };
    DynamicFormComponent.prototype.validateAllFormFields = function (formGroup) {
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    };
    return DynamicFormComponent;
}());
export { DynamicFormComponent };
