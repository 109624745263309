import { AuthenticationService } from './auth/authentication.service';
import { APIService } from './api.service';
import { ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from './event-service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, api, authService, translate, eventService, document, elementRef) {
        this.router = router;
        this.api = api;
        this.authService = authService;
        this.translate = translate;
        this.eventService = eventService;
        this.document = document;
        this.elementRef = elementRef;
        var langAccept = ['pt-BR', 'es'];
        if (langAccept.includes(navigator.language)) {
            translate.setDefaultLang(navigator.language);
            this.eventService.changeTranslateEvent(navigator.language);
        }
        else {
            translate.setDefaultLang('pt-BR');
            this.eventService.changeTranslateEvent('pt-BR');
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.usuarioPossuiChaveValida().then(function (data) {
            if (!data) {
                // Token armazenado na aplicação é invalido relogar o usuario
                _this.authService.logout();
                _this.router.navigate(['/entrar']);
            }
            _this.router.events.subscribe(function (evt) {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
