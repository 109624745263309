import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from '../../../../../api.service';

import { TranslateService } from "@ngx-translate/core"

@Component({
    selector: 'copiar-sub-modal',
    templateUrl: './copiar-sub-modal.component.html',
    styleUrls: ['./copiar-sub-modal.component.scss']
})

export class CopiarSubCategoriaModalComponent implements OnInit {
    public subCategorias;
    public categorias;
    public categoriaServico;
    public loading = false;
    public copiarCategoriasData;
    constructor(
        private apiService: APIService,
        private flashMessagesService: FlashMessagesService,
        public dialogRef: MatDialogRef<CopiarSubCategoriaModalComponent>,
        public translate: TranslateService,
        public modal: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
      this.apiService.getCategoriaServicos().subscribe(
        data => {
          console.log(data);
          this.categorias = data;
        }
      )
    }

    pegarSubCategorias(id){
      console.log("Buscar, ",id)
      this.apiService.getCategoriaServico(id).subscribe(data => {
        console.log(data);
        this.subCategorias = data['sub_categoria_servicos'];
      }, error => {
        console.log(error);
        this.subCategorias = null;
      });
    }

    async copiarCategorias(){
      let sucesso = 0;
      let erro = 0;
      this.loading = true;
      await this.subCategorias.forEach( async (subcat: Array<any>) => {
        const formdata: FormData = new FormData();
        formdata.append("titulo", subcat['titulo']);
        formdata.append("categoriaServico", this.data.toString());
        formdata.append("mensagem", subcat['mensagem']);

        await this.apiService.createSubCategoriaServico(formdata).toPromise().then(data => {

          sucesso ++;
        }, error => {

          erro ++;
        });
        
      });
      this.lancarMensagem(this.translate.instant("categorias.subcategoria.confirma"), 'alert-success');
      this.modal.closeAll();
    } 

    public lancarMensagem(mensagem, tipo) {
        this.flashMessagesService.show(mensagem,{ cssClass: tipo });
    }

    public lancarMensagemDeErro(mensagem, tipo) {
      const campos = Object.keys(mensagem);
      let mensagensDeErro = "";
      if((typeof mensagem) == 'string'){
        // Erros com mensagem direta
        mensagensDeErro = mensagem;
      }else{
        // Erros com multiplas entradas
        for (const campo of campos) {
          mensagensDeErro += ((typeof mensagem[campo]) == 'string' ? mensagem[campo] + "<br>" : mensagem[campo][0] + "<br>" );
        }
      }
      window.scrollTo(0, 0);
      this.flashMessagesService.show(mensagensDeErro, { cssClass: tipo,  timeout: 7000, showCloseBtn: true, closeOnClick: true });
      this.modal.closeAll();
    }
}