<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6" text-center>
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">
            {{ this.translate.instant("error.perdido") }}
          </h4>
          <p class="text-muted">
            {{ this.translate.instant("error.rota") }}
          </p>
        </div>
        <button class="btn btn-dark btn-block" type="button">
          {{ this.translate.instant("error.retornar") }}
        </button>
      </div>
    </div>
  </div>
</div>