import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { PerfilLogado } from '../../model';
import { AuthenticationService } from '../../auth/authentication.service';
import { MenuService } from '../../services/menu-service';
import { Constantes } from '../../helpers';
import { DomainService } from '../../services/domain-service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../event-service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
var DefaultLayoutComponent = /** @class */ (function () {
    function DefaultLayoutComponent(authService, domainSvc, menuService, eventService, translate, router, location, media) {
        var _this = this;
        this.authService = authService;
        this.domainSvc = domainSvc;
        this.menuService = menuService;
        this.eventService = eventService;
        this.translate = translate;
        this.router = router;
        this.location = location;
        this.media = media;
        this.element = document.body;
        this.foto = '/assets/img/avatars/7.jpg';
        this.href = "";
        this.removeCartPages = [
            '/entrada',
            '/dashboard',
            '/atendimento/mapa-atendimentos',
            '/atendimento/tempo-real',
            '/dashboard-corona'
        ];
        this.exibirCard = true;
        this.opened = true;
        this.iconePrefeituraBrasao = Constantes.urlBrasaoPrefeitura(domainSvc.getPrimarySubDomain());
        this.iconePrefeituraLogo = Constantes.urlIconPrefeitura(domainSvc.getPrimarySubDomain());
        this.router.events.subscribe(function () {
            if (_this.location.path() != '') {
                _this.href = _this.location.path();
            }
            _this.exibirCard = _this.removeCartPages.indexOf(_this.href) == -1;
        });
    }
    DefaultLayoutComponent.prototype.onFileChanged = function (event) {
        var _this = this;
        this.selectedFile = event.target.files[0];
        if (this.selectedFile) {
            var formdata = new FormData();
            formdata.append("imagem", this.selectedFile, "arquivo.jpg");
            formdata.append("_method", "PUT");
            this.authService.updateMyProfile(formdata).toPromise().then(function (data) {
                _this.authService.carregarPerfil().subscribe();
                _this.verificarUsuario();
            }, function (erro) {
            });
        }
    };
    ;
    DefaultLayoutComponent.prototype.useLanguage = function (language) {
        var _this = this;
        this.translate.use(language).subscribe(function () {
            _this.eventService.changeTranslateEvent(language);
        });
    };
    DefaultLayoutComponent.prototype.ngAfterViewInit = function () { };
    DefaultLayoutComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.verificarUsuario();
                return [2 /*return*/];
            });
        });
    };
    DefaultLayoutComponent.prototype.verificarUsuario = function () {
        var _this = this;
        this.authService.consultarPerfil().subscribe(function (perfilUsuario) {
            var perfilUsuarioFormatado = perfilUsuario;
            _this.dadosPerfil = _this.convertObjectSession(perfilUsuarioFormatado);
            if (_this.dadosPerfil != null) {
                localStorage.setItem('currentPerfil', JSON.stringify(perfilUsuario));
                if (_this.dadosPerfil.imagem)
                    _this.foto = _this.dadosPerfil.imagem;
                _this.navItems = _this.menuService.build();
                _this.eventService.emitirChangeOnTranslator.subscribe(function (data) {
                    _this.navItems = _this.menuService.build();
                });
            }
        });
    };
    DefaultLayoutComponent.prototype.convertObjectSession = function (perfil) {
        return new PerfilLogado(perfil.name, perfil.email, perfil.fone, perfil.imagem);
    };
    return DefaultLayoutComponent;
}());
export { DefaultLayoutComponent };
