import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { APIService } from '../../../../../api.service';
import { TranslateService } from "@ngx-translate/core";
var CopiarCampoExtraModalComponent = /** @class */ (function () {
    function CopiarCampoExtraModalComponent(apiService, flashMessagesService, dialogRef, translate, modal, data) {
        this.apiService = apiService;
        this.flashMessagesService = flashMessagesService;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.modal = modal;
        this.data = data;
        this.selectedGrupo = null;
        this.selectedSetor = null;
        this.selectedCurso = null;
        this.enabled = 'enabled:ilike:';
        this.title = 'title:ilike:;';
        this.loading = false;
    }
    CopiarCampoExtraModalComponent.prototype.ngOnInit = function () {
        this.getGrupos();
    };
    CopiarCampoExtraModalComponent.prototype.getGrupos = function () {
        var _this = this;
        this.loading = true;
        this.apiService.getGrupoInscricao(this.title, this.enabled).subscribe(function (data) {
            _this.grupos = data;
            _this.grupos.forEach(function (grupo) {
            });
            _this.loading = false;
        });
    };
    CopiarCampoExtraModalComponent.prototype.selectGrupo = function (grupo) {
        // Seleciona o grupo
        this.selectedGrupo = grupo;
        // Limpa as variáveis
        this.setores = [];
        this.cursos = [];
        this.campos = [];
        this.selectedCurso = null;
        this.selectedSetor = null;
        // Inicializa as variáveis
        if (this.selectedGrupo.instituicao) {
            if (this.selectedGrupo.instituicao.setores) {
                this.setores = this.selectedGrupo.instituicao.setores;
            }
        }
    };
    CopiarCampoExtraModalComponent.prototype.selectSetor = function (setor) {
        // Seleciona o setor
        this.selectedSetor = setor;
        // Limpa as variáveis
        this.cursos = [];
        this.campos = [];
        this.selectedCurso = null;
        // Inicializa as variáveis
        if (this.selectedGrupo.courses) {
            var c = [];
            this.selectedGrupo.courses.forEach(function (curso) {
                if (curso.setor_agendamento_id == setor.id) {
                    c.push(curso);
                }
            });
            this.cursos = c;
        }
    };
    CopiarCampoExtraModalComponent.prototype.selectCurso = function (curso) {
        var _this = this;
        // Seleciona o setor
        this.selectedCurso = curso;
        // Limpa as variáveis
        this.campos = [];
        this.loading = true;
        this.apiService.getCamposExtras(curso.id).subscribe(function (data) {
            _this.campos = data;
            _this.loading = false;
        });
    };
    CopiarCampoExtraModalComponent.prototype.mapType = function (type) {
        var options = [
            {
                name: "Data", value: "date"
            }, {
                name: "Texto", value: "string"
            }, {
                name: "Numero", value: "number"
            }, {
                name: "Arquivo", value: "file"
            }, {
                name: "Foto", value: "media"
            },
        ];
        return options.find(function (o) { return o.value == type; }).name;
    };
    CopiarCampoExtraModalComponent.prototype.copiarCampos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sucesso, erro;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                sucesso = 0;
                erro = 0;
                this.loading = true;
                this.campos.forEach(function (campo) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var formdata;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                formdata = new FormData();
                                formdata.append("title", campo.title);
                                formdata.append("type", campo.type);
                                formdata.append("required", campo.required ? '1' : '0');
                                formdata.append("course_id", this.data.toString());
                                return [4 /*yield*/, this.apiService.createCampoExtra(formdata).toPromise().then(function (data) {
                                        sucesso++;
                                    }, function (error) {
                                        erro++;
                                    })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                this.modal.closeAll();
                return [2 /*return*/];
            });
        });
    };
    return CopiarCampoExtraModalComponent;
}());
export { CopiarCampoExtraModalComponent };
