import { Component, Inject } from '@angular/core';
import { APIService } from '../../../api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Protocolo, PerfilBody } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core"
import { AuthenticationService } from '../../../auth/authentication.service';
import { UsuarioSistema } from '../../../model/UsuarioSistema';

@Component({
  selector: 'app-atendimento-chat-modal',
  templateUrl: './atendimento-chat-modal.component.html',
  styleUrls: ['./atendimento-chat-modal.component.css']
})
export class AtendimentoChatModalComponent {
  public mensagens: any;
  public mensagemEnvio = '';
  public selectStatus: any;
  public statusSelecionado: any;
  public loading = false;
  user: UsuarioSistema;
  constructor(
    private apiService: APIService,
    private router: Router,
    public dialogRef: MatDialogRef<AtendimentoChatModalComponent>,
    public translate: TranslateService,
    private authService: AuthenticationService,

    @Inject(MAT_DIALOG_DATA) public protocolo: Protocolo) {
    this.atualizarMensagens();
    this.getStatus();
    this.user = JSON.parse(localStorage.getItem('currentPerfil')) as UsuarioSistema;
  }
  atualizarMensagens() {
    this.apiService.getProtocoloRespostas(this.protocolo.id).toPromise().then(
      data => {
        if (data) {
          console.log('mensagens carregadas do protocolo no modal');
          this.mensagens = data;
        }
      }
    );
  }
  public temPermissao(slug: string): boolean {
    let retorno = false;
    
    this.user.funcoes.some(role => {
        retorno = role.permissoes.filter(perm => perm.slug == slug).length != 0;
        if (retorno) {
            return true;
        }
    });
    
    return retorno;
}
  onNoClick(): void {
    this.dialogRef.close();
  }
  enviarMensagem(mensagem) {
    
    this.apiService.enviaRespostaParaProtocolo(this.protocolo.id, { mensagem: mensagem }).toPromise().then(
      data => {
        this.atualizarMensagens();

      }
    );
  }
  
  public alterarStatus(){
    this.loading = true;
    this.apiService.updateProtocolo(this.protocolo.id ,{status: this.statusSelecionado, _method:'PUT'}).toPromise().then(
      () => {
        if(!this.protocolo['atendente']){
          this.authService.consultarPerfil().subscribe((result) => {
            this.apiService.updateProtocoloAtendente(this.protocolo.id, result['id']).toPromise().then(
              () => {
                this.loading = false;    
                this.dialogRef.close();
              },
              error => {
                // Caso a API ainda não esteja atualizada
                this.loading = false;   
                this.dialogRef.close();
              }
            );
          });
        }
        else {
          this.loading = false;   
          this.dialogRef.close();
        }
      },
      () => {
    });
  }
  public getStatus() {
    this.apiService.getStatus().toPromise().then(
      data => {
        if (data) {
          this.selectStatus = data;
        }
      }
    )
  }
}

