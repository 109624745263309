import { Component, Inject } from '@angular/core';
import { APIService } from '../../../api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Protocolo, Usuario } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core"
import { FlashMessagesService } from 'angular2-flash-messages';
import { UsuarioPageBody } from '../../../model/api/UsuarioPageBody';
import { MatSelectChange } from '@angular/material';
import { ProtocoloService } from '../protocolo-service';

@Component({
  selector: 'selet-nova-categoria-modal',
  templateUrl: './selet-nova-categoria.component.html',
  styleUrls: ['./selet-nova-categoria.component.css']
})
export class SelectNovaCategoriaComponent {
  public mensagens: any;
  public mensagemEnvio = '';
  public selectStatus: any;
  public statusSelecionado: any;
  public _categorias;
  public _subCategorias;
  public loading = true;
  public categoriaSelecionada = null;
  public subCategoriaSelecionada = null;
  public liberarSubmicao = false;
  constructor(
    private apiService: APIService,
    private router: Router,
    public dialogRef: MatDialogRef<SelectNovaCategoriaComponent>,
    public translate: TranslateService,
    private protocoloService: ProtocoloService, 
    private flashMessagesService: FlashMessagesService,

    @Inject(MAT_DIALOG_DATA) public protocolo: Protocolo) {
    this.getCategorias();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public getCategorias() {
      this._categorias = [];
      let retorno = this.apiService.getCategoriaServicos();
      //let retorno = this.apiService.getUsuariosSistema();
      retorno.subscribe((result: Array<any>) => {
        result
        .forEach(categoria => {
          this._categorias.push(categoria);
        });
        this.loading = false;
      });
      return retorno;
    
  }

  get categorias(): Array<any>{
    return this._categorias;
  }
  get subCategorias(): Array<any>{
    return this._subCategorias;
  }

  selecionaCategoria(event: MatSelectChange) {
    this.categoriaSelecionada = event.value;
    console.log(event.value);
    this._subCategorias = event.value.sub_categoria_servicos ? event.value.sub_categoria_servicos : [];
  }
  selecionaSubCategoria(event: MatSelectChange) {
    this.subCategoriaSelecionada = event.value;
    this.liberarSubmicao = true;
  }

  public transferirAtendimento(){
    this.loading = true;
    if(this.categoriaSelecionada){
      this.apiService.updateProtocoloCategoria(this.protocolo.id, this.categoriaSelecionada.id, this.subCategoriaSelecionada.id).subscribe(data => {
        this.lancarMensagem(this.translate.instant("protocolos.transferidoSucessoAtendimento"), "alert-success");
        this.protocoloService.selecionarRemoverListagemProtocolo(this.protocolo);
        this.onNoClick();
        this.loading = false;
      },
      error => {
        this.lancarMensagem(this.translate.instant("protocolos.transferidoErroAtendimento"), "alert-danger");
        this.onNoClick();
        this.loading = false;
      });
    } else {
      this.lancarMensagem(this.translate.instant("protocolos.selecioneUmUsuario"), "alert-danger");
      this.loading = false;
    }
  }

  public lancarMensagem(mensagem, tipo) {
    this.flashMessagesService.show(mensagem,{ cssClass: tipo });  
  }
  
}

