import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../window.providers';

@Injectable()
export class DomainService {
    private dominio;
    constructor(@Inject(WINDOW) private window: Window) {
    }

    public getHostname() : string {
        return this.window.location.hostname;
    }
    public getPrimarySubDomain(): string {
        if(!this.dominio){
            this.dominio = this.window.location.hostname.split(".")[0];
        }
        return this.dominio;
    }
}