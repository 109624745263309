
<div mat-dialog-content>
  <mat-card class="infoProtocolo mx-1">
    <!--  -->
    <div class="row">
      <div class="col-6">
        <mat-card-header>
          <mat-icon mat-list-icon *ngIf="!protocolo.autor?.imagem" class="mat-user-item-icon float-left">account_circle</mat-icon>
          <!--<img mat-card-avatar [src]="icone.url" class="mr-1" *ngIf="!protocolo.autor?.imagem">-->
          <img mat-card-avatar [src]="protocolo.autor.imagem" *ngIf="protocolo.autor?.imagem" />
          
          <mat-card-title class="font-weight-bold">{{protocolo.autor?.name}}{{protocolo.nome}}</mat-card-title>
          <mat-card-subtitle class="badge badge-dark text-white">{{protocolo.autor?.email}}</mat-card-subtitle>
          <mat-card-subtitle class="badge badge-dark text-white">{{protocolo.autor?.fone}}</mat-card-subtitle>
        </mat-card-header>
      </div>
      <div class="col-6">
        <mat-card-header>
          <mat-card-subtitle *ngIf="protocolo.categoriaServico"><small><b> {{ translate.instant("protocolos.status") }}: </b></small>{{protocolo.status.titulo}}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="protocolo.categoriaServico"><small><b> {{ translate.instant("protocolos.categoria") }}: </b></small>{{protocolo.categoriaServico.titulo}}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="protocolo['subCategoriaServico']"><small><b> {{ translate.instant("protocolos.subcategoria") }}: </b></small>{{protocolo['subCategoriaServico'].titulo}}</mat-card-subtitle>
          <mat-card-subtitle primary *ngIf="protocolo.placaVeiculo && protocolo.placaVeiculo != 'null'"><small><b>{{ translate.instant("protocolos.placa") }}: </b></small>{{protocolo.placaVeiculo}}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="protocolo['prazo_de_conclusao']">
            <small><b> {{ translate.instant("protocolos.prazoUtilizado") }}: </b></small>
            <span>{{porcentagemProtocolos[protocolo.id]}}%</span><br>
            <div *ngIf="porcentagemProtocolos[protocolo.id] || getProgressBar(protocolo)">&nbsp;
              <!-- " class="progress-bar mt-1 text" role="progressbar" [ngStyle]="{'width': porcentagemProtocolos[protocolo.id] + '%'}"  attr.aria-valuenow="{{porcentagemProtocolos[protocolo.id]}}" aria-valuemin="0" aria-valuemax="100" 
              [ngClass]="{'bg-success': porcentagemProtocolos[protocolo.id] <= 30,
                'bg-warning': porcentagemProtocolos[protocolo.id] > 30 && porcentagemProtocolos[protocolo.id] <= 60,
                'bg-danger': porcentagemProtocolos[protocolo.id] > 60 && porcentagemProtocolos[protocolo.id] <= 100} -->
            </div>
          </mat-card-subtitle>
        </mat-card-header>
      </div>
    </div>
    
    <mat-card-content>
      <div class="row">
        <div class="col-12">
          <button type="button" (click)="abrirGoogleMaps(protocolo)" class="btn btn-outline-success btn-sm float-right"
          style="font-size: 12px;">
          <i class="material-icons" style="font-size: 15px;">
            map
          </i> {{ translate.instant("protocolos.abrirNoMapa") }}
        </button>
      </div>
    </div>
    <div class="row" *ngIf="protocolo['identificador'] || protocolo.endereco">
      <div class="col-12" *ngIf="protocolo.mensagem">
        {{ translate.instant("protocolos.mensagemAbertura") }} <small>: {{protocolo['mensagem']}}</small>
      </div>
      <div class="col-12" *ngIf="protocolo['identificador']">
        {{ protocolo['categoriaServico']['nomeIdentificador'] }} <small>({{ translate.instant("protocolos.campoExtra") }}): {{protocolo['identificador']}}</small>
      </div>
      <div class="col-12" *ngIf="protocolo.endereco">
        {{ translate.instant("protocolos.endereco") }} <small>({{ translate.instant("protocolos.campoExtra") }}): {{protocolo['endereco']}}</small>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6 text-center">
        <img *ngIf="protocolo.imagem" class="max-size-picture" [src]="protocolo.imagem" alt="Imagem do protocolo">
        <div *ngIf="!protocolo.imagem" class="text-center falta-mapa">
          <i class="material-icons">
            grid_off
          </i>
          <br> <small> {{ translate.instant("protocolos.semImagem") }} </small>
        </div>
      </div>
      <div class="col-md-6">
        <div leaflet class="leafletmap" (leafletMapMoveStart)="onMapStart($event)" (leafletMapReady)="onMapReady($event)"
        *ngIf="protocolo.latitude && leafletOptionsIndividual" [(leafletCenter)]="centerMapIndv" [leafletLayer]="markerIndividual"
        [leafletOptions]="leafletOptionsIndividual">
      </div>
    </div>
  </div>
</mat-card-content>
<mat-card-footer>
  <p class="card-text pull-left mt-1"><small class="text-muted"> {{ translate.instant("protocolos.protocolo") }} {{protocolo.id}}</small></p>
  <p class="card-text pull-right mt-1"><small class="text-muted"> {{ translate.instant("protocolos.criadoEm") }} {{protocolo.created_at |
    dateConverter:'dd/MM/yyyy HH:mm'}}</small></p>
  </mat-card-footer>
</mat-card>
</div>  