import { OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from "@angular/router";
import { APIService } from "../../../api.service";
import { State } from "../state.service";
import { TranslateService } from "@ngx-translate/core";
var AlterarSenhaComponent = /** @class */ (function () {
    function AlterarSenhaComponent(flashMessagesService, router, apiService, state, translate) {
        var _this = this;
        this.flashMessagesService = flashMessagesService;
        this.router = router;
        this.apiService = apiService;
        this.state = state;
        this.translate = translate;
        this.form = {
            newPassword: "",
            confirmPassword: ""
        };
        this.loading = false;
        this.success = function () {
            _this.loading = false;
            _this.state.password_was_changed();
            _this.router.navigate(["/"]);
        };
        this.error = function (error) {
            _this.lancarMensagem(_this.translate.instant("login.erro"), "alert-danger");
        };
    }
    AlterarSenhaComponent.prototype.ngOnInit = function () { };
    AlterarSenhaComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    AlterarSenhaComponent.prototype.onSubmit = function () {
        if (this.form.newPassword && this.form.newPassword === this.form.confirmPassword) {
            this.loading = true;
            this.apiService.alterarSenha({
                token: this.state.get_data().token,
                email: this.state.get_data().email,
                senha: this.form.newPassword
            }).subscribe(this.success, this.error);
        }
        else {
            this.lancarMensagem(this.translate.instant("login.senhasCompativeis"), "alert-danger");
        }
    };
    return AlterarSenhaComponent;
}());
export { AlterarSenhaComponent };
