import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateConverter'
})
export class DateConverterPipe extends DatePipe implements PipeTransform {

  transform(value: any, format = 'mediumDate', timezone?: string, locale?: string): string|null {
    if (value == null || value === '' || value !== value) return null;

    let toConvert = value;
    if(DateConverterPipe.isTextoFormatado(value)){
      toConvert = DateConverterPipe.textoParaData(value);
    }
    return super.transform(toConvert, format, locale || locale, timezone);
  }

  static textoParaData(texto): Date{
    //21/11/2018 - 11:34
    if(/^\d{2}\/\d{2}\/\d{4}\s-\s\d{2}:\d{2}$/.test(texto)){
      let _dateTime = texto.split('-')
      let _data = _dateTime[0].trim().split('/')
      let _tempo = _dateTime[1].trim().split(':')
      return new Date(_data[2], _data[1]-1, _data[0], _tempo[0], _tempo[1]);
    }
  }

  static isTextoFormatado(texto): boolean{
    //21/11/2018 - 11:34
    return (/^\d{2}\/\d{2}\/\d{4}\s-\s\d{2}:\d{2}$/.test(texto));
  }


  static toStringFormat(date: Date): string{
    //dd/mm/yyyy - hh:MM
    return date!=null ?  `${DateConverterPipe.leftPad(date.getDate())}/${DateConverterPipe.leftPad(date.getMonth()+1)}/${date.getFullYear()} - ${DateConverterPipe.leftPad(date.getHours())}:${DateConverterPipe.leftPad(date.getMinutes())}` :'';
  }

  static stringFormat(date: string): string{
    //dd/mm/yyyy hh:MM
    return DateConverterPipe.toStringFormat(new Date(date))
    //return date!=null ?  `${date.split(' ')[0].split('-').reverse().join('/')} ${date.split(' ')[1].split('-').reverse().join('/')}` :'';
  }

  static stringConverte(date: string): string{
    //dd/mm/yyyy hh:MM
    return date!=null ?  `${date.split(' - ')[0].split('/').reverse().join('-')}T${date.split(' - ')[1]}` :'';
  }

  static stringConverteSimple(date: string): string{
    console.log(date);    
    //dd/mm/yyyy
    return date!=null ?  `${date.split('-').reverse().join('/')}` :'';
  }

  static leftPad(numero: number): string{
    return numero > 9 ?  `${numero}` : `0${numero}`;
  }


}
