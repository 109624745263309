
import { environment } from '../../environments/environment'
export class Constantes {

  static urlIconNotificacao: string = './assets/img/notificacao.svg';
  static urlIconConnecta: string = './assets/img/connecta.png';

  static urlIconPrefeitura = function(appname){ return environment.app_name[appname] ? ('./assets/img/brand/' + environment.app_name[appname] + '.logo.png') : null };
  static urlIconLoginPrefeitura = function(appname){ return environment.app_name[appname] ? ('./assets/img/brand/' + environment.app_name[appname] + '.icon.png') : null };
  static urlBrasaoPrefeitura = function(appname){ return environment.app_name[appname] ? ('./assets/img/brand/' + environment.app_name[appname] + '.brasao.png') : null };
  
  
  static iconeNotificacao: any = {
    url: './assets/img/notificacao3.svg',
    scaledSize: {
      width: 40,
      height: 60
    }
  };

  static iconMarkerLeaflet = {
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: 'assets/marker-icon.png',
    shadowUrl: 'assets/marker-shadow.png'
  };
}
