import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../auth/authentication.service';
import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Constantes } from '../../helpers';
import { State } from '../recuperar-senha/state.service';
import { environment } from '../../../environments/environment';
import { DomainService } from '../../services/domain-service';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public formulario = {
    username: '',
    password: ''
  };
  public loading = false;
  public chaveRecaptcha = '';
  public googlesitetoken;
  public returnUrl: string;
  public icone: string = Constantes.urlIconConnecta;
  public nomePrefeitura: any;
  public iconePrefeitura;
  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private recoverState: State,
    private domainSvc: DomainService,
    public translate: TranslateService
  ) {
    this.googlesitetoken = environment.google_site_key;
    this.iconePrefeitura = Constantes.urlIconLoginPrefeitura(domainSvc.getPrimarySubDomain());
    this.nomePrefeitura = environment.prefeituraNome[domainSvc.getPrimarySubDomain()] ? environment.prefeituraNome[domainSvc.getPrimarySubDomain()] : localStorage.getItem('nomePrefeitura');
  }

  ngOnInit() {
    this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (this.recoverState.get_data().changed) {
      this.lancarMensagem('Senha alterada com sucesso', 'alert-success');
    }
  }

  resolved(captchaResponse: string) {
    this.chaveRecaptcha = captchaResponse;
  }

  onSubmit() {
    this.loading = true;
    if (this.chaveRecaptcha === '') {
      this.lancarMensagem('Responda a validação contra Bots (reCaptcha)', 'alert-danger');
      this.loading = false;
    } else {
      this.authService.login(this.formulario.username, this.formulario.password, this.chaveRecaptcha).subscribe(
        data => {
          console.log('autenticacao data:', data);
          this.authService.carregarPerfil().subscribe(() =>
            this.router.navigate([this.returnUrl]));
          this.loading = false;
        },
        error => {
          console.log('carregou data erro: ', error);
          if (error['status'] === 401 || error['status'] === 403) {
            this.lancarMensagem(error['error']['error'], 'alert-danger');
          } else {
            console.log('Erro login', error);
            this.lancarMensagem('Erro, tente novamente mais tarde, caso o problema persista informe aos desenvolvedores: contato@grt8.com.br', 'alert-danger');
          }
          this.loading = false;
        }
      );
    }

  }
  get diagnostic() { return JSON.stringify(this.formulario); }
  public lancarMensagem(mensagem, tipo) {
      this.flashMessagesService.show(mensagem, { cssClass: tipo,  timeout: 7000, showCloseBtn: true, closeOnClick: true });
  }
}
