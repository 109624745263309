import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { tileLayer, marker, icon, Map } from 'leaflet';
import { APIService } from '../../../../api.service';
import { TranslateService } from "@ngx-translate/core";
import { ConfiguracaoMapa } from '../../../../model';
var BairrosModalComponent = /** @class */ (function () {
    function BairrosModalComponent(apiService, flashMessagesService, dialogRef, translate, modal, data) {
        this.apiService = apiService;
        this.flashMessagesService = flashMessagesService;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.modal = modal;
        this.data = data;
        this.latitude = -5.1841285;
        this.longitude = -37.3477805;
        this.zoom = 14;
        this.getConfig();
    }
    BairrosModalComponent.prototype.getConfig = function () {
        var _this = this;
        var retorno = this.apiService.getConfigs();
        this.configMapa = new ConfiguracaoMapa(this.latitude, this.longitude, this.zoom);
        retorno.subscribe(function (data) {
            var hasPoints = false;
            data.map(function (cfg) {
                var prop = JSON.parse(JSON.stringify(cfg));
                if (prop.campo == "latitudeCidade") {
                    _this.configMapa.latitudeCidade = parseFloat(prop.valor);
                    _this.latitude = parseFloat(prop.valor);
                    hasPoints = true;
                }
                else if (prop.campo == "longitudeCidade") {
                    _this.configMapa.longitudeCidade = parseFloat(prop.valor);
                    _this.longitude = parseFloat(prop.valor);
                    hasPoints = true;
                }
                else if (prop.campo == "zoomMapaCidade") {
                    _this.configMapa.zoomMapaCidade = parseFloat(prop.valor);
                }
            });
            _this.loadMapInd();
        });
    };
    BairrosModalComponent.prototype.ngOnInit = function () {
        // this.loadMapInd();
    };
    BairrosModalComponent.prototype.loadMapInd = function () {
        var _this = this;
        this.centerMap = [(this.data.latitude || this.latitude), (this.data.longitude || this.longitude)];
        this.map = new Map('leafletMapId', {
            center: this.centerMap,
            layers: [
                tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
            ],
            zoom: 16,
        });
        setTimeout(function () {
            _this.map.invalidateSize();
        }, 1);
        this.markerIndividual = this.createMarker((this.data.latitude || this.latitude), (this.data.longitude || this.longitude));
        this.map.addLayer(this.markerIndividual);
        this.map.on("move", function (data) {
            _this.map.removeLayer(_this.markerIndividual);
            _this.markerIndividual = _this.createMarker(_this.map.getCenter().lat, _this.map.getCenter().lng);
            _this.map.addLayer(_this.markerIndividual);
        });
    };
    BairrosModalComponent.prototype.atualizarLocalizacaoMapa = function () {
        var _this = this;
        this.apiService.updateGeocodingBairro(this.data.id, this.data.titulo, this.map.getCenter().lat, this.map.getCenter().lng).subscribe(function () {
            _this.data.latitude = _this.map.getCenter().lat;
            _this.data.longitute = _this.map.getCenter().lng;
            _this.lancarMensagem(_this.translate.instant("categorias.localizaoAtualizar"), "alert-success");
            _this.dialogRef.close({ data: _this.data });
        }, function (error) {
            _this.lancarMensagemDeErro(error['error']['error'], "alert-danger");
        });
    };
    BairrosModalComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    BairrosModalComponent.prototype.lancarMensagemDeErro = function (mensagem, tipo) {
        var campos = Object.keys(mensagem);
        var mensagensDeErro = "";
        if ((typeof mensagem) == 'string') {
            // Erros com mensagem direta
            mensagensDeErro = mensagem;
        }
        else {
            // Erros com multiplas entradas
            for (var _i = 0, campos_1 = campos; _i < campos_1.length; _i++) {
                var campo = campos_1[_i];
                mensagensDeErro += ((typeof mensagem[campo]) == 'string' ? mensagem[campo] + "<br>" : mensagem[campo][0] + "<br>");
            }
        }
        window.scrollTo(0, 0);
        this.flashMessagesService.show(mensagensDeErro, { cssClass: tipo, timeout: 7000, showCloseBtn: true, closeOnClick: true });
        this.modal.closeAll();
    };
    BairrosModalComponent.prototype.createMarker = function (lat, lng) {
        var iconMarkerLeaflet = {
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        };
        return marker([lat, lng], { icon: icon({
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                iconUrl: 'assets/marker-icon.png',
                shadowUrl: 'assets/marker-shadow.png'
            })
        });
    };
    return BairrosModalComponent;
}());
export { BairrosModalComponent };
