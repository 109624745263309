<div *ngIf="pager.currentPage">

  <!--
  <div class=" row_">
    <span>  
        Total de itens: {{pager.totalItems}}  
    </span>

    <div class="btn-group" role="group" aria-label="First group">

        
      <button type="button" class="btn btn-outline-light">&laquo;</button>
      <button type="button" class="btn btn-outline-secondary">1</button>
      <button type="button" class="btn btn-outline-secondary">2</button>
      <button type="button" class="btn btn-outline-secondary">3</button>
      <button type="button" class="btn btn-outline-secondary">4</button>
      <button type="button" class="btn btn-outline-secondary">&raquo;</button>

    </div>
  
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item active"><a class="page-link" href="#">1</a></li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
    
  </div>

-->
  
  <!-- pager -->
  <div class="row">
    <div class="col-md-12 ">
      <nav aria-label="Noticias" class="text-center">
        <ul *ngIf="pager && pager.pages && pager.pages.length" class="pagination justify-content-center">
          <li *ngIf="pager.currentPage > 1">
            <a (click)="setPage(1)" class="page-link">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only"> {{ translate.instant("paginacao.totalItems") }} </span>
            </a>
          </li>
          <li *ngIf="pager.currentPage < 1">
            <a (click)="setPage(pager.currentPage - 1)" class="page-link"> {{ translate.instant("paginacao.paginaAnterior") }} </a>
          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="setPage(page)" class="page-link">{{page}}</a>
          </li>
          <li *ngIf="pager.currentPage > pager.totalPages">
            <a (click)="setPage(pager.currentPage + 1)" class="page-link">
              {{ translate.instant("paginacao.proximaPagina") }}
            </a>
          </li>
          <li *ngIf="pager.currentPage < pager.totalPages">
            <a (click)="setPage(pager.totalPages)" class="page-link" aria-label="Ultima Página">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">
                {{ translate.instant("paginacao.ultimaPagina") }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!--
    <div class="row">
      <div *ngIf="pager.currentPage != 0" class="col-md-12 text-center">
        Página atual: {{pager.currentPage}} <b>-</b> Total de itens: {{pager.totalItems}} <b>-</b> Total de páginas: {{pager.totalPages}}
      </div>
      <div *ngIf="pager.currentPage == 0" class="col-md-12 text-center">
        <b>Página vazia!</b>
      </div>
    </div>
  -->
  <hr>
  <div class="row text-center ml-3 mr-3 pl-5 pr-5 pt-2 pb-2 shadow bg-light rounded">
    <div *ngIf="pager.currentPage != 0" class="col-md-12 text-center">
      <span  class="float-left">
        {{ translate.instant("paginacao.totalItems") }} <strong>{{pager.totalItems}}</strong>
      </span>
      <span  class="float-center">
        {{ translate.instant("paginacao.paginaAtual") }} <strong>{{pager.currentPage}}</strong>
      </span>
      <span  class="float-right">
        {{ translate.instant("paginacao.itemsPorPagina") }} <strong>{{pager.pageSize}}</strong>
      </span>
    </div>
    <div *ngIf="pager.currentPage == 0" class="col-md-12 text-center">
      <b> {{ translate.instant("paginacao.paginaVazia") }} </b>
    </div>
  </div>
  <br>

</div>