<!-- <h1 mat-dialog-title *ngIf="data.name">Olá {{ data.string }}</h1> -->
<div mat-dialog-content>
  <p>{{ data.content }}</p>
  <mat-form-field class="w-100">
    <input matInput [(ngModel)]="data.model" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <button mat-button (click)="close()" class="btn outline">Cancelar</button>
  <button
    mat-button
    [mat-dialog-close]="data.model"
    [disabled]="!data.model"
    class="btn btn-primary"
    cdkFocusInitial
  >
    OK
  </button>
</div>
