import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FlashMessagesService } from 'angular2-flash-messages';
import { tileLayer, marker, icon, Map } from 'leaflet';
import { APIService } from '../../../../../api.service';
import { TranslateService } from "@ngx-translate/core";
var InformacoesMapaModalComponent = /** @class */ (function () {
    function InformacoesMapaModalComponent(apiService, flashMessagesService, dialogRef, translate, data) {
        this.apiService = apiService;
        this.flashMessagesService = flashMessagesService;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.data = data;
        this.latitude = -5.1841285;
        this.longitude = -37.3477805;
    }
    InformacoesMapaModalComponent.prototype.ngOnInit = function () {
        this.loadMapInd();
    };
    InformacoesMapaModalComponent.prototype.loadMapInd = function () {
        var _this = this;
        var lat = this.data.latitude || this.latitude;
        var lng = this.data.longitude || this.longitude;
        this.end = this.data.endereco;
        this.map = new Map('leafletMapId', {
            center: [lat, lng],
            layers: [
                tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
            ],
            zoom: 16,
        });
        setTimeout(function () {
            _this.map.invalidateSize();
        }, 1);
        this.markerIndividual = this.createMarker(lat, lng);
        this.map.addLayer(this.markerIndividual);
        this.map.on("move", function (data) {
            _this.map.removeLayer(_this.markerIndividual);
            _this.markerIndividual = _this.createMarker(_this.map.getCenter().lat, _this.map.getCenter().lng);
            _this.map.addLayer(_this.markerIndividual);
        });
    };
    InformacoesMapaModalComponent.prototype.atualizarLocalizacaoMapa = function () {
        var _this = this;
        this.apiService.updateGeocodingInfo(this.data.id, this.map.getCenter().lat, this.map.getCenter().lng).subscribe(function () {
            _this.data.latitude = _this.map.getCenter().lat;
            _this.data.longitute = _this.map.getCenter().lng;
            _this.lancarMensagem(_this.translate.instant("categorias.localizaoAtualizar"), "alert-success");
            _this.dialogRef.close({ data: _this.data });
        }, function (error) {
            _this.lancarMensagemDeErro(error['error']['error'], "alert-danger");
        });
    };
    InformacoesMapaModalComponent.prototype.lancarMensagem = function (mensagem, tipo) {
        this.flashMessagesService.show(mensagem, { cssClass: tipo });
    };
    InformacoesMapaModalComponent.prototype.lancarMensagemDeErro = function (mensagem, tipo) {
        var campos = Object.keys(mensagem);
        var mensagensDeErro = "";
        if ((typeof mensagem) == 'string') {
            // Erros com mensagem direta
            mensagensDeErro = mensagem;
        }
        else {
            // Erros com multiplas entradas
            for (var _i = 0, campos_1 = campos; _i < campos_1.length; _i++) {
                var campo = campos_1[_i];
                mensagensDeErro += ((typeof mensagem[campo]) == 'string' ? mensagem[campo] + "<br>" : mensagem[campo][0] + "<br>");
            }
        }
        window.scrollTo(0, 0);
        this.flashMessagesService.show(mensagensDeErro, { cssClass: tipo, timeout: 7000, showCloseBtn: true, closeOnClick: true });
    };
    InformacoesMapaModalComponent.prototype.createMarker = function (lat, lng) {
        var iconMarkerLeaflet = {
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        };
        return marker([lat, lng], { icon: icon({
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                iconUrl: 'assets/marker-icon.png',
                shadowUrl: 'assets/marker-shadow.png'
            })
        });
    };
    return InformacoesMapaModalComponent;
}());
export { InformacoesMapaModalComponent };
