import { APIService } from '../../../api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Protocolo } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from '../../../auth/authentication.service';
var AtendimentoChatModalComponent = /** @class */ (function () {
    function AtendimentoChatModalComponent(apiService, router, dialogRef, translate, authService, protocolo) {
        this.apiService = apiService;
        this.router = router;
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.authService = authService;
        this.protocolo = protocolo;
        this.mensagemEnvio = '';
        this.loading = false;
        this.atualizarMensagens();
        this.getStatus();
        this.user = JSON.parse(localStorage.getItem('currentPerfil'));
    }
    AtendimentoChatModalComponent.prototype.atualizarMensagens = function () {
        var _this = this;
        this.apiService.getProtocoloRespostas(this.protocolo.id).toPromise().then(function (data) {
            if (data) {
                console.log('mensagens carregadas do protocolo no modal');
                _this.mensagens = data;
            }
        });
    };
    AtendimentoChatModalComponent.prototype.temPermissao = function (slug) {
        var retorno = false;
        this.user.funcoes.some(function (role) {
            retorno = role.permissoes.filter(function (perm) { return perm.slug == slug; }).length != 0;
            if (retorno) {
                return true;
            }
        });
        return retorno;
    };
    AtendimentoChatModalComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    AtendimentoChatModalComponent.prototype.enviarMensagem = function (mensagem) {
        var _this = this;
        this.apiService.enviaRespostaParaProtocolo(this.protocolo.id, { mensagem: mensagem }).toPromise().then(function (data) {
            _this.atualizarMensagens();
        });
    };
    AtendimentoChatModalComponent.prototype.alterarStatus = function () {
        var _this = this;
        this.loading = true;
        this.apiService.updateProtocolo(this.protocolo.id, { status: this.statusSelecionado, _method: 'PUT' }).toPromise().then(function () {
            if (!_this.protocolo['atendente']) {
                _this.authService.consultarPerfil().subscribe(function (result) {
                    _this.apiService.updateProtocoloAtendente(_this.protocolo.id, result['id']).toPromise().then(function () {
                        _this.loading = false;
                        _this.dialogRef.close();
                    }, function (error) {
                        // Caso a API ainda não esteja atualizada
                        _this.loading = false;
                        _this.dialogRef.close();
                    });
                });
            }
            else {
                _this.loading = false;
                _this.dialogRef.close();
            }
        }, function () {
        });
    };
    AtendimentoChatModalComponent.prototype.getStatus = function () {
        var _this = this;
        this.apiService.getStatus().toPromise().then(function (data) {
            if (data) {
                _this.selectStatus = data;
            }
        });
    };
    return AtendimentoChatModalComponent;
}());
export { AtendimentoChatModalComponent };
